import React, {Component} from 'react';
import {
    emptyArray,
    fileOpener, getDisabled,
    Icon,
    marginSetter,
    pullClassName,
    requireChecker,
    string2Json
} from "../../../../../../../helpers/util";
import connect from "react-redux/es/connect/connect";
import {getFormSyncErrors, getFormValues} from "redux-form/es/index";
import {getAccion, getAccionData, getAccionSchema} from "../../../../../../../selectors/estructura";
import {fetchAccionData} from "../../../../../../../actions/fetchAccionData";
    import {setAccionData, setAccionData2} from "../../../../../../../actions/setAccionData";
import {setAccionDinamica, setAccionSeleccionada} from "../../../../../../../actions/setAccionSeleccionada";
import {mostrarModal, showModal} from "../../../../../../../actions/mostrarModal";
import {getModal} from "../../../../../../../selectors/modal";
import {apiGet, apiPost} from "../../../../../../../api/apiSecure";
import {getPreviousData} from "../../../../../../../selectors/states";
import {startSubmit, stopSubmit, destroy, reset} from    "redux-form/es/index";
import ModalTriggerUrl from '../../../../../../ModalMaster/modalTriggerUrl';
import {setGridAccionData} from "../../../../../../../actions/setGridAccionData";
import {goBack} from "../../../../../../../actions/navigation";
import {submitButton, userInfoSetter} from "./Submit";
import {extServerListener} from "../../../../../../ModalMaster/serverListener";
import {ultimatePostGrid} from "../../../../../../../helpers/util/postGrid";

class ButtonField extends Component {

    constructor(props) {
        super(props);
        this.buttonInput = React.createRef();
        this.submitRef = React.createRef();
        this.state = {
            fullWidth: ''
        }
    };

    componentDidMount() {
        this.resize();
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize = () => window.innerWidth < 580 ? this.changeButtonClass(true) : this.changeButtonClass(false);

    updateIncomingData = (prev) => {
        var post = {...prev};
        extServerListener(post, this.props.mostrarModal);
        return (post.estado === null)
            ? post
            : (post.codigo_error === 1000 || post.codigo_error === 0) && (post.codigo_error !== 1002)
                ? post
                : null; // pasa si necesitan datos, si no es nulo
    };

    selector = async (url, data) => this.props.fetchAccionData(`${url}`).then(v => {
        return this.props.setAccionSeleccionada(v.value, data)
    }).then();

    reset = ({destroy}) => destroy('form');

    endpointSetter = (endpoint, parent, formValues) => {
        return this.props.accion.params && parent === undefined
            ? `${endpoint}/${this.checkParams(this.props)}`
            : parent !== "" && formValues && eval("formValues."+parent)
                ? `${endpoint}/${eval("formValues."+parent)}`
                : parent !== "" &&
                this.props.previousData.hasOwnProperty("formValues") &&
                (this.props.previousData.formValues.hasOwnProperty(parent) || eval("this.props.previousData.formValues."+parent))
                    ? `${endpoint}/${eval("this.props.previousData.formValues."+parent)}`
                    : endpoint;
    }

    findParent = val => eval(`this.props.formValues.${val}`);
    findStatic = val => val;
    findValue = val  => eval(`this.props.${val}`);

    checkParams = ({accion:{params}}) => {
        return Object.values(string2Json(params).list).sort((a,b) => a.order - b.order).reduce( (ant, act) => {
            act = ant !== "" ? `${ant}/${this[`find${Object.keys(act)[0]}`](act[Object.keys(act)[0]])}`: this[`find${Object.keys(act)[0]}`](act[Object.keys(act)[0]]);
            return act;
        },"");
    };

    submitAlertElimAsign = ({mostrarModal, schema:{action}, showModal, fields, redirect}) => {
        let fvalues = userInfoSetter(this.props);
        return requireChecker(fields, fvalues) &&
        fvalues.hasOwnProperty("descripcionEliminacion") &&
        fvalues.descripcionEliminacion.toString().length >= 10 &&
        fvalues.descripcionEliminacion.toString().length <= 200
            ? mostrarModal("Alerta", "¿Confirma que desea realizar la acción?", <div className="btn-group-md">
                <button className="btn btn-danger"
                        onClick={() => showModal(false)}>
                    <strong>
                        CANCELAR
                    </strong>
                </button>
                <button className="btn btn-primary text-center" type="submit"
                        onClick={() => {
                            console.log("click")
                            apiPost(action, fvalues)
                                .then(res => {
                                    extServerListener(res, this.props.mostrarModal );
                                    return this.redirect(this.props)
                                }).catch(cat => mostrarModal("Alerta", JSON.stringify(cat), null))
                        }
                        }>
                    <strong>
                        ACEPTAR
                    </strong>
                </button>
            </div>)
            : mostrarModal("Alerta", 'El campo "Causa de eliminación" es de carácter obligatorio y debe tener un largo mínimo de 10 caracteres' , null)
    };

    actionGetter = (endpoint, parent, redirect, formValues) => apiGet( this.endpointSetter(endpoint, parent, formValues))()
        .then(fetcherData => {
            let updated = Array.isArray(fetcherData) ? fetcherData : this.updateIncomingData(fetcherData);
            (updated !== null && updated.codigo_error !== 1001 && updated.codigo_error !== 1002) &&
            this.props.fetchAccionData(redirect)
                .then( v =>  this.props.setAccionSeleccionada(v.value))
                .then( v =>  (updated.codigo_error === 1000 || updated.codigo_error === 0) && updated.codigo_error !== 1002
                    ? this.props.setAccionData(updated)
                    : this.props.setAccionData(formValues));
        }).catch(c => extServerListener(c,this.props.mostrarModal));

    actionPostter = (endpoint, parent, formValues) => {
        try{
            apiPost(`${string2Json(endpoint).saveTo}`, formValues).then(post => {
                this.props.mostrarModal("Mensaje", post.descripcion, "");
                if(post.hasOwnProperty("codigo") || post.hasOwnProperty("codigo_error")){
                    this.props.mostrarModal("Mensaje", post.descripcion, "");
                    this.actionGetter(string2Json(endpoint).endpoint, parent, string2Json(endpoint).redirect, formValues);
                }
            })
        }
        catch (e) {}
    };

    endpointObjectTrigger = (end, parent, formValues) => {
        const { endpoint, redirect} = string2Json(end);
        this.actionGetter(endpoint, parent, redirect, formValues)
    };

    actionTrigger = async ({parent ,accion:{redirect}, formValues, endpoint}) => {
        console.log(endpoint, formValues, parent, redirect);
        try{
            !endpoint.includes('"')
                ? this.actionGetter(endpoint, parent, redirect, formValues)
                : this.endpointObjectTrigger(endpoint, parent, formValues);
        }
        catch (e) {}
    };


    optionsTrigger = async ({parent ,accion:{redirect}, formValues, endpoint}) => {
        try{
            endpoint.includes('"') && this.actionPostter(endpoint, parent, formValues, formValues);
        }
        catch (e) {}
    };

    asyncModal = async (flag, endpoint, formValues, parent) => {

        const acc = {
            parent: "",
            accion: {
                redirect: string2Json(endpoint).b
            },
            formValues: formValues,
            endpoint: string2Json(endpoint).c
        };

        return flag
            ? apiGet(`${string2Json(endpoint).a}/${formValues[parent]}/BORRADOR`)().then(response => this.actionTrigger(acc))
            : null;
    };

    asyncModal2 = async (flag, endpoint, formValues, parent) => {

        const acc = {
            parent: "",
            accion: {
                redirect: string2Json(endpoint).b
            },
            formValues: formValues,
            endpoint: string2Json(endpoint).c
        };

        return flag
            ? apiPost(`${string2Json(endpoint).a}`,formValues).then(response => this.actionTrigger(acc))
            : null;
    };

    endpointTrigger = ({endpoint , formValues, parent}) => {
        this.props.mostrarModal("Alerta ","Estas a punto de devolver el formulario. ¿Desea continuar?", (
            <div className="btn-group-md">
                <button className="btn btn-danger"
                        onClick={() => this.asyncModal(false, endpoint, formValues, parent).then(resp => this.props.showModal(false))}>
                    <strong>
                        CANCELAR
                    </strong>
                </button>
                <button className="btn btn-primary text-center" onClick={
                    () => this.asyncModal(true, endpoint, formValues, parent).then(resp => {
                        this.props.showModal(false);
                            this.props.mostrarModal("Mensaje",'El formulario del postulante ha sido devuelto.',"");
                    })}>
                    <strong>
                        ACEPTAR
                    </strong>
                </button>
            </div>));
    };


    devolver = ({endpoint , formValues, parent}) => {
      console.log("devolver !!!");
        this.props.mostrarModal("Alerta ","Estas a punto de devolver el formulario. ¿Desea continuar?", (
            <div className="btn-group-md">
                <button className="btn btn-danger"
                        onClick={() => this.asyncModal2(false, endpoint, userInfoSetter(this.props), parent).then(resp => this.props.showModal(false))}>
                    <strong>
                        CANCELAR
                    </strong>
                </button>
                <button className="btn btn-primary text-center" onClick={
                    () => this.asyncModal2(true, endpoint, userInfoSetter(this.props), parent).then(resp => {
                        this.props.showModal(false);
                            this.props.mostrarModal("Mensaje",'El formulario del postulante ha sido devuelto.',"");
                    })}>
                    <strong>
                        ACEPTAR
                    </strong>
                </button>
            </div>));
    };

    verify = () => {
        this.props.mostrarModal("Alerta ","Estás a punto de cambiar el estado de 'REGISTRADO' a 'BORRADOR'", (
            <div className="btn-group-md">
                <button className="btn btn-danger"
                        onClick={() => false}>
                    <strong>
                        CANCELAR
                    </strong>
                </button>
                <button className="btn btn-primary text-center" onClick={
                    () => true}>
                    <strong>
                        ACEPTAR
                    </strong>
                </button>
            </div>));
    }

    postGrid = props => {
        props.setAccionData([]);
        return ultimatePostGrid({
            ...props,
            formValues: userInfoSetter(props),
            arrayError: this.arrayError
        });
    };

    modalContact = ({mostrarModal, formValues, keycloakUserInfo, mass}) => {
        return keycloakUserInfo.evg_institucion_perteneciente
            ? mostrarModal("Alerta","Esta función esta solo autorizada para roles administrativos de Ministerio de Justicia y Derechos Humanos.",null)
            : mostrarModal( <div className="text-center">
            <h2>Solicitud de modificación</h2>
            <small>Solicitud para la realización de cambios en el registro.&nbsp;
                <span> </span>
            </small>
        </div>, <ModalTriggerUrl />,<div style={{display: "none"}}> </div>)
    };

    arrayError = (res, modal, set, methodForm) => res.hasOwnProperty("codigo_error") && res.hasOwnProperty("descripcion") &&
    res.codigo_error !== null && res.descripcion !== null
        ? modal("Alerta", res.descripcion, null)
        : res.hasOwnProperty('redirect') && res.redirect
            ? this.redirect({
                ...this.props,
                "dataToFill": res.data
            })
            : methodForm === "DUALENDPOINTS"
                ? set({data:[res] , loaded: false})
                : set([res]);


    getGrid = props => console.log("getGrid", props);

    gridFilter = props => props.metodo === "POST"
        ? this.postGrid(props)
        : props.metodo === "GET"
            ? this.getGrid(props)
            : null;

    fileGetter = ({endpoint, parent, formValues, formato, userInfo, keycloakUserInfo, mostrarModal}) => parent && parent.includes("keycloakUserInfo")
        ? fileOpener(this.endpointSetter(endpoint, parent, {...formValues, keycloakUserInfo: keycloakUserInfo}), undefined, formato, mostrarModal)
        : fileOpener(this.endpointSetter(endpoint, parent, formValues), undefined, formato);

    fileGetterPost = ({endpoint, parent, formValues, formato, fields, mostrarModal}) => requireChecker(fields, formValues)
        ? apiPost(endpoint,userInfoSetter(this.props)).then(respuesta => respuesta.hasOwnProperty("base") && respuesta.base
            ? fileOpener(null, respuesta, formato)
            : emptyArray(respuesta.hasOwnProperty("descripcion") && respuesta.descripcion !== "" ? respuesta.descripcion : null, mostrarModal))
        : mostrarModal("Alerta", "Falta completar campos obligatorios", null);

    fileGetterPostIntitucion = ({endpoint, parent, formValues, formato, fields, mostrarModal,keycloakUserInfo}) => requireChecker(fields, formValues)
        ? apiPost(this.endpointSetter(endpoint, parent, {...formValues, keycloakUserInfo: keycloakUserInfo}), userInfoSetter(this.props)).then(respuesta => respuesta.hasOwnProperty("base") && respuesta.base
            ? fileOpener(null, respuesta, formato)
            : emptyArray(respuesta.hasOwnProperty("descripcion") && respuesta.descripcion !== "" ? respuesta.descripcion : null, mostrarModal))
        : mostrarModal("Alerta", "Falta completar campos obligatorios", null);

    backButton = () => this.props.goBack();

    redirect = ({redirect, dataToFill}) => {
        try{
            apiGet(redirect)().then(fetchedData => {
                return this.props.setAccionSeleccionada(fetchedData, dataToFill !== undefined ? dataToFill : undefined, undefined, false)
            })
        }catch (e) {
            console.log(e);
        }

    };

    actionGetter2 = (endpoint, parent, redirect, formValues) => apiPost(endpoint, formValues)
        .then(fetcherData => {
            let updated = Array.isArray(fetcherData) ? fetcherData : this.updateIncomingData(fetcherData);
            (updated !== null && updated.codigo_error !== 1001 && updated.codigo_error !== 1002) &&
            this.props.fetchAccionData(redirect)
                .then( v =>  this.props.setAccionSeleccionada(v.value))
                .then( v =>  (updated.codigo_error === 1000 || updated.codigo_error === 0) && updated.codigo_error !== 1002
                    ? this.props.setAccionData(updated)
                    : this.props.setAccionData(formValues));
        }).catch(c => extServerListener(c,this.props.mostrarModal));

    actionTrigger2 = async ({parent ,accion:{redirect}, formValues, endpoint}) => {
        try{
            !endpoint.includes('"')
                ? this.actionGetter2(endpoint, parent, redirect, formValues)
                : this.endpointObjectTrigger(endpoint, parent, formValues);
        }
        catch (e) {}
    };

    asignacion = ({endpoint , formValues, parent}) => {
      const acc = {
          parent: "",
          accion: {
              redirect: string2Json(endpoint).b
          },
          formValues: userInfoSetter(this.props),
          endpoint: string2Json(endpoint).c
      };
      return apiPost(`${string2Json(endpoint).a}`, userInfoSetter(this.props)).then(response => this.actionTrigger2(acc));
    }




    bloqueo = ({endpoint , formValues, parent}) => {

      return apiPost(`${string2Json(endpoint).a}`, userInfoSetter(this.props)).then(response => {
          if(response.codigo_error=="1005")
          {
            this.props.mostrarModal("ALERTA",response.descripcion)
          }else{
            try{
                apiGet(string2Json(endpoint).b)().then(fetchedData => {
                    return this.props.setAccionSeleccionada(fetchedData,undefined, undefined, false)
                })
            }catch (e) {
                console.log(e);
            }
          }
        }
      );
    }

    botonAccion = ({icon, label, className, tipo, endpoint, pristine, submitting, metodo}) => {
        if (tipo === "AdviseDarDeBaja") {
            let datos = this.props.formValues;
            return (
                <button className={`${className} pull-right ${this.state.fullWidth}`}
                        style={{ marginLeft: `${marginSetter(label)}` }}
                        onClick={() => {
                            datos = userInfoSetter(this.props);
                            if (datos.listDarDeBaja) {
                                if (datos.listDarDeBaja.motivo && datos.listDarDeBaja.fecha) {
                                    apiPost(endpoint, datos).then(a => {
                                        extServerListener(a, this.props.mostrarModal);
                                        this.redirect(this.props);
                                    });
                                } else {
                                    this.props.mostrarModal("ALERTA", "Los campos motivo y fecha de la baja son obligatorios para poder dar de baja al entrevistador");
                                }
                            } else {
                                this.props.mostrarModal("ALERTA", "Los campos motivo y fecha de la baja son obligatorios para poder dar de baja al entrevistador");
                            }
                        }}>
                    {label} &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon clase={icon}></Icon>
                </button>
            )
        }
        if(tipo === "AgibizGuardar"){
            let datos = this.props.formValues;
            return (
                <button className={`${className} pull-right ${this.state.fullWidth}`}
                        style={{marginLeft:`${marginSetter(label)}`}}
                        onClick={() => {
                            datos = userInfoSetter(this.props);
                            datos.guardado = true;
                            datos.submitted = false;
                            datos.estado = "BORRADOR";
                            (datos.profesional)
                                ? (datos.profesional.nombre && datos.profesional.run && datos.profesional.apellidoPaterno)
                                ? apiPost(endpoint, datos).then(a => {
                                    extServerListener(a,this.props.mostrarModal)
                                })
                                : this.props.mostrarModal("ALERTA", "Los campos RUN, Nombre y Apellido Paterno, son necesarios para poder guardar los datos en estado de BORRADOR")
                                : this.props.mostrarModal("ALERTA", "Los campos RUN, Nombre y Apellido Paterno, son necesarios para poder guardar los datos en estado de BORRADOR");
                        }}>
                    {label} &nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon clase={icon}></Icon>
                </button>)

        }
        else{
            return (<button type={tipo}
                            style={{marginLeft:`${marginSetter(label)}`}}
                            className={`${className} ${pullClassName(label)} ${this.state.fullWidth}`}
                            disabled={getDisabled(this.props)}
                            onClick={() => (tipo === "endpointTrigger")
                                ? this.endpointTrigger(this.props)
                                : (tipo !== 'button')
                                    ? this[tipo](this.props)
                                    : (metodo !== "OPTIONS")
                                        ? this.actionTrigger(this.props)
                                        : this.optionsTrigger(this.props)
                            }>
                {label} &nbsp;&nbsp;&nbsp;&nbsp;
                <Icon clase={icon}> </Icon>
            </button>)
        }
    };

    changeButtonClass = (mobile) => mobile ? this.setState({fullWidth: 'col-xs-12'}) : this.setState({fullWidth: ''});

    render() {
        return this.props.tipo === "submit"
            ?  submitButton(this.props, this.state)
            :  this.botonAccion(this.props)
    }
}

ButtonField.defaultProps = {
    label: "label",
    icon: "",
    className: "btn btn-info",
    tipo: "button",
    endpoint:""
};

const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors('form')(state),
    formValues: getFormValues('form')(state),
    data: getAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    modal: getModal(state),
    previousData: getPreviousData(state),
    keycloakUserInfo: state.userInfo,
    previousAccion: state.previousAccion,
    previousData: state.previousData,
    previousForm: state.previousForm,
    mass: state
});
export default connect(mapStateToProps,{fetchAccionData, setGridAccionData ,setAccionData, destroy, reset,goBack , setAccionData2, setAccionSeleccionada,setAccionDinamica, mostrarModal, showModal, startSubmit, stopSubmit})(ButtonField);
