import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import {string2Json, parentFinder, parentLabelFinder} from "../../../../../../helpers/util";
import {apiGet} from "../../../../../../api";
import isPlainObject from "redux-actions/es/utils/isPlainObject";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import {getAccionColumns, getAccionSchema} from "../../../../../../selectors/estructura";
const { detect } = require('detect-browser');

class EqualsField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstValue: null,
            secondValue: null,
            valid: true,
            touched: false,
            visited: false,
            labelParent :""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        parentFinder(this.props.conditionals, this.props.formValues) !== this.props.input.value &&
        this.state.valid && this.setState({
                valid : false,
            });

        parentFinder(this.props.conditionals, this.props.formValues) === this.props.input.value &&
        !this.state.valid && this.setState({
            valid : true,
        });
    }

    checkLength = (event) => {
        event.target.value = event.target.value.slice(0,this.props.conditionals.maxLength);
        return event;
    };

    handleOnChange = (event, {conditionals, formValues, input:{onChange}} ) => {
        parentFinder(conditionals, formValues) === event.target.value
            ? this.setState({
                valid : true
            })
            : this.setState({
                valid : false
            });
        event = this.checkLength(event);
        return parentFinder(conditionals, formValues) === null ? null : onChange(event);
    };

    handleOnBlur = () => {

    };
    handleClick = () => {
        !this.state.visited && this.setState({
            visited: true,
            touched:true,
            valid: false
        });
        this.props.meta.visited = this.state.visited;
        this.props.meta.touched = true;
    };

    EqualsChecker = ({label, type, placeholder, input, meta, required, fields, conditionals, formValues},{valid, labelParent}) => {
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <div className="input-group">
                    <input
                        {...input}
                        type={type}
                        className={`form-control async-validating ${valid ? "" : "bg-red"} col-md-2`}
                        placeholder={placeholder}
                        onBlur={this.handleOnBlur}
                        onChange={event => this.handleOnChange(event , this.props)}
                        style={this.checkStyles(type, detect().name)}
                        autoComplete="off"
                        onClick={this.handleClick}
                        required={required}
                        onPaste={event => {
                            return event.preventDefault();
                        }}
                    />
                    <span className={`input-group-addon  ${valid ? "bg-green" : "bg-red"}`} style={{borderRadius: "7px"}}>
                        &nbsp;&nbsp;&nbsp;<i className={`fas fa-check`}></i>&nbsp;&nbsp;
                    </span>
                </div>
                <div className="form-group col-md-12 bg-gray-light" style={{
                    borderRadius:"7px",
                    marginTop: "5px",
                    paddingBottom: "4px"
                }}>
                    <span> {
                        !valid ? `El campo "${label}" debe ser igual a "${labelParent}"`
                               : "Los campos son iguales"
                    } </span>
                </div>
            </WrapperField>
        );
    };

    checkStyles = (type, browser) =>  (type === "date" && browser === "firefox")
        ? { paddingTop: "0px",
            paddingBottom: "36px",
            borderRadius: "5px" }
        : { padding: "18px",
            borderRadius: "5px" };

    componentDidMount() {
        this.setState({
            labelParent : parentLabelFinder(this.props.conditionals, this.props.fields)
        });
        (this.props.visible) && this.props.setVisible({
            [this.props.massive.questionId]: {
                input: this.props.massive.questionId,
                filterBy: string2Json(this.props.filterBy),
                hide: string2Json(this.props.visible)
            }
        });
    }

    render() {
        return this.EqualsChecker({...this.props}, {...this.state});
    }
}

EqualsField.defaultProps = {
    conditionals:{
        maxLength: 60
    }
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state),
    fields: getAccionColumns(state),
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(EqualsField);


