import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import {RadioButton, RadioGroup} from "react-radio-buttons";
import connect from "react-redux/es/connect/connect";
import {fetchAccionData} from "../../../../../../actions/fetchAccionData";
import {setGridAccionData} from "../../../../../../actions/setGridAccionData";
import {setAccionData} from "../../../../../../actions/setAccionData";
import {destroy, getFormSyncErrors, getFormValues, reset, startSubmit, stopSubmit} from "redux-form";
import {goBack} from "../../../../../../actions/navigation";
import {setAccionDinamica, setAccionSeleccionada} from "../../../../../../actions/setAccionSeleccionada";
import {mostrarModal, showModal} from "../../../../../../actions/mostrarModal";
import {getAccion, getAccionData, getAccionSchema} from "../../../../../../selectors/estructura";
import {getModal} from "../../../../../../selectors/modal";
import {getPreviousData} from "../../../../../../selectors/states";
import _ from 'lodash';

class RadioField extends Component {

    onChange = (e) => e && this.props.input.onChange(e);

    componentDidUpdate(prevProps ) {
        if(prevProps.hasOwnProperty('formValues') && prevProps.formValues !== null && _.isEmpty(this.props.formValues) ) this.props.input.onChange(null);
    }

    render() {
        let {input: {name}, values, disabled, label, required} = this.props;

        let extraInputProps = Object.assign({}, this.props.input, {onChange: this.onChange});

        return (
            <WrapperField meta={this.props.meta} label={label} required={required}><br/>
                <div style={{
                    marginTop:label && label !== "" ? "-10px" : "-5px"
                }}>
                    {values.map(({label, value}, index) => {
                        const id = `${name}-${value}`;
                        return (
                            <div style={{
                                marginBottom:"40px"
                            }}>
                                <div className="radio form-control"  key={index} style={{
                                    padding:"8px",
                                    height:"38px",
                                    borderRadius:"7px"
                                }}>
                                    <label key={index} htmlFor={id} className="col-md-12" style={{
                                        paddingRight:"0px"
                                    }}>
                                        <input
                                            key={index}
                                            type='radio'
                                            {...extraInputProps}
                                            id={id}
                                            disabled={disabled}
                                            value={value.includes(" ") ? value.replace(/\s/g,'') : value}
                                        />
                                        {label}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </WrapperField>
        );
    }
}

RadioField.defaultProps = {
  values: [{
      "label": "",
      "value": ""
  }]
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
});
export default connect(mapStateToProps, {fetchAccionData})(RadioField);