import {apiGet,apiPost} from "../../api/apiSecure";
import React from "react";

export const deleter = (url,redirect,desc, modal, metodo, show, setAccionData2,setAccionData,setAccion,keycloakUserInfo) => modal("Alerta", desc, <div className="btn-group-md">
    <button className="btn btn-danger"
            onClick={() => show(false)}>
        <strong>
            CANCELAR
        </strong>
    </button>
    <button className="btn btn-primary text-center"
            onClick={() => {
                if(url.includes("/reservas/eliminarBloqueo") || url.includes("darDeBaja") || url.includes("reversardarDeBaja")){
                    return apiGet(url)().then(response => {

                        console.log(response);
                        show(false);
                        setAccionData([]);
      
                        apiGet(redirect)().then(fetchedData => {
                            console.log(fetchedData);
                          if(typeof setAccionData2 === "function" && Array.isArray(response)) {
                              setAccionData2({data: response, loaded: false});
                          }
                          if(Array.isArray(response) && !setAccionData2 && !response[0].hasOwnProperty("codigo_error")) {
                              return setAccionData(response);
                          }
                          return !Array.isArray(response) && response.codigo === 1005 || response.codigo_error === 1005
                              ? modal("Alerta",response.descipcion || "Error", null)
                              : modal("Alerta", "Operacion exitosa", null)
      
                      })
                      }).catch(cat => {
                          show(false)
                          return modal("Alerta","Ha ocurrido un error", null)
                      })
                } else {
                    return apiPost(url,{userInfo:keycloakUserInfo}).then(response => {
                        show(false);
                        setAccionData([]);
      
                        apiGet(redirect)().then(fetchedData => {
                          return setAccion(fetchedData,undefined, undefined, false)
      
      
                          if(typeof setAccionData2 === "function" && Array.isArray(response)) {
                              setAccionData2({data: response, loaded: false});
                          }
                          if(Array.isArray(response) && !setAccionData2 && !response[0].hasOwnProperty("codigo_error")) {
                              return setAccionData(response);
                          }
                          return !Array.isArray(response) && response.codigo === 1005 || response.codigo_error === 1005
                              ? modal("Alerta",response.descipcion || "Error", null)
                              : modal("Alerta", "Operacion exitosa", null)
      
                      })
                      }).catch(cat => {
                          show(false)
                          return modal("Alerta","Ha ocurrido un error", null)
                      })
                }
            }}>
        <strong>
            ACEPTAR
        </strong>
    </button>
</div>);
