import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Card from "../index";
import {
    getAccion,
    getAccionColumns,
    getAccionData,
    getAccionSchema,
} from "../../../../selectors/estructura";
import GeneradorForm from './GeneradorForm';
import WizardForm from './WizardForm';
import {insertAccionData, actionHandleSubmit, submitHandling} from "../../../../actions/insertAccionData";
import {updateActionData} from "../../../../actions/updateActionData";
import {fetchAccionData} from "../../../../actions/fetchAccionData";
import {mostrarModal} from "../../../../actions/mostrarModal";
import TabForm from "./TabForm";
import getFormValues from "redux-form/es/getFormValues";
import {formRequirements} from "../../../../externalHandling/agibiz";
import {string2Json} from "../../../../helpers/util";
import {setAccionSeleccionada} from "../../../../actions/setAccionSeleccionada";
import {setAccionData} from "../../../../actions/setAccionData";
import {apiGet} from "../../../../api";

class Formulario extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loaded : false
        }
    }


    submitActionTrigger = (values, triggers) => {
        this.props.submitHandling(this.props.schema.action, values, this.props.mostrarModal, triggers);
    };

    submitRegular = (values) => console.log();

    agibizProcessSetter = values => {
        if( values.hasOwnProperty('estado') &&
            values.estado === "BORRADOR" &&
            !values.hasOwnProperty('provider') &&
            !values.hasOwnProperty('process')){
            values.provider = "agibiz";
            values.process = "ingreso";
            return values;
        }else if(values.hasOwnProperty('estado') &&
                 values.estado === "REGISTRADO" &&
                 values.hasOwnProperty('process') &&
                  values.process === "ingreso") {
            values.estado = "BORRADOR";
            return values;
        }else if(   values.hasOwnProperty('process') &&
                    values.process === "acreditacion" &&
                    values.hasOwnProperty('acreditacion') &&
                    values.acreditacion === null){
            values.process = "editaracreditacion";
            return values;
        }else if(   values.hasOwnProperty('process') &&
            values.process === "acreditacion" &&
            values.hasOwnProperty('acreditacion') &&
            values.acreditacion !== null){
            values.process = "acreditacion";
            return values;
        }
        return values;
    };

    requirementsModal = () => {
        this.props.mostrarModal("Mensaje", "Falta completar campos obligatorios", "");
        this.metaReset();
    };

    nuevoHandleSubmit = values => {
        const {schema: {action, botones}} = this.props;
        let sbmt = botones.length > 0 && botones.filter(b => b.tipo === "submit")[0];
        values = this.agibizProcessSetter(values);
        values =    values.hasOwnProperty('process') && values.process !== null &&
                    values.hasOwnProperty('provider') && values.provider !== null &&
                    values.hasOwnProperty('estado') && values.estado !== null
                        ? formRequirements[values.provider][values.process](values)
                        : values.process === "pfc" &&
                            values.codigo_error === 1000 &&
                            !values.hasOwnProperty('estado')
                                ? formRequirements[values.provider][values.process](values)
                                : values === null
                                    ? null
                                    : values.process === "ingresarReclamacion" &&
                                                 !values.hasOwnProperty('estado')
                                                    ? formRequirements[values.provider][values.process](values)
                                                    : values === null
                                                        ? null
                                                        : values;
        return values === null
            ? this.requirementsModal()
            : string2Json(sbmt.endpoint) !== null
                ? this.submitActionTrigger(values, string2Json(sbmt.endpoint))
                : this.submitRegular(values);
    };

    metaReset = () => {
        this.props.setSubmitFailed('form');
        throw this.props.stopSubmit('form');
    };

    handleSaves = values => {
        try{
            values = this.agibizProcessSetter(values);
            values = formRequirements[values.provider][values.process](values);
            values === null && this.metaReset();
        }catch (e) {}
    };

    handleSubmit = values => {
        const {schema: {action}, loading, userInfo} = this.props;
        values.submitted
            ? this.nuevoHandleSubmit(values)
            : this.handleSaves(values)
    };

    submitter = async  (action, values, nextAction) => {
        let res = await this.props.actionHandleSubmit(action, values, this.props.mostrarModal, nextAction);
        return res;
    };


    submitterPromise = (action, values) => {
        return new Promise((resolve, reject) => {
            let data = this.props.actionHandleSubmit(action, values);
            return (data !== undefined)
                ? resolve(data)
                : reject;
            })
    };

    alerta= (mensaje) => {
        this.props.mostrarModal("Mensaje", mensaje, null)
    };

    seleccionarTipo = (tipo) =>{
        switch (tipo) {
            case "Simple": return GeneradorForm;
            case "Wizard": return WizardForm;
            case "Tab": return TabForm;
            default: return GeneradorForm;
        }
    };

    cardLayer = () => <Card titulo={this.props.accion.nombre || this.props.schema.formulario.nombre ||  "nombre"}>
                        {this.renderTipo(this.props.schema.tipoFormulario.nombre)}
                      </Card>;

    renderTipo = (tipoForm) => {
        const Tipo = this.seleccionarTipo(tipoForm);
        const butones = (this.props.schema.botones.length > 0 ) ? this.props.schema.botones : [];
        return (
                <Tipo fields={this.props.fields}
                      alerta={this.alerta}
                      onSubmit={this.handleSubmit}
                      initialValues={this.isValidDataEdit(this.props.data)}
                      botones={butones}/>
                )
    };

    renderTipoGrid = (tipoForm) => {
        const Tipo = this.seleccionarTipo(tipoForm);
        const butones = (this.props.schema.formulario.botones.length > 0)
            ? this.props.schema.formulario.botones
            : [];
        return  <Tipo fields={this.props.schema.formulario.sections}
                  alerta={this.alerta}
                  onSubmit={this.handleSubmit}
                  sectionGrid={this.props.sectionGrid || null}
                  initialValues={this.isValidDataEdit(this.props.data)}
                  botones={butones}/>;
    };

    isValidDataEdit = (data) => {
        if(Array.isArray(data) && this.props.loadPrev && this.props.loadForm) return this.props.lastFormData.form.values;
        if(Array.isArray(data)) return {};
        return data
    };

    gridFormHandling = () => {
        return this.renderTipoGrid(this.props.schema.formulario.tipoFormulario.nombre)
    };

    renderFormGrid = () => this.props.schema.hasOwnProperty('formulario')
                            ? this.gridFormHandling()
                            : this.cardLayer();


    render(){
        return this.props.schema && this.renderFormGrid();
    }
}



Formulario.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = state => ({
    data:getAccionData(state),
    lastFormData: state.previousForm,
    schema: getAccionSchema(state),
    fields: getAccionColumns(state),
    accion: getAccion(state),
    formValues: { ...getFormValues('form')(state),...state.userInfo},
    modalFormValues: state,
    formResponse: state.formResponse,
    userInfo: state.userInfo,
    loadPrev: state.accionValor.loadPrev,
    loadForm: state.accionValor.formValues
});



const FormularioConectado = connect(mapStateToProps,{mostrarModal, insertAccionData, setAccionData, updateActionData, actionHandleSubmit ,submitHandling, setAccionSeleccionada ,fetchAccionData})(Formulario);



export default FormularioConectado
