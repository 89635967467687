import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import {Icon} from "../../../../../../helpers/util";
import connect from "react-redux/es/connect/connect";
import {getFormSyncErrors, getFormValues} from "redux-form/es/index";
import {getAccion, getAccionData, getAccionSchema} from "../../../../../../selectors/estructura";
import {fetchAccionData} from "../../../../../../actions/fetchAccionData";
import {setAccionData} from "../../../../../../actions/setAccionData";
import {setAccionDinamica, setAccionSeleccionada} from "../../../../../../actions/setAccionSeleccionada";
import {mostrarModal, showModal} from "../../../../../../actions/mostrarModal";
import {getModal} from "../../../../../../selectors/modal";
import {apiGet, apiPost} from "../../../../../../api/apiSecure";
import {setPreviousData, setPrevAccionSeleccionada, clearPrevData, clearPrevAccionSeleccionada} from "../../../../../../actions/previous";

class ActionButtonField extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    };


    updateIncomingData = (prev) => {
        if(Array.isArray(prev)){
            return prev
        }
        else {
            var post = {...prev};
            this.extServerListener(post);
            return (post.estado === null)
                ? post
                : (post.codigo_error === 1000 || post.codigo_error === 0)
                    ? post
                    : null;
        }
    };

    extServerListener = (response) =>{
        response.hasOwnProperty("codigo_error") || response.hasOwnProperty("codigo")
            ? this.props.mostrarModal(`Mensaje  ` ,`${response.descripcion}`)
            : this.props.mostrarModal(`Ha ocurrido algo inesperado`, JSON.stringify(response.response || response, null, 2),"")
    };

    selector = async (url, data) => this.props.fetchAccionData(`${url}`).then(v => {
        return this.props.setAccionSeleccionada(v.value, data, this.props.mostrarModal, true)
    }).then( a => a);


    actionGetter = (endpoint, parent, redirect, formValues) => {
        apiGet( `${endpoint}/${eval("formValues."+parent)}`)()
            .then(fetcherData => {
                let updated = this.updateIncomingData(fetcherData);
                (updated !== null && updated.codigo_error !== 1001) &&
                this.props.fetchAccionData(redirect)
                    .then( v =>  {
                        return this.props.setAccionSeleccionada(v.value, undefined, this.props.mostrarModal, true)
                    })
                    .then( v =>  {
                        return (updated !== null)
                            ? this.props.setAccionData(updated)
                            : this.props.setAccionData(formValues)
                    });
            }).catch(c => this.extServerListener(c));
    };

    postPrev = async (url) => apiPost(url, this.props.formValues).then(r => r);

    actionTrigger = async ({parent ,accion:{redirect}, formValues, endpoint}) => {
        try{
            this.actionGetter(endpoint, parent, redirect, formValues);
        }
        catch (e) {
            console.log()
        }
    };

    botonAccion = () => {
        const {icon, class_name, redirect,tipo, endpoint, parent} = this.props.massive;
        const {label, required, meta, formValues, setPreviousData, previousData, setPrevAccionSeleccionada, previousAccion, clearPrevData, clearPrevAccionSeleccionada} = this.props;
        const triggerable = {
            parent: parent,
            accion :{
                redirect: redirect
            },
            formValues: formValues,
            endpoint: endpoint
        };

            return (
                <WrapperField label={label} required={required} meta={meta}>
                    <button className={`btn ${class_name} col-md-12`}
                            style={{padding:"8px"}}
                                onClick={() => {
                                    clearPrevAccionSeleccionada({});
                                    clearPrevData({});
                                    return this.actionTrigger(triggerable)
                                }}>
                        <strong>{label}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Icon clase={icon} />
                    </button>
                </WrapperField>)
    };

    render() {return this.botonAccion(this.props);}
}

ActionButtonField.defaultProps = {
    label: "label",
    icon: "",
    className: "btn btn-info",
    tipo: "button",
    endpoint:""
};

const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors('form')(state),
    formValues: getFormValues('form')(state),
    data: getAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    modal: getModal(state),
    previousData: state.accionValor,
    previousAccion: state.accionSeleccionada
});
export default connect(mapStateToProps,{fetchAccionData,
    setAccionData,
    setAccionSeleccionada,
    setAccionDinamica,
    mostrarModal,
    showModal,
    setPreviousData,
    setPrevAccionSeleccionada,
    clearPrevData,
    clearPrevAccionSeleccionada
   })(ActionButtonField);