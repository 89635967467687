import React from 'react';
import Parser from 'html-react-parser';

const DescriptionField = ({field}) => {
    /*
        field.placeholder   en BD contiene      icono
        field.label         en BD contiene      titulo
        field.defaultValue  en BD contiene      texto presentado en el interior EN HTML
     */
    return (
        <div className="alert alert-dismissible text-justify col-md-12 col-xs-12" style={{backgroundColor:"rgba(0, 192, 239, 0.3)", color:"#4d4d4d"}}>
            <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
            <h4><i className={`icon ${field.placeholder}`}></i> {field.label}</h4>
            {Parser(field.defaultValue)}
        </div>
    );
};


export default DescriptionField;