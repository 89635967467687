import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../helpers/util';

class Treeview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            s: ''
        };
    }
    render() {
        const { modulo: {icono, nombre}, extraInfo, extraInfoClass, children} = this.props;
        const cambiarClase = () => {
            /* esta funcion obliga el re-render del componente con la clase (state)
             * diferente no hay animacion de transicion de clase activa a desactivada
             * es momentanea hasta que se encuentre algo correcto
            */
            (this.state.s === 'active')
                ? this.setState({s:''})
                : this.setState({s:'active'});
        };
        return (
            <li className={`${this.state.s} treeview`} onClick={ () => cambiarClase()}>
                <a href="#treeview">
                    <Icon clase={icono || "fa fa-share"}/>
                    <span>  {nombre}</span>
                    {extraInfo && <span className="pull-right-container">
                <span className={extraInfoClass}>
                    {extraInfo}
                </span>
            </span>}
                </a>
                {children && <ul className="treeview-menu">
                    {children}
                </ul>}
            </li>
        );
    }
}

Treeview.propTypes = {
    modulo: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    extraInfo: PropTypes.string,
    extraInfoClass: PropTypes.string
};

export default Treeview;
