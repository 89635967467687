import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({desde, anio, version})=> {
    return (
        <footer className="main-footer">
            <div className="pull-right hidden-xs">
                <small><b>Versi&oacute;n</b> {version} </small>
            </div>
            <small>
                {desde === anio &&
                <strong>Copyright &copy; {desde} <a href="http://minjusticia.gob.cl" target={"_blank"}>Ministerio de Justicia y Derechos humanos</a>.</strong>
                }
                {desde < anio &&
                <strong>Copyright &copy; {desde}-{anio} <a href="http://minjusticia.gob.cl" target={"_blank"}>Ministerio de Justicia y Derechos humanos</a>.</strong>
                }
                Todos los derechos reservados.
            </small>

        </footer>
    );
};

Footer.propTypes = {
    desde: PropTypes.number.isRequired,
    anio: PropTypes.number.isRequired,
};

Footer.defaultProps = {
    desde: 2016,
    anio: new Date().getFullYear(),
    version: "0.1.0 (ccr)",
};

export default Footer;