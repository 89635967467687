import {getToken} from "../helpers/Token";
import {validarError} from "./ValidarError";
import axios from 'axios';
import {envDomain} from "../config";
import {getValidHeader, postValidHeader} from "./validHeaders";
import {postHeaderUndefined, getHeaderUndefined} from "./invalidHeaders";

export const apiPut = async (url, obj) => axios.put(url, obj)
            .then(c => c.data)
            .catch(err => {
                return validarError(err)
            });

export const apiPost = async (url, obj) => axios.defaults.headers.common.Authorization ? postValidHeader(url, obj) : postHeaderUndefined(url,obj);

export const apiGet = (url) => async () => axios.defaults.headers.common.Authorization ? getValidHeader(url) : getHeaderUndefined(url);

