import React, {Component} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_en_US from "@amcharts/amcharts4/lang/es_ES";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import myTheme from "@amcharts/amcharts4/themes/material";
import myTheme from "@amcharts/amcharts4/themes/kelly";
//import darkTheme from "@amcharts/amcharts4/themes/amchartsdark";
//import myTheme from "@amcharts/amcharts4/themes/moonrisekingdom";
//import myTheme from "@amcharts/amcharts4/themes/patterns";
//import myTheme from "@amcharts/amcharts4/themes/spiritedaway";
import darkTheme from "@amcharts/amcharts4/themes/dark";

import {connect} from "react-redux";

import {getDataSistemaSeleccionado} from "../../../../selectors/estructura";
import {getUserProfileData} from "../../../../selectors/keycloak";
import {getStats} from "../../../../selectors/stats";

import PropTypes from 'prop-types';

am4core.useTheme(am4themes_animated);
am4core.useTheme(myTheme);
//am4core.useTheme(darkTheme);

class ColumnChart extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let chart = am4core.create("chartdiv_ColumnChart", am4charts.XYChart);
        //chart.paddingRight = 20;
        //chart.background.fill = am4core.color("##222D32");

        // Add data
        chart.data = [{
            "year": "2016",
            "europe": 2.5,
            "namerica": 2.5,
            "asia": 2.1,
            "lamerica": 0.3,
            "meast": 0.2,
            "africa": 0.1
        }, {
            "year": "2017",
            "europe": 2.6,
            "namerica": 2.7,
            "asia": 2.2,
            "lamerica": 0.3,
            "meast": 0.3,
            "africa": 0.1
        }, {
            "year": "2018",
            "europe": 2.8,
            "namerica": 2.9,
            "asia": 2.4,
            "lamerica": 0.3,
            "meast": 0.3,
            "africa": 0.1
        }];

// Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        categoryAxis.renderer.grid.template.location = 0;


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.inside = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.min = 0;

// Create series
        function createSeries(field, name) {

            // Set up series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.name = name;
            series.dataFields.valueY = field;
            series.dataFields.categoryX = "year";
            series.sequencedInterpolation = true;

            // Make it stacked
            series.stacked = true;

            // Configure columns
            series.columns.template.width = am4core.percent(60);
            series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

            // Add label
            let labelBullet = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueY}";
            labelBullet.locationY = 0.5;
            labelBullet.label.hideOversized = true;

            return series;
        }

        createSeries("europe", "Europe");
        createSeries("namerica", "North America");
        createSeries("asia", "Asia-Pacific");
        createSeries("lamerica", "Latin America");
        createSeries("meast", "Middle-East");
        createSeries("africa", "Africa");

// Legend
        chart.legend = new am4charts.Legend();


        chart.responsive.enabled = true;

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (

            <div className='box-body'>
                <div id="chartdiv_ColumnChart" style={{ width: "100%", height: "500px" }}></div>
            </div>

        );
    }
}

ColumnChart.propTypes = {

};

const mapStateToProps = (state) => ({
    sistemaActual: getDataSistemaSeleccionado(state),
    profileData: getUserProfileData(state),
    stats: getStats(state)
});

export default connect(mapStateToProps,{})(ColumnChart);