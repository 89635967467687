import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import {Icon} from "../../../../../../helpers/util";
import {apiGet} from "../../../../../../api";
import isPlainObject from "redux-actions/es/utils/isPlainObject";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import {getUserProfile, getUserProfileData} from "../../../../../../selectors/keycloak";

class HiddenField extends Component {
    constructor(props){
        super(props);
        this.state={
            options: props.options || null
        }
    }
    TextFieldIcon = ({label, type, placeholder, input, meta, required, icon, disabled}) => (
        <WrapperField label={label} required={required} meta={meta}>
            <div className="input-group">
            <span className="input-group-addon">
                <Icon clase={icon}/>
            </span>
                <input
                    {...input}
                    type={type}
                    disabled={disabled}
                    className="form-control input-sm"
                    placeholder={placeholder}
                />
            </div>
        </WrapperField>
    );

    formOptions = (arreglo, label, value) => {
        if(arreglo){
            return arreglo.map( v => {
                const parent = {...Object.values(v).filter( f => isPlainObject(f))[0]};
                return {label: v[label], value: v[value], parent};
            })
        }
    };

    formateaRut = (rut) => {
        //console.log("start formatter", rut);
        let actual = rut.replace(/^0+/, "");
        if (actual !== '' && actual.length > 0) {
            //console.log("validara el rut: ", actual);
            let sinPuntos = actual.replace(/\./g, "");
            let actualLimpio = sinPuntos.replace(/-/g, "");
            let inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            let j = 1;
            for (let i = inicio.length - 1; i >= 0; i--) {
                let letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                //console.log("validara el rut: ", rutPuntos);
                if (j % 3 === 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return rutPuntos;
    };

    renderCheck = (val) =>{
        console.log("hay que validar");
        this.setState({valido : val});
        this.forceUpdate();
    };

    handleOnChange = (event) => {
        let nombreInput = this.props.input.name.toLowerCase().substring(this.props.input.name.indexOf("."));
        let rut = ["rut", "run"];
        if(rut.some(el => nombreInput.includes(el))){
            let run = this.formateaRut(event.target.value);
            //console.log("run: ", run);
            if(run == null){
                //event.target.value = run;
                //console.log("es indefinido");
                //event.target.value = "";
                //return this.props.input.onChange();
            }
            else {
                event.target.value = run;
                //return this.props.input.onChange();
            }
        }
        return this.props.input.onChange(event);
    };

    handleOnBlur = () => {
        console.log(this.props.input.value);
        if(this.props.massive.filterBy){
            if(this.props.massive.filterBy.indexOf(".") >= 0){
                return Object.values(this.props.formValues).map(fv => {
                    if (isPlainObject(fv)) {
                        const string = fv[this.props.filterBy.toString().substring(this.props.filterBy.indexOf('.'))];
                        var a = this.props.filterBy.substring(this.props.filterBy.indexOf('.')+1);
                        const url = `${this.props.elementoOpcion.endpoint}/${fv[a] || ""}`;
                        apiGet(url)()
                            .then(v => this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value))
                            .then(v => {
                                return this.setState({options: v})
                            });
                    }
                })
            }
            else {
                apiGet(`${this.props.elementoOpcion.endpoint}/${this.props.formValues[this.props.filterBy] || ""}`)()
                    .then(v => this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value))
                    .then(v => {
                        const encontrado = v.filter( s => s.label.toLowerCase() === this.props.input.value.toLowerCase());
                        if(encontrado.length > 0) {
                            console.log("buena, encontrado:", encontrado);
                        }
                        else {
                        }
                        return this.setState({options: v})
                    });
            }
        }
    };

    componentDidMount() {
        const { userdata,input } = this.props;
        if(input.value === ""){
            if(input.name === "id_usuario" || input.name === "username"){
                if(input.name === "id_usuario") input.onChange(userdata.id);
                if(input.name === "username") input.onChange(userdata.username);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
            const { userdata,input } = this.props;
            if(input.value === "" || input.value === "HIDDEN"){
                if(input.name === "id_usuario" || input.name === "username"){
                    if(input.name === "id_usuario") input.onChange(userdata.id);
                    if(input.name === "username") input.onChange(userdata.username);
                }
            }
    }

    SimpleTextField = ({label, type, placeholder, input, meta, required, disabled }) => {
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <input
                    {...input}
                    type={type}
                    disabled={disabled}
                    readOnly
                    className="form-control async-validating"
                    placeholder={placeholder}
                    onBlur={this.handleOnBlur}
                    onChange={this.handleOnChange}
                    style={{
                        padding: "18px",
                        borderRadius: "5px"
                    }}
                />
            </WrapperField>
        );
    };
//<i className={`${this.state.valido} fas fa-check-circle fa-sm`}/>
    render() {
        return this.props.icon ? this.TextFieldIcon({...this.props}) : this.SimpleTextField({...this.props});
    }
}

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state),
    userdata: getUserProfileData(state)
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(HiddenField);


