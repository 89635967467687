import axios from "axios";
import {agibizPostForm, agibizResponseAdapter, updateIncomingData} from "../externalHandling/agibiz";
import {firstLoad} from "./apiConfig";
import {validarError} from "./ValidarError";
import sizeof from "object-sizeof";

export const postHeaderUndefined = async (url, obj) => firstLoad()
    .then(() => axios.post(url, agibizPostForm(obj),sizeof(obj) > 1000000 ? {timeout: 0}:{})
        .then(c => agibizResponseAdapter(c.data || c.response || c ))
        .catch( cat => {
            console.log("catch axiosPost:", cat);
            return validarError(cat);
        }));

export const getHeaderUndefined = async (url) => firstLoad()
    .then(() => axios.get(url).then(res => updateIncomingData(res.data)).catch(cat => {
        console.log("catch axiosGet:", cat);
        return validarError(cat);
        //return cat;
    }));
