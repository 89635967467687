import axios from "axios";
import {envDomain} from "../config";
import {getToken} from "../helpers/Token";

export const axiosHeaderSetter = (token) => {
    //console.log("[API-CONFIG] axios header token updated");
    axios.defaults.baseURL = envDomain;
    axios.defaults.timeout = 60000;

    let newToken =  token.replace('bearer ', '');
    return axios.defaults.headers.common['Authorization'] =  "bearer " + newToken;
};

export const firstLoad = (kcToken) => {
    //console.log("[API-CONFIG] param: kcToken ? ", !!kcToken);
    return kcToken
        ? axiosHeaderSetter(kcToken)
        : getToken().then(token => axiosHeaderSetter(token));
};
