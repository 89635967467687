import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import _ from 'lodash';

class CodResolField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueA:"",
            valueB:"",
            value:""
        }
    }
    handleOnBlur = () => console.log();

    handleOnChangeA = event => {
        this.setState({
            valueA:event.target.value.substring(0,30)
        });
        return this.handleOnChange(event, true);
    }
    handleOnChangeB = event => {
        this.setState({
            valueB: event.target.value.substring(0,4)
        });
        return this.handleOnChange(event, false);
    }
    handleOnChange = (event, source) => {
        event.target.value = `${source ? event.target.value : this.state.valueA}-${!source ? event.target.value : this.state.valueB}`
        return this.props.input.onChange(event)
    };

    splitter = str => this.setState({
            valueA: str.substring(0,str.indexOf("-")),
            valueB: str.substring(str.indexOf("-")+1    ),
            value: str
        });

    componentDidMount() {
        if(this.props.input.value && this.state.value === ""){
            this.props.input.value.includes("-")
                ? this.splitter(this.props.input.value)
                : this.setState({
                    valueA: this.props.input.value,
                    value: this.props.input.value
                })
        }
    }


    render() {
        const {label, meta, required, placeholder, disabled, input} = this.props;
        let type = "text";
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <div className="form-group col-xs-12 col-md-12 col-lg-12" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                    <div className="col-md-7 col-xs-7 col-lg-7" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                        <input
                            {...input}
                            required={required}
                            meta={meta}
                            type={type}
                            disabled={disabled}
                            className="form-control"
                            placeholder="codigo de resolución"
                            onChange={this.handleOnChangeA}
                            onBlur={this.handleOnBlur}
                            autoComplete="off"
                            value={this.state.valueA}
                            style={{ padding: "18px",
                                borderRadius: "5px" }}
                        />
                    </div>
                    <div className="col-md-1 col-xs-1 col-lg-1 text-center" style={{paddingLeft:"0px", paddingRight:"0px", paddingTop:"7px" }}>
                            <p> - </p>
                    </div>
                    <div className="col-md-4 col-xs-4 col-lg-4" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                        <input
                            {...input}
                            required={required}
                            meta={meta}
                            type={type}
                            disabled={disabled}
                            className="form-control text-center"
                            placeholder="año"
                            onChange={event => !isNaN(event.target.value) ? this.handleOnChangeB(event) : console.log("es string")}
                            onBlur={this.handleOnBlur}
                            autoComplete="off"
                            value={this.state.valueB}
                            style={{ padding: "18px",
                                borderRadius: "5px"}}
                        />
                    </div>
                </div>
            </WrapperField>
        );
    }
}
const mapStateToProps = (state) => ({
    fields: state.accionValor.schema.sections.reduce((ant,act) => {
        return !_.isEmpty(ant)
            ? {...Object.values(ant).concat(act.childFields)}
            : {...Object.values(act.childFields)};
        },{})
});
export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(CodResolField);
/*
                        <!--

                        -->
 */