import React from 'react';
import PropTypes from 'prop-types';

const SidebarMenuTree = ({header, children}) => (
    <ul className="sidebar-menu" data-widget="tree">
        <li className="header">{header || "Menu"}</li>
        {children && children}
    </ul>
);

SidebarMenuTree.propTypes = {
    header: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default SidebarMenuTree;