export const convertBooleanToString = (data) => {
    if(Array.isArray(data)){
        const newData = data.map((obj) => {
            var objeto = {};
            for (let [key, value] of Object.entries(obj))
            {
                if (typeof value === 'boolean') {
                    if (value) {
                        objeto= {...objeto, [key]:"Si"}
                    } else {
                        objeto= {...objeto, [key]:"No"}
                    }
                }else {
                    objeto= {...objeto, [key]:value}
                }
            }
            return objeto;
        });
        return newData
    }
    return []
};
/*
export const convertBooleanToStr3ing = (data) => Array.isArray(data) && data
    .map( row => Object.entries(row)
        .map( (key, val) => { [key] : typeof val !== "boolean"
                    ? val
                    : val ? "Si" : "No"}));


    {
    if(Array.isArray(data)){
        const newData = data.map((obj) => {
            var objeto = {};
            for (let [key, value] of Object.entries(obj))
            {
                if (typeof value === 'boolean') {
                    if (value) {
                        objeto= {...objeto, [key]:"Si"}
                    } else {
                        objeto= {...objeto, [key]:"No"}
                    }
                }else {
                    objeto= {...objeto, [key]:value}
                }
            }
            return objeto;
        });
        return newData
    }
    return []
};

 */