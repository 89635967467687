import React, {Component} from 'react';
import Textarea from 'react-textarea-autosize';

class MotivoSolicitud extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: null
        }
    }

    handleOnChange = (event) => {
        console.log("handling changes:", this.props.input);
        this.props.meta.visited = true;
        this.props.meta.touched = true;
        this.setState({ value: event.target.value });
        return this.props.input.onChange(event);
    };


    render() {
        const {input, meta} = this.props;
        console.log("motivo", input);
        console.log("motivo meta", meta);
        return (
            <div>
                <Textarea {...input}
                          {...meta}
                          value={this.state.value}
                          onChange={this.handleOnChange}
                          minRows={5}
                          style={{ width: "100%",
                              borderRadius: "5px" ,
                              zIndex:"0"}}/>
            </div>
        );
    }
}

export default MotivoSolicitud;