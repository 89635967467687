import axios from "axios";
import {agibizPostForm, agibizResponseAdapter, updateIncomingData} from "../externalHandling/agibiz";
import {getHeaderUndefined, postHeaderUndefined} from "./invalidHeaders";
import sizeof from "object-sizeof";

export const postValidHeader = (url, obj) => axios.post(url, agibizPostForm(obj), sizeof(obj) > 1000000 ? {timeout: 0}:{})
        .then(c => agibizResponseAdapter(c.data || c.response || c ))
        .catch( a => {
            console.log("[POST] new token required at:", url);
            return postHeaderUndefined(url,obj);
        });

export const getValidHeader = (url) => axios.get(url).then(res => updateIncomingData(res.data)).catch(cat => {
    console.log("[GET] new token required at:", url);
    return getHeaderUndefined(url).then(ret => ret);
});
