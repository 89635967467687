import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WrapperField from "./WrapperField";

class SingleCheckboxField extends Component {

    onChange = e => {
        let {input: { onChange }} = this.props;
        onChange(e.target.checked);
    };


    render() {
        let {input, disabled, label, required, meta} = this.props;

        let extraInputProps = Object.assign({}, this.props.input, {onChange: this.onChange});

        return (
            <WrapperField meta={meta} label={null} required={required}>
                <div className="checkbox">
                    <h1>CHECKBOX</h1>
                    <label>
                        <input
                            type='checkbox'
                            {...extraInputProps}
                            disabled={disabled}
                            value={input.value}
                            onBlur={()=>input.value}
                        />
                        {label}
                    </label>
                </div>
            </WrapperField>
        );
    }
}

SingleCheckboxField.propTypes = {};

export default SingleCheckboxField;