import React from 'react';
import PropTypes from 'prop-types';

const SideBar = ({children}) => {
    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                {children && children}
            </section>
        </aside>
    );
};

SideBar.propTypes = {
children: PropTypes.node.isRequired,
};

export default SideBar;