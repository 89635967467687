import {genericFieldProps} from '@isobar-us/redux-form-gen';
import CheckboxField from "../Components/CheckboxField";
import SingleCheckboxField from "../Components/SingleCheckboxField";
import {string2Json} from "../../../../../../helpers/util";

const CheckboxType = ({field}) => {
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        required: !!field.required ,
        values: field.options,
        disabled: !!field.disabled,
        component: field.options ? CheckboxField: SingleCheckboxField,
        massive: field
    };
};

export default CheckboxType;
