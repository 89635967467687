import React, {Component} from 'react';
import {apiGet, apiPost} from "../../api"
import {Form, reduxForm} from "redux-form";
import {Frag, Icon} from "../../helpers/util";
import MotivoSolicitud from "../Content/Card/Form/CustomFieldTypes/Components/ExternalComponents/MotivoSolicitud";
import Field from "redux-form/es/Field";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getOcultos, getSelectData} from "../../selectors/select";
import {connect} from "react-redux";
import {setVisible} from "../../actions/setVisible";
import {setHiddenNew} from "../../actions/setHidden";
import {getFormSyncErrors} from "redux-form/es/index";
import {getAccion, getAccionData, getAccionSchema} from "../../selectors/estructura";
import {getModal} from "../../selectors/modal";
import {getPreviousData} from "../../selectors/states";
import {mostrarModal} from "../../actions/mostrarModal";
import {actionHandleSubmit, insertAccionData, submitHandling} from "../../actions/insertAccionData";
import {setAccionData} from "../../actions/setAccionData";
import {updateActionData} from "../../actions/updateActionData";
import {setAccionSeleccionada} from "../../actions/setAccionSeleccionada";
import {fetchAccionData} from "../../actions/fetchAccionData";

class ModalTriggerUrl extends Component {

    modalBuilder = async (urlVista, vals, userInfo, mass) => {
        console.log("llego a modalBuilder");
        let modalStructure = {
            header: null,
            body: null,
            footer: null
        };
        modalStructure.body = this.formulario();
        modalStructure.header = this.titleMaker();
        modalStructure.footer = <div style={{display: "none"}}> </div>;
        return modalStructure;
    };

    titleMaker = () => <div className="text-center">
        <h2>Solicitud de modificación</h2>
        <small>Solicitud para la realización de cambios en el registro.&nbsp;
            <span> </span>
        </small>
    </div>;

    required = value => value ? undefined : 'El campo es requerido';

    handleSubmit = (data) => {
        console.log("ENVIAR FORMVALUES", this.props);
        console.log("ENVIAR MASS", this.props.mass);
        let jsonData = {
            "formularioId" : this.props.formValues.id,
            "userInfo": this.props.keycloakUserInfo,
            "motivo": this.props.mass.form.formularioModal.values.motivo
        };
        console.log("DATA TO SEND", jsonData);
        apiPost("api-entrevistas-videograbadas/api/v1/notificacion/guardarSolicitud", jsonData).then(res => {
            document.elementFromPoint(1,1).click()
            this.props.mostrarModal("Alerta", res.descripcion || res.toString(), null);
        }).catch(cat => {
            document.elementFromPoint(1,1).click()
            this.props.mostrarModal("Alerta", "Ha ocurrido un error", null);
        });
    };

    renderField = ({ input, label, type, meta }) => (
        <div>
            <MotivoSolicitud input={input} meta={meta} />
            {meta.touched && ((meta.error && this.renderError(meta.error)) || (meta.warning && this.renderError(meta.warning)))}
        </div>
    );

    renderError = (error) => <div className="pull-right">
                    <span style={{color:'#dd4b39'}} >
                        <Icon clase="fas fa-times-circle"/> {error}
                    </span>
    </div>;

    formulario = props => (
        <Form onSubmit={props.handleSubmit}
              style={{padding:"0", margin:"0"}}>
            <div className="box box-default no-border" style={{borderRadius:"7px",   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
                <div className="box-header with-border" style={{paddingTop:"15px"}}>
                    <h4>Motivo de solicitud de modificación</h4>
                    <div className="box-tools pull-right" style={{paddingTop:"10px"}}>
                        <button type="button" className="btn btn-box-tool"  data-toggle="collapse" data-target={`#m0d4l`}>
                            <i className="fa fa-minus icon"> </i>
                        </button>
                    </div>
                </div>
                <div className="box-body collapse in" id="m0d4l">
                    <Field name="motivo"
                           component={this.renderField}
                           validate={[this.required]}
                    />
                </div>
            </div>
            <div className="box-footer col-md-12" style={{ backgroundColor:"#F9F9F9",borderRadius:"10px", marginBottom: "0px", marginTop:"30px"}}>
                <div className="col-md-12" style={{margin:"0px", padding:"0px", height:"35px"}} >
                    <div className="col-md-12 pull-right">
                        <div className="btn-group-md">
                            <button className="btn btn-danger text-center" onClick={
                                () => document.elementFromPoint(1,1).click()
                            }
                                    style={{marginRight:"15px"}}>
                                <strong>
                                    CANCELAR
                                </strong>
                            </button>
                            <button className="btn btn-primary text-center" disabled={props.submitting} onClick={() => this.handleSubmit(props)}>
                                <strong>
                                    ACEPTAR
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );

    render() {
        return (
            this.formulario(this.props)
        );
    }
}
const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors('form')(state),
    formValues: getFormValues('form')(state),
    data: getAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    modal: getModal(state),
    previousData: getPreviousData(state),
    keycloakUserInfo: state.userInfo,
    previousAccion: state.previousAccion,
    previousData: state.previousData,
    previousForm: state.previousForm,
    mass: state
});

export default connect(mapStateToProps,{mostrarModal, insertAccionData, setAccionData, updateActionData, actionHandleSubmit ,submitHandling, setAccionSeleccionada ,fetchAccionData})(reduxForm({
    form: 'formularioModal'
})(ModalTriggerUrl));