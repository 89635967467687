const token = require(process.env.REACT_APP_TOKEN);
const kc = require(process.env.REACT_APP_KC);
const envDomain = process.env.REACT_APP_DOMAINIP;
const urlBase = "backend-framework-justicia/api/v1";
const urlSistemas = `${urlBase}/sistemas`;
const urlModulos = `${urlBase}/modulos/sistema`;
const domainServers = eval(process.env.REACT_APP_DOMAINSERVERS);
export {
    domainServers,
    envDomain,
    kc,
    token,
    urlBase,
    urlSistemas,
    urlModulos
};
