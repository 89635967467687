import {fileOpener, Icon, string2Json} from "../../../../helpers/util";
import React from "react";
import ReactToolTip from "react-tooltip"
import {deleter} from "../../../../helpers/util/Deleter";
import {methodSwitchGrid} from "../../../../helpers/util/methodSwitchGrid";

const onClickActionButton = async paramsGroup => {
    let {
        values,
        row,
        params,
        flagAddData,
        mostrarModal,
        showModal,
        setAccionData2,
        aux,
        setAccionData,
        setAccionSeleccionada,
        keycloakUserInfo
    }  = paramsGroup;
    if(values && values.componenteVista === "fileOpener" && params) fileOpener(`${row.value[params]}`);
    if(values && values.componenteVista === "deleter" && params)deleter(`${values.endpoint}/${row.value[params]}`, values.redirect, values.descripcion, mostrarModal, values.methodForm, showModal, aux ? setAccionData2 : null, setAccionData,setAccionSeleccionada,keycloakUserInfo);
    if(values.methodForm === "DUALENDPOINTS") values.endpoint = {pre: `${string2Json(values.endpointAux).pre}/${row.value[params]}`,
                                                                 post: `${string2Json(values.endpointAux).post}/${row.value[params]}`};
    values = values && values.endpointAux && params && values.methodForm !== "DUALENDPOINTS" ? {...values, endpoint: `${values.endpointAux}/${row.value[params]}`} : values;
    if(values && values.methodForm === 'POST') flagAddData = true;
    return await methodSwitchGrid({...paramsGroup, values: values, flagAddData: flagAddData});
};

const ActionButton = paramsGroup => <button
        data-tip={`Ir a ${paramsGroup.values.nombre}`}
        key={paramsGroup.values.id}
        type="button"
        className="btn btn-default btn-xs"
        onClick={() => onClickActionButton(paramsGroup)}>
        <Icon clase={paramsGroup.values.icono}/>
        <ReactToolTip />
    </button>;


export default ActionButton;
