import React from 'react';
import WrapperField from "./WrapperField";


const BlankField = () => {
    return (
        <WrapperField label="" required="">
            <h1>blank field</h1>
            <input style={{display:"none"}}/>
        </WrapperField>
    );
};

export default BlankField;

//<div className={"form-control input-lg"} style={{opacity:0}}/>