import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'react-bootstrap';
import {destroy, getFormSyncErrors, getFormValues, reset, startSubmit, stopSubmit} from "redux-form/es/index";
import {getAccion, getAccionData, getAccionSchema} from "../../selectors/estructura";
import {getModal} from "../../selectors/modal";
import {getPreviousData} from "../../selectors/states";
import connect from "react-redux/es/connect/connect";
import {fetchAccionData} from "../../actions/fetchAccionData";
import {setGridAccionData} from "../../actions/setGridAccionData";
import {setAccionData, setAccionData2} from "../../actions/setAccionData";
import {goBack} from "../../actions/navigation";
import {setAccionDinamica, setAccionSeleccionada} from "../../actions/setAccionSeleccionada";
import {mostrarModal, showModal} from "../../actions/mostrarModal";
import {apiPost} from "../../api";

class Calificar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            m: false
        };
    };

    botonCerrar = (handleModal) => <Button className="btn btn-default" onClick={() => handleModal()}>Cerrar</Button>;

    componentWillUnmount() {
        this.setState({
            m: false
        });
        this.forceUpdate();
        this.props = undefined;
    }

    verifyMeyhod = ({endpoint, method, formValues}) => {
        return <div className="btn-group-md">
            <button className="btn btn-danger"
                    onClick={() => this.props.showModal(false)}>
                <strong>
                    CANCELAR
                </strong>
            </button>
            <button className="btn btn-primary text-center" type="submit"
                    onClick={() => {
                        console.log("click");
                        switch (method) {
                            case "POST": apiPost(endpoint, formValues).then(res => {
                                console.log("res nueva", res)
                                this.props.showModal(false);
                                this.props.setAccionData2(res);
                            });
                                break;
                            case "GET" : console.log("GET");
                                break;
                            default    : console.log("default")
                        }
                    }
                    }>
                <strong>
                    ACEPTAR
                </strong>
            </button>
        </div>
    }

    render(){
        const {body, handleModal} = this.props;
        return(
            <div>
                <Modal className="modal" show={this.props.m} onHide={() => handleModal()} bs animation={true} size={"lg"} >
                    <Modal.Header closeButton style={{
                        border:"none"
                    }}>
                        <div className="box-title">
                            <Modal.Title>{body && body.header && body.header}</Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{
                        border:"none"
                    }}>
                        <div className="col-md-12" >
                            <div className="text-center">
                                <strong>{body && body.body && body.body}</strong>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{
                        border:"none"
                    }}>
                        {body && body.footer && !Array.isArray(body.footer) && body.footer}
                        {(body && body.footer && body.footer)
                            ? Array.isArray(body.footer)
                                ? this.verifyMeyhod(body.footer[0])
                                : console.log()
                            : this.botonCerrar(handleModal)}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };
};

Calificar.defaultProps = {
    body: "Ha ocurrido un error!"
};

const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors('form')(state),
    formValues: getFormValues('form')(state),
    data: getAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    modal: getModal(state),
    previousData: getPreviousData(state),
    keycloakUserInfo: state.userInfo,
    previousAccion: state.previousAccion,
    previousData: state.previousData,
    previousForm: state.previousForm
});
export default connect(mapStateToProps,{fetchAccionData, setGridAccionData ,setAccionData, destroy, reset,goBack , setAccionData2, setAccionSeleccionada,setAccionDinamica, mostrarModal, showModal, startSubmit, stopSubmit})(Calificar);