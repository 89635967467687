import {FETCH_SISTEMAS} from "../constants";
import {createAction, } from 'redux-actions';
import {apiGet,apiPost} from "../api";
import {urlSistemas} from "../config";
import {unirUrl} from "../helpers/util";
import {fetchUserProfileInfo} from "./fetchUserProfileInfo";

const fetchSistemas=createAction(FETCH_SISTEMAS, url => apiGet(url)());

export const fetchEstructura = () => {
    return (dispatch, getState) => {
        const {keycloak} = getState();
        dispatch(fetchUserProfileInfo(keycloak.loadUserInfo)).then(
            res=> {
                console.log(res);
                if(res && res.value && res.value.gruposUsuario && res.value.gruposUsuario.length){
                    apiPost("/api-entrevistas-videograbadas/api/v1/auditoria",{idUsuario:res.value.sub,accion:"INGRESO A SISTEMA"}).then(r => console.log(r)).catch(e => console.log(e))
                    dispatch(fetchSistemas(unirUrl(urlSistemas,"/grupos-usuario", res.value.gruposUsuario.map(grupo => grupo.replace("/","")))))
                }
            }
        )
    }
};
