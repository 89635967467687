import React from 'react';
import TextType from "./Type/TextType";
import TextAreaType from "./Type/TextAreaType";
import RadioType from "./Type/RadioType";
import SelectType from "./Type/SelectType";
import CheckboxType from "./Type/CheckboxType";
import fileType from "./Type/fileType";
import {SeparadorType} from "./Type/SeparadorType";
import SectionType from "./Type/SectionType";
import {BlankType} from "./Type/BlankType";
import ButtonType from "./Type/ButtonType";
import {HiddenType} from "./Type/HiddenType";
import {DescriptionType} from "./Type/DescriptionType";
import {DateType} from "./Type/DateType";
import CommentType from "./Type/CommentType";
import {ActionButtonType} from './Type/ActionButtonType';
import {LabelContentType} from "./Type/LabelContentType";
import equalsType from "./Type/EqualsType";
import {CodResolType} from './Type/CodResolType'

const CustomFieldTypes = {
    text: TextType,
    radio: RadioType,
    textarea: TextAreaType,
    select: SelectType,
    checkbox: CheckboxType,
    file: fileType,
    separador: SeparadorType,
    section: SectionType,
    blank: BlankType,
    hidden: HiddenType,
    DescriptionField: DescriptionType,
    DateField: DateType,
    CommentField: CommentType,
    action: ActionButtonType,
    lbContent: LabelContentType,
    equalsField : equalsType,
    CodResolType
};
export default CustomFieldTypes;

//eliminar error de "separador en consola"
//Archivo: /node_modules/@isobar-us/redux-form-gen/lib/GenField.js
//linea 192:
//console.error('Form Generator: unknown field type "' + field.type + '". \nField:', field, '\n. skipping render.');
//https://github.com/isobar-us/redux-form-gen