import {string2Json} from "../../../../../../helpers/util";
import {genericFieldProps} from "@isobar-us/redux-form-gen";
import CodResolField from "../Components/CodResolField";

export const CodResolType = (props) => {
    const {field} = props;
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        name: field.questionId,
        required: !!field.required,
        component: CodResolField,
        label: field.label,
        options:field.options,
        disabled: !!field.disabled,
        placeholder: field.placeholder,
        isMulti: !!field.multi,
        elementoOpcion: field.elementoOpcion,
        filterBy: field.filterBy,
        mostrar: field.visible,
        icon: field.icon ? field.icon : false,
        massive: field,
        conditionals: field.conditionalValid,
        visible:field.visible
    };
};