import React, {Component} from 'react';
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import {string2Json} from "../../../../../../helpers/util";
import WrapperField from "./WrapperField";
import { Field } from 'redux-form';

const { detect } = require('detect-browser');

class LabelContentField extends Component {
    constructor(props){
        super(props);
        this.state={
            content: null,
            value: "value"
        }
    }

    typeofEval = value =>  {
        this.props.input.onChange(value && typeof value !== "string"
            ? Object.values(value).reduce( (anterior, actual, index) => {
                 return actual !== null
                     ? {...anterior, [Object.keys(value)[index]]:actual}
                     : {...anterior}
              },{})
            : value);
        return value && typeof value !== "string"
                ? Object.values(value).map( (attribute, index) => attribute && this.lineOrder(attribute,
                    this.props.massive.conditionalVisible
                        ? this.props.massive.conditionalVisible[index]
                        : Object.keys(value)[index]))
                : this.lineOrder(value)
    };

    renderContent = ({input:{value}, massive, placeholder}) => {
        if(placeholder && !value) {
            return this.typeofEval(placeholder.includes('__')
                ? eval(`this.props.${placeholder.substring(2)}`)
                : placeholder, massive);
        }
        if(value && !placeholder){
            return this.typeofEval(value, massive)
        }
    };

    lineOrder = (val, key) => this.props.massive.multi && key
            ? <div><strong>{key}</strong>&nbsp;&nbsp;&nbsp;&nbsp;{val}<br/></div>
            : <div>{val}<br/></div>;

    componentDidMount() {
        this.setState({
            value: this.renderContent(this.props)
        })
    }

    render(){
        return <WrapperField label={this.props.label} meta={this.props.meta} required={this.props.required}><br/>
                    <p style={{marginTop:"10px", marginBottom:"8px"}}>{this.state.value}</p>
                </WrapperField>;
    }
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state),
    keycloakUserInfo: state.userInfo
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(LabelContentField);


