import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Content from "../components/Content";
import {
    getAccion,
    getBreadCrumb,
    getComponenteVista,
    getLoadingState,
    getModulosDeSistema
} from "../selectors/estructura";
import {insertAccionData} from "../actions/insertAccionData";
import Header from "../components/Content/Card/Header";
import Formulario from '../components/Content/Card/Form';
import Grid  from './../components/Content/Card/Grid';
import Profile from "../components/Content/Card/Profile";
import Welcome from "../components/Content/Card/Welcome";
import Stats from  "../components/Content/Card/Stats"
import {updateActionData} from "../actions/updateActionData";
import LoadingBar from 'react-redux-loading-bar';
import SelectorConectado from "../helpers/selectorConectado";
import PieChart from "../components/Content/Card/Chart/PieChart";
import Chart from "../components/Content/Card/Chart";



const View = () => <h1>View</h1>;



class ContentContainer extends Component {

    handleSubmit = (values) => {
        if(this.props.schema.columns) {
           this.props.insertAccionData(values, this.props.schema.urlSubmit);
        }
    };

    renderTipo = (tipo) => {
        const TipoControl = this.seleccionarTipo(tipo);
        return <TipoControl />
    };

    seleccionarTipo = tipo =>{
        switch (tipo) {
            case "view": return View;
            case "grid": return Grid;
            case "form": return Formulario;
            case "Profile": return Profile;
            case "stats": return Stats;
            case "graficos": return Chart;
            default: return Welcome;
        }
    };


render() {
 return <Content loading={this.props.loading}>
     <LoadingBar style={{height:'5px', backgroundColor: '#338bb8'}} showFastActions/>
     <Header {...this.props.bread}/>
     <section className="content">
         <div className="row">
             <div className={`col-md-12`}>
                 {this.renderTipo(this.props.componenteVista)}
             </div>
         </div>
     </section>
 </Content>;
    }
}

ContentContainer.propTypes = {
accion: PropTypes.object.isRequired,
schema: PropTypes.object,
data: PropTypes.array,
};

ContentContainer.defaultProps = {
accion:{},
data:[],
schema:{
 columns:[]
}
};


const mapStateToProps = (state) => ({
    accion: getAccion(state),
    componenteVista: getComponenteVista(state),
    sistemaActual: getModulosDeSistema(state),
    bread: getBreadCrumb(state),
    loading: getLoadingState(state)
});

export default connect(mapStateToProps, {insertAccionData, updateActionData})(ContentContainer);
