import React from 'react';
import {genericFieldProps} from "@isobar-us/redux-form-gen";
import fileField from "../Components/fileField";
import {string2Json} from "../../../../../../helpers/util";

const FileType = ({field}) => {
    if(!field.conditionalValid) delete field['conditionalValid'];
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        required: field.required,
        component: fileField,
        type: field.type,
        disabled: !!field.disabled,
        accept: field.conditionalValid.accept || "*",
        limit: field.conditionalValid.limit || 5242880,
        maxLength: field.conditionalValid.size || 1024,
        holdId: field.conditionalValid.holdId || false
    };
};

export default FileType;