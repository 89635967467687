import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WrapperField from "./WrapperField";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getOcultos, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden, setHiddenNew, clearHiddenNew} from "../../../../../../actions/setHidden";
import {parentFinder, shouldHide, string2Json} from "../../../../../../helpers/util";

class CheckboxField extends Component {

    constructor(props){
        super(props);
        this.state={
            value: "",
            valid: false,
            invalid: true,
            touched: false,
            active: false,
            visited: false,
            selected: null,
            loaded: false
        }
    }

    onChange = e => {

    };

    componentDidUpdate = (prevProps, prevState, snapshot) =>  {
        this.state.selected && this.props.massive.visible && this.evalHide(string2Json(this.props.massive.visible), this.props.formValues, false);
        this.props.massive.visible && prevState.selected && Object.values(this.state.selected).find(a => !a) !== undefined && this.evalHide(string2Json(this.props.massive.visible), this.props.formValues, true);
        if(this.state.selected !== null && this.state.invalid && this.state.loaded){
        }
    };



    evalHide = (condition, values, show) => {
        Array.isArray(condition.parent)
            ? condition.parent.map(cond => shouldHide(cond, values) && show ? this.props.clearHiddenNew(cond) : this.props.setHiddenNew(cond))
            : shouldHide(condition, values) && show
                ? this.props.clearHiddenNew(condition.parent)
                : this.props.setHiddenNew(condition.parent)
    };

    checkSelected = (formValues, name, val) => {
        let vaal = this.checker(formValues,name,val);
        Object.values(vaal).filter( sel => sel).length === 0
            ?   this.setState({
                valid : false,
                invalid: true,
            })
            :   console.log();
        this.setState({
            selected: vaal
        });
    };

    validator = (flag) => {
        return flag
            ? this.setState({
                valid: true,
                invalid: false,
                touched: true
            })
            : this.setState({
                valid: false,
                invalid: true,
                touched: true
            });
    };

    componentDidMount() {
        let {input, values, disabled, label, required, meta, formValues} = this.props;
        let {loaded} = this.state;
        if( !this.state.loaded && values){
            if(formValues.hasOwnProperty(input.name) && formValues[input.name] !== null) {
                this.setState({
                    selected: formValues[input.name]
                });
                Object.values(formValues[input.name]).filter( val => val ).length === 0
                    ? this.validator(false)
                    : this.validator( true);
            }
        }
    }

    handleChange = (event) => {
        this.setState({
            valid : true,
            invalid: false,
            touched:true
        });
        this.checkSelected(this.props.formValues,this.props.input.name, event.target.value);
        this.props.input.onChange(this.checker(this.props.formValues,this.props.input.name,event.target.value))
    };

    checker = (formValues, name, val) => {
        return !formValues.hasOwnProperty(name)
            ?   {[val]:true}
            :   formValues.hasOwnProperty(name) && !formValues[name].hasOwnProperty(val)
            ? {...formValues[name], [val]:true}
            : { ...formValues[name], [val]: !formValues[name][val]};
    }

    checkPre = (formValues, name, val, res) => {
        return (formValues.hasOwnProperty(name) && formValues[name].hasOwnProperty(val))
          ? {[val]:formValues[name][val]}
          : {[val]:res};
    };

    render() {
        let {input, values, disabled, label, required, meta, massive} = this.props;
        let extraInputProps = Object.assign({}, this.props.input, {onChange: this.onChange});
        this.props.meta.valid = this.state.valid;
        this.props.meta.invalid = this.state.invalid;
        this.props.meta.touched = this.state.touched;
        this.props.meta.visited = this.state.visited;
        this.props.meta.active = this.state.active;
        return (
            <div className="col-md-12 input-group-lg">
                <WrapperField meta={meta} label={label} required={required} questionId={massive.questionId} massive={massive}><br/>
                    {values && values.map(({label, value}, index) => {
                        const id = `${input.name}-${value}`;
                        return (
                            <div className="col-md-6">
                                <div className="checkbox" disabled={disabled} onChange={this.handleChange}>
                                    <label key={index} htmlFor={id}>
                                        <input
                                            key={index}
                                            type='checkbox'
                                            checked={this.state.selected !== null && this.state.selected[label]}
                                            {...extraInputProps}
                                            id={id}
                                            disabled={disabled}
                                            value={value}
                                            onBlur={value}
                                        />
                                        {label}
                                    </label>
                                </div>
                            </div>
                        );
                    })}
                </WrapperField>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state),
    ocultos: getOcultos(state)
});

export default connect(mapStateToProps , {setSeleccion, setVisible, clearHiddenNew, setHiddenNew })(CheckboxField)