import React from 'react';
import {Icon} from "../../../helpers/util";

const Header = ({sistema, modulo, accion}) => {
    var actual = {titulo:"MinjuEscritorio", descripcion:"Framework Justicia"};
    if(sistema && sistema.nombre) actual={...actual, titulo: sistema.nombre, descripcion:sistema.descripcion};
    if(modulo && modulo.nombre) actual={...actual, titulo: modulo.nombre, descripcion:modulo.descripcion};
    if(accion && accion.nombre) actual={...actual, titulo: accion.nombre, descripcion:accion.descripcion};
    return (
        <section className="content-header">
            <h1>
                {actual.titulo}
                <small>{actual.descripcion}</small>
            </h1>
            <ol className="breadcrumb">
                {sistema && sistema.nombre &&
                <li>
                    <a href="#">
                        {sistema.icono && <Icon clase={sistema.icono}/> }
                        {' '+ sistema.nombre}
                    </a>
                </li>}
                {modulo && modulo.nombre &&
                <li>
                    <a href="#">
                        {modulo.icono && <Icon clase={modulo.icono}/>}
                        {' '+ modulo.nombre}
                    </a>
                </li>}
                {accion && accion.nombre &&
                <li>
                    <a href="#">
                        {accion.icono && <Icon clase={accion.icono}/>}
                        {' '+ accion.nombre}
                    </a>
                </li>}
            </ol>
        </section>
    );
};

Header.propTypes = {
};

Header.defaultProps = {
};
export default Header;