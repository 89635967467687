import {createBrowserHistory} from "history";
import configureStore from "../../store/configureStore";
import {keycloak} from "../../keycloak-config-framework";
import {axiosHeaderSetter, firstLoad} from "../../api/apiConfig";
import App from "../../App";
import {render} from "../../index"

export const history = createBrowserHistory();
export const store = configureStore(history);
export const init = () => keycloak.init({onLoad: 'check-sso',checkLoginIframe: false}).success(authenticated => {
    if (authenticated) {
        store.getState().keycloak = keycloak;
        //console.log("TOKEN?", !!keycloak.token);
        console.log(store.getState().keycloak);
        !!keycloak.token ? axiosHeaderSetter(keycloak.token) : console.log("no viene token...");
        setInterval(() => keycloak.updateToken(5).success(s => {
            //console.log("[INIT] Keycloak library response (UpdateToken => success)", s);
            return s ? firstLoad(keycloak.token) : console.log("[INIT] [KEYCLOAK] Valid Token")
        }).error(err => {
            //console.log("[INIT] Keycloak library response (UpdateToken => error)", err);
            return keycloak.logout()
        }),10000);
        render(App);
    } else {
        keycloak.login();
    }
}).error(() => render(App));