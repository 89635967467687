import React from "react";
import ModalTrigger from './modalTrigger';
import {connect} from 'react-redux';
import {mostrarModal} from "../../actions/mostrarModal";
import mapDispatchToProps from "react-redux/es/connect/mapDispatchToProps";

export const modalTrigger = (header,body,footer,modal) => {
    modal(header,body,footer);
    return <ModalTrigger header={"header"} body={"body"} footer={"footer"}/>;
};


export default connect( mapDispatchToProps,{mostrarModal})(modalTrigger);