import React from 'react';
import PropTypes from 'prop-types';
import {Frag} from "./Frag";

const Icon = ({clase})=> {
    return (
        <Frag>
            <i className={clase}></i>&nbsp;
        </Frag>
    );
};

Icon.propTypes = {
    clase: PropTypes.string.isRequired,
};

export default Icon;