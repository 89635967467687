import {handleActions} from 'redux-actions';
import {
    FETCH_ACCION_DATA,
    FETCH_ACCION_SCHEMA,
    CLEAR_DATA, CLEAR_SCHEMA, SET_URL_SUBMIT, SET_ACCION_DATA, SET_GRID_ACCION_DATA, LOAD_PREV, LOAD_FORM_VALS
} from "../constants";

export const accionValor = handleActions({
    [`${FETCH_ACCION_SCHEMA}_FULFILLED`]: (state, action) => ({...state, schema: action.payload}),
    [`${FETCH_ACCION_DATA}_FULFILLED`]: (state, action) => ({...state, data: action.payload}),
    [CLEAR_DATA]: (state) => ({...state, data:[]}),
    [CLEAR_SCHEMA]: (state) => ({...state, schema:{}}),
    [SET_URL_SUBMIT]: (state, action) => ({...state, schema: {...state.schema, urlSubmit: action.payload}}),
    [SET_ACCION_DATA]: (state, action) => ({...state, data:action.payload}),
    [SET_GRID_ACCION_DATA]: (state, action) => ({...state, gridData:action.payload}),
    [LOAD_PREV]: (state, action) => ({...state, loadPrev:action.payload}),
    [LOAD_FORM_VALS]: (state, action) => ({...state, formValues:action.payload}),

},{
    data:[],
    gridData: [],
    schema:{},
    error: null,
    loadPrev: false,
    formValues: null
});