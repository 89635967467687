import React from 'react';
import {GenField} from '@isobar-us/redux-form-gen';
import {FormSection} from 'redux-form';
import {Frag} from "../../../../../../helpers/util";

export const SectionLabel = (props) => {
    const {field, path} = props;
    const label = field.label ? <div className='generated-form__header col-md-12'>{field.label}</div> : null;

    let colapsar = label.props.children !== null ? label.props.children.replace(/\s/g,'') : "static";

    const Wrapper = field.questionId ? FormSection : Frag;
    return (

        <Frag>
            <div className="box box-default no-border" style={{borderRadius:"7px",   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
                <div className="box-header with-border" style={{paddingTop:"15px"}}>
                    {label}
                    <div className="box-tools pull-right" style={{paddingTop:"10px"}}>
                        <button type="button" className="btn btn-box-tool"  data-toggle="collapse" data-target={`#${colapsar}`}>
                            <i className="fa fa-minus icon"> </i>
                        </button>
                    </div>
                </div>
                <div className="box-body collapse in" id={`${colapsar}`}>
                    <Wrapper name={field.questionId} >
                        {field.childFields &&
                        field.childFields.map((field, index) => (
                            <GenField key={index} field={{...field}} path={`${path}.childFields[${index}]`} />
                        ))}
                    </Wrapper>
                </div>
            </div>
        </Frag>
    );
};

export const sectionType = ({field}) => ({
    _genComponent: SectionLabel,
    _genSkipChildren: true
});

export default sectionType;
