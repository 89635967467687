import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from "../../helpers/util";
import { cambiarClaseBody } from "../../helpers/util";


const SideApp = ({ sistemas, handleOnClick }) => {

    const colors = [
        {
            hex: '#338bb8',
            class: "skin-blue"
        },
,        {
            hex: '#615ca4',
            class: "skin-purple"
        },
        {
            hex: '#00a55e',
            class: "skin-green",
        },
        {
            hex: '#e24c42',
            class: "skin-red",
        },
        {
            hex: '#f79b34',
            class: "skin-yellow",
        },
        {
            hex: '#338bb8',
            class: "skin-blue-light",
        },
        {
            hex: '#D0D0D0',
            class: "skin-black-light",
        },
        {
            hex: '#615ca4',
            class: "skin-purple-light",
        },
        {
            hex: '#00a55e',
            class: "skin-green-light",
        },
        {
            hex: '#e24c42',
            class: "skin-red-light",
        },
        {
            hex: '#f79b34',
            class: "skin-yellow-light"
        }
];
    return (
            <aside className="control-sidebar control-sidebar-dark" id="sideapp">
                <ul className="nav nav-tabs nav-justified control-sidebar-tabs">
                    <li><a href="#control-sidebar-sistemas-tab" data-toggle="tab"><Icon clase="fas fa-toolbox"/></a></li>
                    <li><a href="#control-sidebar-config-tab" data-toggle="tab"><Icon clase="fa fa-wrench"/></a></li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active" id="control-sidebar-sistemas-tab">
                        <h4 className="control-sidebar-heading">Mis Sistemas</h4>
                        <ul className="control-sidebar-menu">
                            {
                                sistemas ? sistemas.map( (sistema) => {
                                    if(!sistema.disabled ) return(
                                        <li key={sistema.id}>
                                            <a href="#" onClick={() => handleOnClick(sistema)}>
                                                <i className={`menu-icon ${sistema.icono || "fas fa-rocket"}`}></i>
                                                <div className="menu-info">
                                                    <h4 className="control-sidebar-subheading">{sistema.nombre && sistema.nombre}</h4>
                                                    {sistema.descripcion && <p>{sistema.descripcion}</p>}
                                                    {sistema.mantenimiento && (<span className="pull-right-container">
                                                        <small className="label pull-right bg-red">Mantenimiento</small>
                                                    </span>)}
                                                </div>
                                            </a>
                                        </li>
                                    );
                                }): null
                            }
                        </ul>
                    </div>
                    <div className="tab-pane" id="control-sidebar-config-tab">
                        <h4 className="control-sidebar-heading">Temas</h4>
                        <ul className="list-unstyled">
                            {colors.map( color => {
                                return(
                                    <div>
                                        <li className="col-xs-12">
                                            <a href="#" onClick={()=> cambiarClaseBody(color.class)}>
                                                <div className="btn btn-primary text-right col-xs-12" style={{backgroundColor:`${color.hex}`, opacity: 0.9}}>
                                                    <div className="col-xs-10 text-left">
                                                        <strong style={{color:"#F0F0F0"}}>{color.class.substring(color.class.indexOf("-")+1)}</strong>
                                                    </div>
                                                    <i className="icon fa fa-paint-brush">
                                                </i>
                                                </div>
                                            </a>
                                        </li>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </aside>

    );
};

SideApp.propTypes = {
    sistemas: PropTypes.array,
};

export default SideApp;