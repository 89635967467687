import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SideBar from "../components/SideBar";
import UserPanel from "../components/SideBar/UserPanel";
import SidebarMenuTree from "../components/SideBar/SidebarMenu/SidebarMenuTree";
import {getModulosDeSistema} from "../selectors/estructura";
import {setAccionSeleccionada} from "../actions/setAccionSeleccionada";
import {getKeycloak, getUserProfile} from "../selectors/keycloak";
import {fetchUserProfileData} from "../actions/fetchUserProfileData";
import {setSelectedAction} from "../actions/setSelectedAction";
import Treeview from "../components/SideBar/SidebarMenu/Treeview";
import Subtree from "../components/SideBar/SidebarMenu/Subtree";
import {fetchUserProfileInfo} from "../actions/fetchUserProfileInfo";

class SideBarContainer extends Component {

    componentDidMount = () => this.props.fetchUserProfileData(this.props.kc.loadUserProfile);

    handleOnClick = (accion) => this.props.setAccionSeleccionada(accion);

    loadProfile = () => this.props.setSelectedAction({nombre: "Perfil", componenteVista: "Profile"});

    renderSidebarMenuTree = (modulos) => {
      if(modulos && modulos.length) return (
          <SidebarMenuTree>
              {modulos && modulos.length && modulos.sort((a,b) => a.orden - b.orden).map((modulo, i)=>
              {
                  return (
                      <Treeview modulo={modulo} key={modulo.id}>
                          {
                              modulo && modulo.acciones &&  modulo.acciones.map((accion, i)=>
                              {
                                  return(
                                      <Subtree accion={accion} key={accion.id} handleOnClick={this.handleOnClick}/>
                                  )
                              })
                          }
                      </Treeview>
                  );
              })
              }
          </SidebarMenuTree>
      );
      return null;
    };
    render() {
        const { urlImgUsuario, urlLogout, username} = this.props.userProfile;
        return (
                <SideBar>
                    <UserPanel
                        viewProfile={this.loadProfile}
                        urlImagenUsuario={urlImgUsuario}
                        urlLogout={urlLogout}
                        username={username}
                    />
                    {this.props.modulos && this.props.modulos.length && this.renderSidebarMenuTree(this.props.modulos)}
                </SideBar>
        );
    }
}


SideBarContainer.propTypes = {
    modulos: PropTypes.array,
    userProfile: PropTypes.object,
};

const mapStateToProps = (state) => (
    {
    modulos: getModulosDeSistema(state),
    kc: getKeycloak(state),
    userProfile: getUserProfile(state)
});


export default connect(mapStateToProps, {
    setAccionSeleccionada,
    fetchUserProfileData,
    setSelectedAction,
    fetchUserProfileInfo
})(SideBarContainer);