
export const FETCH_SISTEMAS = 'FETCH_SISTEMAS';
export const FETCH_ACCION_SCHEMA = 'FETCH_ACCION_SCHEMA';
export const FETCH_ACCION_DATA = 'FETCH_ACCION_DATA';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_SCHEMA = 'CLEAR_SCHEMA';
export const CLEAR_ACCION = 'CLEAR_ACCION';
export const SET_SISTEMA_SELECCIONADO = 'SET_SISTEMA_SELECCIONADO';
export const SET_ACCION_SELECCIONADA = 'SET_ACCION_SELECCIONADA';
export const SET_ACCION_VALOR = 'SET_ACCION_VALOR';
export const SET_ACCION_DATA = 'SET_ACCION_DATA';
export const FETCH_USER_PROFILE_DATA = 'FETCH_USER_PROFILE_DATA';
export const FETCH_USER_PROFILE_INFO = 'FETCH_USER_PROFILE_INFO';
export const SET_KEYCLOAK = 'SET_KEYCLOAK';
export const INSERT_ACCION_DATA = 'INSERT_ACCION_DATA';
export const HANDLESUBMIT = 'HANDLESUBMIT';
export const SET_URL_SUBMIT = 'SET_URL_SUBMIT';
export const UPDATE_ACCION_DATA= 'UPDATE_ACCION_DATA';
export const FETCH_MODULOS_POR_SISTEMA= 'FETCH_MODULOS_POR_SISTEMA';
export const MODAL_VISIBLE = 'MODAL_VISIBLE';
export const MODAL_BODY = 'MODAL_BODY';
export const SET_MODULO_SELECCIONADO = 'SET_MODULO_SELECCIONADO';
export const SET_SELECCIONES = 'SET_SELECCIONES';
export const BORRAR_SELECCIONES = 'BORRAR_SELECCIONES';
export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_HIDDEN = 'SET_HIDDEN';
export const SET_ENDPOINT = 'SET_ENDPOINT';
export const SET_FORM_RESPONSE = 'SET_FORM_RESPONSE';
export const SET_PREVIOUS_DATA = "SET_PREVIOUS_DATA";
export const SET_STATS = "SET_STATS";
export const CLEAR_STATS ="CLEAR_STATS";
export const SET_LOADING_BAR = "SET_LOADING_BAR";
export const SET_GRID_ACCION_DATA = "SET_GRID_ACCION_DATA";
export const SET_PREVIOUS_ACCION = "SET_PREVIOUS_ACCION";
export const CLEAR_PREVIOUS_ACCION = "CLEAR_PREVIOUS_ACCION";
export const CLEAR_PREVIOUS_DATA = "CLEAR_PREVIOUS_DATA";
export const CLEAR_PREVIOUS_FORM = "CLEAR_PREVIOUS_FORM";
export const SET_PREVIOUS_FORM = "SET_PREVIOUS_FORM";
export const SET_FORM = "SET_FORM";
export const LOAD_PREV = "LOAD_PREV";
export const LOAD_FORM_VALS = "LOAD_FORM_VALS";
export const CLEAR_AUX_DATA = "CLEAR_AUX_DATA";
export const SET_AUX_DATA = "SET_AUX_DATA";

export const GROUP = {
    SET_PREVIOUS_DATA,
    FETCH_SISTEMAS,
    FETCH_ACCION_SCHEMA,
    FETCH_ACCION_DATA,
    CLEAR_DATA,
    CLEAR_SCHEMA,
    CLEAR_ACCION,
    SET_SISTEMA_SELECCIONADO,
    SET_ACCION_SELECCIONADA,
    SET_ACCION_DATA,
    FETCH_USER_PROFILE_DATA,
    FETCH_USER_PROFILE_INFO,
    SET_KEYCLOAK,
    INSERT_ACCION_DATA,
    HANDLESUBMIT,
    SET_URL_SUBMIT,
    UPDATE_ACCION_DATA,
    FETCH_MODULOS_POR_SISTEMA,
    MODAL_VISIBLE,
    MODAL_BODY,
    SET_MODULO_SELECCIONADO,
    SET_SELECCIONES,
    BORRAR_SELECCIONES,
    SET_VISIBLE,
    SET_HIDDEN,
    SET_FORM_RESPONSE
}

