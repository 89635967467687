import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {init, store} from "./helpers/util";

init();

export function render(Component) {
    ReactDOM.render(
        <Provider store={store}>
                <Component />
        </Provider>,
        document.getElementById('root')
    );
    serviceWorker.register();
}