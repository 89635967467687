export const extServerListener = (response, mostrarModal) =>{
    const code = response.hasOwnProperty("codigo_error") || response.hasOwnProperty("codigo")
        ? response.codigo_error || response.codigo
        : null;
    code
        ? mostrarModal(`Mensaje  ` ,`${response.descripcion}`)
        : mostrarModal(`Ha ocurrido algo inesperado`, JSON.stringify(response.response || response, null, 2),"");
    return code !== 1001 && code !== 1002 && code !== 1003 && code !== 1005
        ? response
        : null;
};