import React, { Component } from 'react';
import RequiredLabel from "./RequiredLabel";
import {Frag, Icon, parentFinder, string2Json} from "../../../../../../helpers/util";
import getFormValues from "redux-form/es/getFormValues";
import { getHide, getOcultos, getSelectData } from "../../../../../../selectors/select";
import { connect } from "react-redux";
import { setVisible } from "../../../../../../actions/setVisible";
import { isNull } from 'util';
import {setHiddenNew} from "../../../../../../actions/setHidden";

class WrapperField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ocultar: "",
            display: ""
        }
    }


    pickClassname = (meta) => {
        const { touched, warning, error } = meta;;
    };

    showValues = () => {
        this.state.ocultar !== "" && this.setState({ ocultar: "" });
    };

    hideValues = () => {
        this.state.ocultar !== "hidden" && this.setState({ ocultar: "hidden" });
    };

    check = () => {
        const { ocultar } = this.state;
        const { formValues, show, children } = this.props;
        Object.values(show).map(questionId => {
            if (children.ref || children.props) {
                if (children.ref === questionId.input || children.props.name === questionId.input) {
                    if (show) {
                        let parent = show[questionId.input].hide.parent;
                        let value = show[questionId.input].hide.value;
                        if (parent.includes(".")) {
                            let subparent = parent.slice(0, parent.indexOf(".")).toString();
                            let parent2 = parent.substring(parent.indexOf(".") + 1).toString();
                            if (formValues.hasOwnProperty(subparent)) {

                                if (formValues[subparent] && formValues[subparent].hasOwnProperty(parent2)) {

                                    if (formValues[subparent][parent2] && formValues[subparent][parent2] === value) {
                                        this.showValues()
                                    } else if (formValues[subparent][parent2] && formValues[subparent][parent2] !== value) {
                                        this.hideValues()

                                    }
                                } else {
                                    this.hideValues()
                                }
                            } else {
                                this.hideValues()
                            }
                        } else {
                            if (formValues.hasOwnProperty(parent)) {
                                formValues[parent] === value
                                    ?  this.showValues()
                                    :  this.hideValues()
                            } else {
                                this.hideValues()
                            }
                        }
                    }
                }
            }
        });
    };

    childrenPropsFinder = childrenArr => Array.isArray(childrenArr) ? childrenArr[1].props : childrenArr.props;

    checkVisible = (props,state) => {
        console.log(`CHECK VISIBLE ${props.label}`);
        //console.log("se ocultara?", this.props)
    };

    checkHidden = ({toHide, questionId}) => this.shouldHide(toHide.find(a => a === questionId).length > 0);

    shouldHide = hide => hide ? this.hideValues() :  this.showValues();

    componentDidUpdate = () => {
        try{
            this.props.massive &&
            this.props.massive.visible &&
            this.checkHidden(this.props)
        }catch (e) {
            this.showValues();
        }
        return (this.state.ocultar === "") &&
        (this.props.label === "username" || this.props.label === "id_usuario") &&
        this.setState({ ocultar: "hidden" });
    };

    renderWrapper = ({ children, required, label, meta, questionId}) => {
        this.check(this.state, this.props);
        const objetoMuestra = {
            label,
            meta,
            required,
            children
        };
        const { touched, warning, error, pristine, visited, active, invalid, submitFailed, valid } = meta;
        var className = this.pickClassname(meta);
        let checker = (visited && error); // si fue visitado y tiene error OOOO es invalido
        let disableds;
        try{
            if ("props" in this.props.children) {
                if (!('disabled' in this.props.children.props)) {
                    disableds = false
                } else {
                    disableds = this.props.children.props.disabled;
                }
            }
        }catch (e) {
            console.log("fallo props in children", JSON.stringify(e, null, 3))
        }

        let afterSubmit = submitFailed && !disableds && invalid;
        let checkerBeta = checker && active && !valid;
        let visibleCuando = visited && invalid;
        meta.error = ( meta.error === undefined && this.props.required ) ?  "Este campo es requerido" : meta.error;
        return (
            <div className={`form-group ${className || ''} col-sm-12 col-xs-12 col-md-6 ${this.state.ocultar || ''}`}>
                {label && <Frag>
                    <RequiredLabel required={required} label={label}/>&nbsp;&nbsp;
                </Frag>}
                <div className="pull-right">
                    {touched && warning &&
                    <span>
                        <Icon clase="fas fa-bell"/>
                        {meta.warning || "un warning"}
                    </span>
                    }
                    {(checkerBeta || afterSubmit || visibleCuando)
                        ? <span style={{color:'#dd4b39'}} >
                            <Icon clase="fas fa-times-circle"/>
                            {(meta.error === "Invalid Field") ? "Valor inválido" : meta.error || ""}
                          </span>
                        :" "
                    }
                </div>
                {children}
            </div>
        );
    };

        componentDidMount() {
            if (this.props.mostrar) {
                this.props.setVisible({
                    [this.props.elementoOpcion.elementoFormulario.questionId]: {
                        input: this.props.elementoOpcion.elementoFormulario.questionId,
                        filterBy: string2Json(this.props.filterBy),
                        hide: string2Json(this.props.mostrar)
                    }
                });
            }

            window.addEventListener("resize", this.resize.bind(this));
        }

        resize() {
            let tamano = window.innerHeight;
            if (tamano < 700) {
                this.setState({
                    display: 'none'
                });
            } else {
                this.setState({
                    display: ''
                });
            }
        }

        isMobile = () => {
            return (
                (navigator.userAgent.match(/Android/i)) ||
                (navigator.userAgent.match(/webOS/i)) ||
                (navigator.userAgent.match(/iPhone/i)) ||
                (navigator.userAgent.match(/iPod/i)) ||
                (navigator.userAgent.match(/iPad/i)) ||
                (navigator.userAgent.match(/BlackBerry/i))
            )
        };

        renderBlank = () => {
            if (!this.isMobile()) {
                return (
                    <div className = "form-group col-md-6"
                    style = {{
                            paddingTop: "20px",
                            opacity: 0,
                            display: this.state.display
                        }}>
                    <label>
                    </label>
                        <div className = "form-control input-sm" >
                        </div>
                    </div>
                )
            } else {
                return <div> </div>;
            }
        };

        render() {
            return this.props.label === "blank" ? this.renderBlank() : this.renderWrapper(this.props);
        }

    }

    const mapStateToProps = (state) => ({
        formValues: getFormValues('form')(state),
        selecciones: getSelectData(state),
        show: getHide(state),
        ocultos: getOcultos(state),
        toHide: state.toHide
    });

    export default connect(mapStateToProps, { setVisible, setHiddenNew })(WrapperField);