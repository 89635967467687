import createAction from "redux-actions/es/createAction";
import {SET_SELECCIONES} from "../constants";
import { diff } from 'deep-object-diff';

const seleccion = createAction(SET_SELECCIONES);

export const setSeleccion = (data) => {
    return (dispatch, getState) =>{
        dispatch(seleccion(data));
    }
};
