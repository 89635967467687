import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from "../components/Header";
import {Frag} from "../helpers/util";
import {setSelectedSystem} from "../actions/setSelectedSystem";
import {clearAccionSelected} from "../actions/clearAccionSelected";
import {mostrarModal} from "../actions/mostrarModal";

class HeaderContainer extends Component {
    clearSistemaSeleccionado = () =>{
        this.props.clearAccionSelected();
        this.props.setSelectedSystem(null);
    }

    render() {
        return (
            <Frag>
                <Header clearSistemaSeleccionado={this.clearSistemaSeleccionado}
                        mostrarModal={this.props.handleModal}/>
            </Frag>
        );
    }
}

HeaderContainer.propTypes = {};

export default connect(null,{setSelectedSystem, clearAccionSelected, mostrarModal })(HeaderContainer);