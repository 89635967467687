import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import moment from "moment";
import _ from 'lodash';
var DatePicker = require('react-bootstrap-date-picker');



class DateField extends Component {
    constructor(props){
        super(props);
        this.state={
            value: "",
            valid: false,
            invalid: true,
            touched: false,
            active: false,
            visited: false,
            minDate: "",
            maxDate: ""
        }
    }

    handleChange = (value, formattedValue) => {
        if(value){
            this.setState({
                value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
                formattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
                valid: true,
                invalid: false
            });
            this.props.input.onChange(this.inputChecker(formattedValue));
        }
        else{
            this.setState({
                value:value,
                formattedValue: formattedValue,
                valid: false,
                invalid: true
            })
        }
    };
    handleFocus = () => {
        this.setState({
            visited: true,
            active: true
        })
    };
    handleBlur = () => {
        this.setState({
            touched: true,
            active: false
        })
    };

    componentDidMount() {
        this.setState({
            minDate: this.checkLimits(this.props.minDate),
            maxDate: this.checkLimits(this.props.maxDate)
        });
        if(this.props.input.value){
            if(this.props.input.value === "0000-00-00" || this.props.input.value === "2000-00-00"){
                this.props.input.onChange(null);
            }
            var recibido = this.props.input.value === "0000-00-00" || this.props.input.value === "2000-00-00" ?  null : this.props.input.value;
            if(this.props.input.value)
            this.setState({
                value : recibido,
                valid: true,
                invalid: false
            });
            this.props.meta.valid = true;
            this.props.meta.invalid = false;
        }
    }
    checkLimits = (date) => (date === "now") ? moment().format().slice(0, moment().format().indexOf("T")): date;

    addOne = (date) => moment(date).add(1,'d').format().slice(0, moment().format().indexOf("T"));

    resetDate = () => null;

    inputChecker = (input) => {
        const {minDate, maxDate} = this.state;
        let output = moment(input, "DD/MM/YYYY").format();
        if(minDate !== "" || maxDate !== ""){
            if (moment(input, "DD/MM/YYYY").isBefore(moment(minDate, "YYYY-MM-DD")) || !moment(input, "DD/MM/YYYY").isValid()){
                this.setState({
                    value: "",
                    formattedValue: "",
                    invalid: true,
                    valid: false
                });
                return null;
            }
            else if (moment(input, "DD/MM/YYYY").isAfter(moment(maxDate, "YYYY-MM-DD")) || !moment(input, "DD/MM/YYYY").isValid()) {
                this.setState({
                    value: "",
                    formattedValue: "",
                    invalid: true,
                    valid: false
                });
                return null;
            }
            else {
                return output.slice(0,output.indexOf("T"));
            }
        }
        else {
            return output.slice(0,output.indexOf("T"));
        }
    };


    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props.formValues && !nextProps.formValues){
            this.setState({
                value:null
            })
        }
    }

    render() {
        this.props.meta.valid = this.state.valid;
        this.props.meta.invalid = this.state.invalid;
        this.props.meta.touched = this.state.touched;
        this.props.meta.visited = this.state.visited;
        this.props.meta.active = this.state.active;
        const { maxDate, minDate } = this.state;
        const {label, type, placeholder, input, meta, required, disabled} = this.props;
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <div className="" >
                    <DatePicker
                        {...input}
                                id="example-datepicker"
                                value={this.state.value}
                                onChange={this.handleChange}
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                className="form-control input-md"
                                style={{ padding: "18px",
                                    borderRadius: "5px",
                                    zIndex:"0"}}
                                maxDate={this.addOne(maxDate)}
                                minDate={this.addOne(minDate)}
                                placeholder={placeholder}
                                dateFormat="DD/MM/YYYY"
                                dayLabels={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                                monthLabels={['Enero', 'Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']}
                                weekStartsOn={1}
                                showTodayButton={true}
                                todayButtonLabel={"Hoy"}
                                onClear={() => this.props.input.onChange("")}
                                disabled={disabled}/>
                </div>
            </WrapperField>
            )
    }
}
//
const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state)
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(DateField);