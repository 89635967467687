import React from 'react';
import PropTypes from 'prop-types';

const UserPanel = ({urlImagenUsuario,username, urlLogout, viewProfile}) => {
    return (
        <div className="user-panel" style={{position:"static"}}>
            <div className="pull-left image">
                <img src={urlImagenUsuario} onClick={() => viewProfile()} className='img-rounded' alt="Imagen Usuario"/>
            </div>
            <div className="pull-right info">
                <p>{username}</p>
                <a href={urlLogout}><i className="fa fa-power-off text-warning"></i> Cerrar Sesión</a>
            </div>
        </div>
    );
};

UserPanel.propTypes = {
    urlImagenUsuario: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    urlLogout: PropTypes.string.isRequired,
};

UserPanel.defaultProps = {
    urlImagenUsuario: "assets/img/avatar.png",
    urlLogout: "#",
    username: "Invitado"
};

export default UserPanel;

/*

<div class="user-panel">
        <div class="pull-left image">
          <img src="dist/img/user2-160x160.jpg" class="img-circle" alt="User Image">
        </div>
        <div class="pull-left info">
          <p>Alexander Pierce</p>
          <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
        </div>
      </div>
                <img id="imgUsr" src={urlImagenUsuario}  className='img-rounder' alt={"foto"} />

                <div className="user-panel col-md-12" >
            <div className="image col-md-3 col-xs-3 col-lg-3" style={{ paddingLeft:"0px", paddingRight:"0px",marginRight:"0px",marginLeft:"0px"}}>
                <img src={urlImagenUsuario} onClick={() => viewProfile()} className='img-rounded' alt="Imagen Usuario"/>
            </div>
            <div className="info col-md-9 col-lg-9 col-xs-9 pull-left" style={{position:"relative", paddingRight:"0px", paddingLeft:"0px",marginRight:"0px",marginLeft:"0px"}}>
                <p>{username}</p>
                <h5><i className="fas fa-sign-out-alt text-danger"></i><a href={urlLogout}>Cerrar Sesión</a></h5>
            </div>
        </div>;

 */