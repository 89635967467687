import {createSelector} from 'reselect';

export const getSistemas= state => state.estructura.sistemas && [...state.estructura.sistemas] || []; //Entrega un Array con los sistemas disponibles

export const getSistemaSeleccionado = state => state.estructura.sistemaSeleccionado || null; //Entrega el sistema que esta seleccionado

export const getModuloSeleccionado = state => state.estructura.moduloSeleccionado || null; //Entrega el modulo que esta seleccionado

const getIdSistemaSeleccionado = state => state.estructura.sistemaSeleccionado && state.estructura.sistemaSeleccionado.id || null;

export const getModulosDeSistema = createSelector( //Entrega un array con los modulos seleccionados
    (state) => getModulos(state) ,
    modulos => modulos
);

export const getAccion =  state => state.accionSeleccionada || null; // Entrega la accion que se realizara en el sistema

export const getAccionData = state => state.accionValor.data || []; // Entrega un array con los datos objetidos con un fetch a la accion

export const getGridAccionData = state => state.accionValor.gridData || []; // Entrega un array con los datos objetidos con un fetch a la accion

export const getAccionSchema = state => state.accionValor.schema || {}; // Entrega el esquema que tendra la accion

export const getAccionColumns = state => state.accionValor.schema.sections || []; // Entrega el array de columnas

export const getComponenteVista = state => state.accionSeleccionada.componenteVista || null;

const getModulos = (state) => state.estructura.modulos && getIdSistemaSeleccionado(state) && state.estructura.modulos.map((modulo) => {
    if(modulo.sistema.id===getIdSistemaSeleccionado(state)) return modulo;
});

export const getDataSistemaSeleccionado = (state) => state.estructura.sistemas && getIdSistemaSeleccionado(state) && state.estructura.sistemas.find(v => v.id ===getSistemaSeleccionado(state));

export const getBreadCrumb = (state) => {
    var bread = {};
    if(getSistemaSeleccionado(state)) bread = {...bread, sistema:getSistemaSeleccionado(state)};
    if(getModuloSeleccionado(state)) bread = {...bread, modulo:getModuloSeleccionado(state)};
    if(getAccion(state)) bread = {...bread, accion:getAccion(state)};
    return bread;
};

export const getLoadingState = state => state.loadingBar.default === 1;

export const getFormResponse = state => state.formResponse;
