import React from 'react';
import PropTypes from 'prop-types';
import {RequiredCustomIndicator} from "./RequiredCustomIndicator";
import {Frag} from "../../../../../../helpers/util";

const RequiredLabel = ({label, required, children}) => (
    <Frag>
        <label>
            <RequiredCustomIndicator required={required && label}/>
            {label}
            {children}
        </label>

    </Frag>
);

RequiredLabel.propTypes = {
    label: PropTypes.string|| PropTypes.func,
    required: PropTypes.bool,
};

export default RequiredLabel;


