import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from "../../../helpers/util";

const Subtree = ({accion, handleOnClick}) => {
    return (
        <li>
               <a href="#subtree" onClick={() => handleOnClick(accion)}>
                   <Icon clase={accion.icono || "fa fa-arrow-right" }/>&nbsp;
                {accion.nombre}
               </a>
        </li>
    );
};

Subtree.propTypes = {
    accion: PropTypes.object.isRequired,
    handleOnClick: PropTypes.func.isRequired
};

export default Subtree;

/*
className="active"
 */