import React, {Component} from 'react';
import { statAlert } from "./StatAlert";
import {fetchAccionData} from "../../../../actions/fetchAccionData";
import {setAccionDinamica, setAccionSeleccionada} from "../../../../actions/setAccionSeleccionada";
import connect from "react-redux/es/connect/connect";
import {getFormSyncErrors, getFormValues} from "redux-form/es/index";
import {getAccion, getAccionData, getAccionSchema} from "../../../../selectors/estructura";
import {getModal} from "../../../../selectors/modal";
import {getPreviousData} from "../../../../selectors/states";
import {setAccionData} from "../../../../actions/setAccionData";
import {mostrarModal, showModal} from "../../../../actions/mostrarModal";
import Card from "../index";
import axios from 'axios'
//import {apiPost} from "../../../../api";

class Stats extends Component {

    redirect = ({redirect, dataToSend, dataSource}) => this.props.fetchAccionData(redirect).then( v =>  {
        console.log(dataToSend)
        if(dataToSend && dataSource) return axios.post(dataSource,dataToSend)
            .then(res => this.props.setAccionSeleccionada(v.value, res.data))
            .catch(c => {
                console.log("error", c);
                return this.props.setAccionSeleccionada(v.value)
            });
        return this.props.setAccionSeleccionada(v.value)
    });

    renderTable = (alerts) => {
        console.log("alerts", !alerts.some(element => typeof element !== "object"));
        return !alerts.some(element => typeof element !== "object")
            ? Object.values(this.checkTitles(alerts))
            .map( (proceso, index) =>
                <div className={`col-md-${Object.values(this.checkTitles(alerts)).length >= 4 ? 4 : 12/Object.values(this.checkTitles(alerts)).length}`}>
                    <div className="box no-border" style={{borderRadius:"7px",   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
                        <div className="box-header with-border" style={{paddingTop:"15px"}}>
                            <h4>{Object.keys(this.checkTitles(alerts))[index]}</h4>
                            <div className="box-tools pull-right" style={{paddingTop:"10px"}}>
                                <button type="button" className="btn btn-box-tool"
                                        data-toggle="collapse" data-target={`#${Object.keys(this.checkTitles(alerts))[index].replace(/\s/g,'')}`}>
                                    <i className="fa fa-minus icon"> </i>
                                </button>
                            </div>
                        </div>
                        <div className="box-body collapse in" id={`${Object.keys(this.checkTitles(alerts))[index].replace(/\s/g, '')}`}>
                            {this.columnFill(proceso)}
                        </div>
                    </div>
                </div>
            )
            : null;
    };

    columnFill = (column) => column.map(alert => {
            const {titulo, data, descripcion, icono, tipo, orden, redirect} = alert;
            return  <div onClick={() => this.redirect(alert)} style={{cursor:"pointer"}}>
                        {statAlert(tipo,titulo,data,descripcion)}
                    </div>
        });

    getProcess = data => data.process || data.proceso;

    checkTitles = fetchedData => fetchedData.reduce( (anterior, actual) => {
            anterior[this.getProcess(actual)] = anterior.hasOwnProperty(this.getProcess(actual))
                ? [...anterior[this.getProcess(actual)], actual]
                : [actual];
            anterior[this.getProcess(actual)].sort((a,b) => b.tipo - a.tipo);
            return anterior;
        },{});

    render() {
        const {data, statsData} = this.props;
        return (
            <Card titulo={`Alertas y Estadisticas`}>
                <div className='box-body'>
                    {this.renderTable( Array.isArray(statsData || data)
                            ? statsData || data
                            : Object.values(statsData || data))}
                </div>
            </Card>
        );
    }
}


const statDataFeed = (dataSource, dataToSend) => {
    console.group("statDataFeed");
    console.log("dataSource:", dataSource);
    console.log("dataToSend",dataToSend);
    console.groupEnd();
}


const mapStateToProps = (state) => ({
    syncErrors: getFormSyncErrors('form')(state),
    formValues: { ...getFormValues('form')(state),...state.userInfo},
    data: getAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    modal: getModal(state),
    previousData: getPreviousData(state)
});
export default connect(mapStateToProps,{fetchAccionData, setAccionData, setAccionSeleccionada, setAccionDinamica, mostrarModal, showModal})(Stats);

//export default connect({},fetchAccionData, setAccionSeleccionada)();
