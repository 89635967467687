import { createAction} from 'redux-actions';
import {SET_HIDDEN} from "../constants";
import {unregisterField} from "redux-form";
import {unirUrl} from "../helpers/util";
import {urlModulos} from "../config";
import {clearAccionSelected} from "./clearAccionSelected";
import {setSelectedSystem} from "./setSelectedSystem";
import {apiGet} from "../api";
import {clearStats} from "./clearStats";
import {setStats} from "./setStats";
import {fetchModulosPorSistema} from "./setSistemaSeleccionado";

export const setHidden = createAction(SET_HIDDEN);

export const setHidden2 = createAction("SET_HIDDEN_NEW");

export const clearHidden = createAction("CLEAR_HIDDEN_NEW");

export const setHiddenNew = fieldToHide => {
    return (dispatch, getState) => {
        const store = getState();
        try {
            !store.toHide.find( a => a === fieldToHide).length > 0 && dispatch(setHidden2(fieldToHide));
        }catch (e) {
            dispatch(setHidden2(fieldToHide));
        }
    }
};


export const clearHiddenNew = fieldToHide => {
    return (dispatch, getState) => {
        const store = getState();
        try {
            store.toHide.find( a => a === fieldToHide).length > 0 && dispatch(clearHidden(fieldToHide));
        }catch (e) {

        }
    }
};