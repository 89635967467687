import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from "../../../helpers/util/ErrorBoundary";

const Card = ({titulo, loading, clase, children})=> {
    return (
            <div className={`box ${clase}` } style={{borderRadius: "7px",boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
                    <div className="box-body">
                        <ErrorBoundary>
                        {children}
                        </ErrorBoundary>
                    </div>
                {
                    loading && <div className="overlay">
                        <i className="fas fa-circle-notch fa-spin  center-block"></i>
                    </div>
                }
            </div>
    );
};

Card.propTypes = {
    header: PropTypes.string,
    largo: PropTypes.number,
    clase: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.node.isRequired,

};

Card.defaultProps = {
    titulo: "Card",
    clase: "box-primary",
    loading: false
};

export default Card;