import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import {Icon, string2Json} from "../../../../../../helpers/util";
import {apiGet} from "../../../../../../api";
import isPlainObject from "redux-actions/es/utils/isPlainObject";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
const { detect } = require('detect-browser');

class TextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options || null,
            disabled: false
        }
    }

    TextFieldIcon = ({label, type, placeholder, input, meta, required, icon, disabled}) => (
        <WrapperField label={label} required={required} meta={meta}>
            <div className="input-group">
            <span className="input-group-addon">
                <Icon clase={icon}/>
            </span>
                <input
                    {...input}
                    type={type}
                    disabled={disabled}
                    className="form-control input-sm"
                    placeholder={placeholder}
                    disabled={this.state.disabled}
                    autoComplete="off"
                />
            </div>
        </WrapperField>
    );

    formOptions = (arreglo, label, value) => {
        if (arreglo) {
            return arreglo.map(v => {
                const parent = {...Object.values(v).filter(f => isPlainObject(f))[0]};
                return {label: v[label], value: v[value], parent};
            })
        }
    };

    formateaRut = (rut) => {
        if(rut.length <= 12){
            let dv = rut.substring(rut.length - 1);
            let c = rut.substring(0, rut.length - 1);
            return dv.length === 0 && c.length === 0
                ? ""
                : `${c}-${dv === 'k' || dv === 'K' ? 'K' : dv}`;
        }
        else {
            return `${rut.substring(0,11)}-${rut.substring(11,12) === 'k' || rut.substring(11,12) === 'K' ? 'K' : rut.substring(11,12) }`;
        }
    };

    renderCheck = (val) =>{
        this.setState({valido : val});
        this.forceUpdate();
    };

    checkLength = (event) => {
        let textLength = event.target.value.toString().length;
        if(textLength > this.props.conditionals.maxLength) console.log("");
        event.target.value = event.target.value.slice(0,this.props.conditionals.maxLength);
        return event;
    };

    handleOnChange = (event) => {
        let nombreInput = this.props.input.name.toLowerCase().substring(this.props.input.name.indexOf("."));
        let rut = ["rut", "run"];
        if(rut.some(el => nombreInput.includes(el))){
            try{
                var run = event.target.value.length === 1 && event.target.value.includes("-")
                    ? event.target.value = ""
                    : this.formateaRut(event.target.value.replace(/[A-Ja-jL-Zl-z]|[,;.ñÑ¸:_{*+¨^~`}¿¡'?=̣̣·)(/&%$#"!°¬|@·~½]|\s|\-|\[|\]|\\/g, ""));
            }catch (e) {}
            event.target.value = run !== null || run !== undefined
                ? run
                : "";
        }
        event = this.checkLength(event);
        return this.props.input.onChange(event);
    };

    handleOnBlur = () => {
        if(this.props.massive.filterBy){
            if(this.props.massive.filterBy.indexOf(".") >= 0){
                return Object.values(this.props.formValues).map(fv => {
                    if (isPlainObject(fv)) {
                        const string = fv[this.props.filterBy.toString().substring(this.props.filterBy.indexOf('.'))];
                        var a = this.props.filterBy.substring(this.props.filterBy.indexOf('.')+1);
                        const url = `${this.props.elementoOpcion.endpoint}/${fv[a] || ""}`;
                        apiGet(url)()
                            .then(v => this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value))
                            .then(v => {
                                return this.setState({options: v})
                            });
                    }
                })
            }
            else {
                apiGet(`${this.props.elementoOpcion.endpoint}/${this.props.formValues[this.props.filterBy] || ""}`)()
                    .then(v => this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value))
                    .then(v => {
                        const encontrado = v.filter( s => s.label.toLowerCase() === this.props.input.value.toLowerCase());
                        if(encontrado.length > 0) {
                        }
                        else {
                        }
                        return this.setState({options: v})
                    });
            }
        }
    };

    SimpleTextField = ({label, type, placeholder, input, meta, required, disabled}) => {
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <input
                    {...input}
                    type={type}
                    disabled={disabled}
                    className="form-control async-validating"
                    placeholder={placeholder}
                    onBlur={this.handleOnBlur}
                    onChange={this.handleOnChange}
                    style={this.checkStyles(type, detect().name)}
                    disabled={this.state.disabled}
                    autoComplete="off"
                />
            </WrapperField>
        );
    };

    checkStyles = (type, browser) =>  (type === "date" && browser === "firefox")
                                        ? { paddingTop: "0px",
                                            paddingBottom: "36px",
                                            borderRadius: "5px" }
                                        : { padding: "18px",
                                            borderRadius: "5px" };

    runDisabler = () => {
        let nombreInput = this.props.input.name.toLowerCase().substring(this.props.input.name.indexOf("."));
        let rut = ["rut", "run"];
        if(rut.some(el => nombreInput.includes(el))) {
            if(this.props.input.value !== "" && this.props.input.value !== null){
                //  this.setState({disabled:true})
            }
        }
    }

    componentDidMount() {
        (this.props.visible) && this.props.setVisible({
            [this.props.massive.questionId]: {
                input: this.props.massive.questionId,
                filterBy: string2Json(this.props.filterBy),
                hide: string2Json(this.props.visible)
            }
        });
        this.props.massive.disabled
            ? this.setState({disabled:true})
            : this.runDisabler();

        if(this.props.conditionals.maxLength && this.props.conditionals.maxLength !== 60){
        }
        else{
            this.props.conditionals.maxLength = 60;
        }

    }

    render() {
        return this.props.icon ? this.TextFieldIcon({...this.props}) : this.SimpleTextField({...this.props});
    }
}

TextField.defaultProps = {
    conditionals:{
        maxLength: 60
    }
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state)
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(TextField);


