import React, {Component} from 'react';
import AsyncSelect from 'react-select/lib/Async';
import WrapperField from "./WrapperField";
import {apiGet} from "../../../../../../api/apiSecure";
import getFormValues from "redux-form/es/getFormValues";
import {change} from "redux-form/es/index";
import connect from "react-redux/es/connect/connect";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import isPlainObject from "redux-actions/es/utils/isPlainObject";
import {string2Json} from "../../../../../../helpers/util";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import _ from "lodash";

class SelectAsyncField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            options: props.options || [],
            formValues: props.formValues,
            hidden: "",
            loaded: false,
            fetched: false,
            visited: false,
            touched: false,
            active: false,
        }
    }

    formOptions = (arreglo, label, value) => {
        if (arreglo && Array.isArray(arreglo)) {
            return arreglo.map(v => {
                const parent = {...Object.values(v).filter(f => isPlainObject(f))[0]};
                return {label: v[label], value: v[value], parent}
            })
        }
    };

    promiseOptions = (inputValue) => {
        const {filterBy, elementoOpcion: {label, value, endpoint}, formValues} = this.props;
        Object.values(this.props.selecciones).map(s => {
            if(s.parent !== undefined){
                if (s.parent.id !== formValues[s.filterBy] && s.parent.id !== 0) {
                    this.setState(prevState => ({
                        ...prevState,
                        value: ""
                    }));
                }
            }
        });
        if (!filterBy){
            return apiGet(`${endpoint}`)()
                .then(v => {
                    const data = this.formOptions(v, label, value);
                    return data;
                })
        }
    };

    findlParentValue= (filterBy) => {
        let {formValues} = this.props;
        formValues = {...formValues,keycloakUserInfo:this.props.userInfo};
        try {
            if(filterBy.includes(".")){
                const sectionName = filterBy.substring(0, filterBy.indexOf("."));
                const parentName = filterBy.substring(filterBy.indexOf(".")+1);
                return formValues[sectionName][parentName];
            }else {
                return formValues[filterBy];
            }
        }
        catch (e) {
            return 0
        }
    };

    fetchInitialValue = async (initialValue) => {
        const {filterBy, elementoOpcion: {label, value, endpoint}} = this.props;
        var parentId = "";
        if(filterBy) parentId = this.findlParentValue(filterBy);
        let array = await  apiGet(`${endpoint}/${parentId}`)().then(v => {
            const data = this.formOptions(v, label, value);
            return data;
            });
        return array;
    };

    saveSetSeleccion = (value) => {
        this.props.setSeleccion({
            [this.props.elementoOpcion.elementoFormulario.questionId]: {
                ...value,
                input: this.props.elementoOpcion.elementoFormulario.questionId,
                filterBy: this.props.filterBy
            }
        });
        this.setState(prevState => ({
            ...prevState,
            value: value
        }));
    };

    handleInputChange = (value) => {
        value && this.props.setSeleccion({
            [this.props.elementoOpcion.elementoFormulario.questionId]: {
                ...value,
                input: this.props.elementoOpcion.elementoFormulario.questionId,
                filterBy: this.props.filterBy
            }
        });
        this.setState(prevState => ({
            ...prevState,
            value: value
        }));
        return !this.props.isMulti ? this.props.input.onChange( value !== null  ? value.value : value ) : this.props.input.onChange(value.map(v => v.value) || value );
    };

    handleOnFocus = () => {
        this.setState({visited: true, active:true});
        if (this.props.filterBy) {
            (this.props.filterBy.indexOf(".") >= 0)
                ? Object.values(this.props.formValues).map(fv => {
                    if (isPlainObject(fv)) {
                        var a = this.props.filterBy.substring(this.props.filterBy.indexOf('.') + 1);
                        const url = `${this.props.elementoOpcion.endpoint}/${fv[a] || ""}`;
                        if(fv[a] !== undefined && fv[a] !== 0){
                            apiGet(url)()
                                .then(v => {
                                    return this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value)
                                })
                                .then(v => {
                                    return this.setState({options: v, fetched:true})
                                });
                        }
                    }
                })
                : apiGet(`${this.props.elementoOpcion.endpoint}/${this.props.formValues[this.props.filterBy] || ""}`)()
                        .then(v => {
                            return this.formOptions(v, this.props.elementoOpcion.label, this.props.elementoOpcion.value)
                        })
                        .then(v => {
                            return this.setState({options: v, fetched: true})
                        });
            }
    };

    visible = () => {
        if (this.state.hidden === "") {
            if (this.props.mostrar) {
                const {parent, value} = string2Json(this.props.mostrar);
                const filtradoEsigual = {...Object.values(this.props.selecciones).filter(sel => (sel.input === parent && value === sel.value))[0]};
                const filtradoEsdiferente = {...Object.values(this.props.selecciones).filter(sel => (sel.input === parent && value !== sel.value))[0]};
                if (filtradoEsdiferente.value || filtradoEsigual.value) {
                    if (filtradoEsigual.value !== value) {
                        this.setState({hidden: "hidden"});
                        this.props.setHidden({
                            [this.props.elementoOpcion.elementoFormulario.questionId]: {
                                label: this.props.elementoOpcion.elementoFormulario.questionId,
                                visible: false
                            }
                        });
                    }
                }
            }
        }
        if (this.state.hidden === "hidden") {
            if (this.props.mostrar) {
                const {parent, value} = string2Json(this.props.mostrar);
                const filtradoEsigual = {...Object.values(this.props.selecciones).filter(sel => (sel.input === parent && value === sel.value))[0]};
                const filtradoEsdiferente = {...Object.values(this.props.selecciones).filter(sel => (sel.input === parent && value !== sel.value))[0]};
                if (filtradoEsdiferente.value || filtradoEsigual.value) {
                    if (filtradoEsigual.value === value) {
                        this.setState({hidden: ""});
                        this.props.setHidden({
                            [this.props.elementoOpcion.elementoFormulario.questionId]: {
                                label: this.props.elementoOpcion.elementoFormulario.questionId,
                                visible: true
                            }
                        });
                    }
                }
            }
        }
    };

    componentDidMount() {
        const {elementoOpcion: {endpoint}, input: {value}, filterBy,massive} = this.props;
        const {loaded} = this.state;

        if(massive && massive.defaultValue && !this.props.formValues.provincia){
          this.fetchInitialValue()
              .then(array => {
                  let defaultValue = this.findlParentValue(massive.defaultValue)
                  let fil = array.filter(m => m.value === parseInt(defaultValue) || m.value === defaultValue)
                  console.log(fil);
                  this.setState({value: fil,loaded: true})
                  this.saveSetSeleccion(fil[0])
                  return this.props.input.onChange(defaultValue);
              })
          return null
        }


        (this.props.mostrar) && this.props.setVisible({
                                    [this.props.elementoOpcion.elementoFormulario.questionId]: {
                                        input: this.props.elementoOpcion.elementoFormulario.questionId,
                                        filterBy: string2Json(this.props.filterBy),
                                        hide: string2Json(this.props.mostrar)
                                    }
                                });

       if(this.state.value !== "" && !loaded && value !== "" && value !== 0){
           let parentVal = (filterBy) ? this.findlParentValue(filterBy) : null;
           if(parentVal !== 0){
               this.fetchInitialValue(endpoint, value)
                   .then(array => {
                       let fil = array.filter(m => m.value === parseInt(value) || m.value === value)
                       return fil
                   })
                   .then(filtered => {
                       if (filtered !== []){
                           this.setState({value: filtered,loaded: true})
                           this.saveSetSeleccion(filtered[0])
                       }
                   })
           }
           if(!filterBy){
               this.fetchInitialValue(endpoint, value)
                   .then(array => array.filter(m => m.value === parseInt(value) || m.value === value))
                   .then(filtered => (filtered !== []) && this.setState({value: filtered,loaded: true}))
           }
       }
    }

    componentWillReceiveProps = (nextProps) => {
        return (nextProps.input.value === '' && this.state.value !== null) && this.setState({value: null, options:[]});
    };

    deleteExternalValue = ({input}) => {
        const { change } = this.props;
        change('form', input, '');
    };

    setChildren = (sel) => {
        this.deleteExternalValue(sel[0]);
        if(!this.state.hasOwnProperty('children')){
            this.setState({children: sel[0]});
        }
    };

    checkChildrens = ({selecciones, elementoOpcion:{elementoFormulario:{questionId}}}) => {
        if( selecciones &&
            !_.isEmpty(selecciones) &&
            selecciones[questionId]){
            let sel = Object.values(selecciones).filter(seleccion => seleccion.filterBy === questionId);
            sel.length > 0 && this.setChildren({...sel});
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const {input: {value}} = this.props;
        (prevProps.input.value !== '' && prevProps.input.value !== value) && this.checkChildrens(this.props);
        (prevProps.input.value !== '' && prevProps.input.value !== value &&  !_.isEmpty(this.state.children)) && console.log();
        if (this.props.meta.submitFailed && this.state.value === null && this.state.value === "" && !this.state.valid) this.setState({valid: false, invalid: true, touched:true});
        this.visible();
    }

    handleCheckOnBlur = () => {
        this.setState({touched:true, active: false})
    };

    render() {
        this.props.meta.touched = this.state.touched;
        this.props.meta.active = this.state.active;
        this.props.meta.visited = this.state.visited;
        const {filterBy, label, required, meta, disabled, isMulti, placeholder, input } = this.props;
        let extraProps = {defaultOptions: true};
        if(filterBy) extraProps= {...extraProps, defaultOptions: this.state.options };
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <AsyncSelect
                    ref={input.name}
                    {...extraProps}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    value = {this.state.value}
                    selectedOption = {this.state.value}
                    onChange={this.handleInputChange}
                    loadOptions={this.promiseOptions}
                    placeholder={placeholder || "Seleccione..."}
                    noOptionsMessage={()=> "No existen datos"}
                    isClearable
                    options={this.state.options}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleCheckOnBlur}
                    isSearchable={false}
                    style={{backgroundColor:"black"}}
                />
            </WrapperField>
        );
    }
}
SelectAsyncField.propTypes = {};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state),
    userInfo: state.userInfo
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden, change })(SelectAsyncField);
