import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WrapperField from "./WrapperField";
import { domainIpRemoval, fileOpener, Icon } from "../../../../../../helpers/util";
import { Document } from "react-pdf";
import { apiGet, apiShow } from "../../../../../../api";
import { decode as atob, encode as btoa } from 'base-64'
import Iframe from 'react-iframe';
import connect from "react-redux/es/connect/connect";
import { mostrarModal, showModal } from "../../../../../../actions/mostrarModal";
import ModalTrigger from "../../../../../ModalMaster/modalTrigger";
import { isFieldEmpty } from '@isobar-us/redux-form-gen';


class FileField extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.document = React.createRef();
        this.onFileChange = this.onFileChange.bind(this);
        this.state = {
            file: null,
            placeholder: "Archivo:",
            fileName: "",
            filePath: "",
            loaded: false,
            url: "",
            deleteArchivo: null,
            touched: false,
            visited: false,
            active: false,
            valid: false,
            invalid: true
        }
    }

    rejectUpload = (nombre, limite, arg, format) => {
        switch (arg) {
            case "size":
                this.deleteInput(true);
                this.props.mostrarModal("Mensaje", "El tamaño del archivo, '" + nombre + "' excede el tamaño máximo permitido (" + ((limite / 1024) / 1024).toPrecision(2) + " MB).", "");
                break;
            case "format":
                this.props.mostrarModal("Mensaje", "El formato del archivo, '" + nombre + "', no coincide con el formato aceptado en este campo (" + format + ").", "");
                this.deleteInput(true);
                break;
            default:
                this.props.mostrarModal("El archivo seleccionado, no coincide con los parámetros definidos", "", "");
                this.deleteInput(true);
                break;
        }

    };

    onFileChange(e) {
        this.setState({ active: true });
        const { limit, accept, holdId, value, input} = this.props;
        let allowedExt = accept.substring(accept.indexOf(".") + 1);
        if (e.target.files.length) {
            let selectedFile = e.target.files;
            let file = null;
            let fileName = "";
            if (selectedFile.length > 0) {
                let fileToLoad = selectedFile[0];
                fileName = fileToLoad.name;
                const tamaño = fileToLoad.size > this.props.limit;
                const formato = fileName.substring(fileName.lastIndexOf('.') + 1) !== allowedExt;
                if(formato || tamaño){
                    (tamaño)
                        ? this.rejectUpload(fileName, limit,"size", allowedExt)
                        : this.rejectUpload(fileName, limit, "format",allowedExt);
                }
                else{
                    let fileReader = new FileReader();
                    fileReader.onload = (fileLoadedEvent) => {
                        file = fileLoadedEvent.target.result;
                        var decode = file.substring(file.indexOf(',') + 1);
                        this.setState({ file: file });
                        file = { base: decode, fileName: this.state.fileName, label: this.props.input.name };
                        console.log("file agregando: ",file);
                        console.log("file value: ",input);

                        file = holdId && !!input.value && input.value.hasOwnProperty("id")
                            ?  {...file, id: input.value.id }
                            : file;
                        this.props.input.onChange(file);
                    };
                    fileReader.readAsDataURL(fileToLoad);
                    this.setState({
                        fileName: fileName,
                        valid: true,
                        invalid: false
                    });

                }
            }
        }
        this.setState({ active: false, touched: true, visited: true })
    }

    componentDidMount() {
        const { input: {value, name} } = this.props;
        if (value && this.state.loaded === false && value.pathArchivo && value.deleteArchivo) {
            this.getBase(value.pathArchivo).then(c => {
                if (c.base !== "" && c.base !== null && c.base !== undefined) {
                    let file = {
                        base: c.base,
                        fileName:  (value.fileName)?
                            value.fileName:
                            name.substring(name.indexOf(".")+1),
                        label: name
                    };
                    file = value && value.id && !c.id ? {...file, id: value.id} : file;
                    const leUrl = value.deleteArchivo.includes("http")
                        ? domainIpRemoval(value.deleteArchivo)
                        : value.deleteArchivo;

                    this.setState({
                        externalFile: file,
                        loaded: true,
                        file: c.base,
                        deleteArchivo: leUrl,
                        fileName: (value.fileName) ? value.fileName : name,
                        valid: true,
                        invalid: false,
                        visited: true,
                        touched: true,
                        active: false
                    });
                    this.props.input.onChange(file);
                }
            });
        } else if (value && !this.state.loaded && value.base && !value.pathArchivo && !value.deleteArchivo) {

            let file = {
                base: value.base || null,
                fileName:  (value.fileName)?
                    value.fileName:
                    name.substring(name.indexOf(".")+1),
                label: name
            };
            this.setState({
                loaded: true,
                file: value.base || null,
                deleteArchivo: null,
                fileName: (value.fileName) ? value.fileName : name,
                valid: true,
                invalid: false,
                visited: true,
                touched: true,
                active: false
            });
            this.props.input.onChange(file);
        } else {
            this.setState({ fileName: this.props.label })
        }
    }

    getBase = async(url) => apiGet(url.includes("http://")
            ? domainIpRemoval(url)
            : url)().then(c => c);


    makeUrl = (base64Data) => {
        var byteCharacters = atob(base64Data);
        var contentType = "application/pdf";
        var sliceSize = 512;
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, { type: contentType });
        const url = URL.createObjectURL(blob);
    };

    openFile = () => {
        const fileSetter = {
            fileName: this.state.fileName ? this.state.fileName : this.props.label,
            base: this.state.file
        };
        return fileOpener(undefined, fileSetter);
    };

    clickInput = () => {
        this.fileInput.current.click();
    };

    asyncModal = async(bool) => {
        if (bool) {
            this.setState({
                fileName: this.props.label,
                file: null,
                filePath: "",
                url: "",
                touched: false,
                active: false,
                visited: true,
                valid: false,
                invalid: true
            });
            this.fileInput.current.value = "";
            this.props.input.onChange({});
            if (this.state.deleteArchivo) {
                return apiGet(this.state.deleteArchivo)().then(res => {
                    return res;
                }) || { "descripcion": "eliminado" };
            }
        } else {
            return { "descripcion": "no eliminado" } || "";
        }
    };

    deleteInput = (flag) =>{
        let response = null;
        if(typeof flag === "object"){
            this.props.mostrarModal("ADVERTENCIA: "
                    , "La eliminación de este archivo es definitiva ¿Desea eliminar el archivo de todas formas?", (
                <div className="btn-group-md">
                    <button className="btn btn-danger"
                            onClick={() => this.asyncModal(false).then(resp => this.props.showModal(false))}>
                        <strong>
                            CANCELAR
                        </strong>
                    </button>
                    <button className="btn btn-primary" onClick={
                        () => this.asyncModal(true).then(resp => this.props.showModal(false))}>
                        <strong>
                            ELIMINAR
                        </strong>
                    </button>
                </div>));
        }
        else {
            this.setState({
                fileName: this.props.label,
                file: null,
                filePath: "",
                url: "",
                touched: false,
                active: false,
                visited: true,
                valid: false,
                invalid: true
            });
            this.fileInput.current.value = "";
            this.props.input.onChange({});
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filePath } = this.state;
        const { value } = this.fileInput.current;
        if (value && value !== filePath) {
            this.setState({ filePath: value });
        }
    }

    render() {
        const {label, required, meta, accept, type, maxLength, input, disabled} = this.props;
        //console.log("VALUE? ", this.props.input.value);
        //!this.props.input.value && isFieldEmpty(input.value);
        this.props.meta.active = this.state.active;
        this.props.meta.touched = this.state.touched;
        this.props.meta.visited = this.state.visited;
        this.props.meta.valid = this.state.valid;
        this.props.meta.invalid = this.state.invalid;

        var showLoad = true;

        console.log(this.state);
        if(this.state.fileName.length > 0){
            showLoad = false;
            if(this.state.loaded === false || this.state.file === null){
                showLoad = false;
            }
        }
        
        if(showLoad === true){
            return(
            <WrapperField label={label} required={required} meta={meta}>
                <div className="form-group" style={{paddingTop: "2px"}}>
                    <input
                           type={type}
                           accept={accept}
                           onChange={this.onFileChange}
                           maxLength={maxLength}
                           className="col-md-10"
                           ref={this.fileInput}
                           style={{display:"none", opacity: 1}}
                    />
                    <div className="col-md-12">
                        <span className="btn btn-primary col-md-8 col-sm-8 col-xs-12"
                              onClick={(!disabled) ? this.clickInput : null}>
                            <strong>Seleccionar un archivo: &nbsp;</strong>
                            <i>{this.state.fileName}</i>
                        </span>

                        <div key={"spinner"} className="overlay" style={{position: "absolute",
                                                    width: '100%',
                                                    height: '100%',
                                                    display: "grid",
                                                    alignItems: "center",
                                                    gridTemplateColumns: "1.5fr .5fr"
                                                    }}>
                            <i className="fas fa-circle-notch fa-spin  center-block"></i>
                        </div>                       

                        <div className="col-md-3 col-sm-4 col-xs-12" style={{paddingTop: "3px"}}>
                                <span className="input-group-addon btn btn-info"
                                      disabled={!this.state.file}
                                      onClick={this.openFile}
                                      style={{marginTop:4}}
                                >
                                <Icon clase="fas fa-eye"/>
                            </span>
                                <span className="input-group-addon btn btn-adn"
                                      disabled={!this.state.file}
                                      style={{marginTop:4}}
                                      onClick={(this.state.valid && !disabled) ? this.deleteInput : null}
                                >
                                <Icon clase="fas fa-times" style={{color:"white"}}/>
                            </span>
                        </div>

                    </div>
                </div>
            </WrapperField>
            );
        }

        if(showLoad === false){
            return (
                <WrapperField label={label} required={required} meta={meta}>
                <div className="form-group" style={{paddingTop: "2px"}}>
                    <input
                           type={type}
                           accept={accept}
                           onChange={this.onFileChange}
                           maxLength={maxLength}
                           className="col-md-10"
                           ref={this.fileInput}
                           style={{display:"none", opacity: 1}}
                    />
                    <div className="col-md-12">
                        <span className="btn btn-primary col-md-8 col-sm-8 col-xs-12"
                              onClick={(!disabled) ? this.clickInput : null}>
                            <strong>Seleccionar un archivo: &nbsp;</strong>
                            <i>{this.state.fileName}</i>
                        </span>

                        <div className="col-md-3 col-sm-4 col-xs-12" style={{paddingTop: "3px"}}>
                                <span className="input-group-addon btn btn-info"
                                      disabled={!this.state.file}
                                      onClick={this.openFile}
                                      style={{marginTop:4}}
                                >
                                <Icon clase="fas fa-eye"/>
                            </span>
                                <span className="input-group-addon btn btn-adn"
                                      disabled={!this.state.file}
                                      style={{marginTop:4}}
                                      onClick={(this.state.valid && !disabled) ? this.deleteInput : null}
                                >
                                <Icon clase="fas fa-times" style={{color:"white"}}/>
                            </span>
                        </div>

                    </div>
                </div>
            </WrapperField>
            );
        }

    }
}

FileField.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { mostrarModal, showModal })(FileField);
