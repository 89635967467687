import {Icon, marginSetter, pullClassName} from "../../../../../../../helpers/util";
import React from "react";

export const submitButton = (props, state) =>   {
    let {icon, label, className, tipo, formValues, pristine, submitting, mostrarModal, syncErrors} = props;
    return (
        <button className={`${className} ${pullClassName(label)} ${state.fullWidth}`}
                type={tipo}
                style={{marginLeft:`${marginSetter(label)}`}}
                disabled={ props.formValues !== {}
                    ? false
                    : submitting || pristine }
                onClick={() => {
                    formValues.submitted = true;
                    try{
                        let fvalues = formValues;
                        fvalues = userInfoSetter(props);
                        (Object.values(syncErrors).length !== 0)
                            ? mostrarModal("Mensaje","Falta completar campos obligatorios","")
                            : console.log();
                    }
                    catch (e) {}
                }
                }>
            {label} &nbsp;&nbsp;&nbsp;&nbsp;
            <Icon clase={icon}></Icon>
        </button>
    );
};

export const userInfoSetter = props => {
    try{
        let newProps = props;
        newProps.formValues.userInfo = props.userInfo
            ? props.keycloakUserInfo
            : null;
        return newProps.formValues;
    }
    catch (e) {
        return props.formValues;
    }
};
