import { createAction } from "redux-actions";
import {MODAL_VISIBLE, MODAL_BODY} from "../constants";

export const showModal = createAction(MODAL_VISIBLE);
const modalBody = createAction(MODAL_BODY);


export const mostrarModal = (header,body, footer) => {
  return (dispatch, getState) => {
      var modal = getState().modalvisible;
      modal ? dispatch(showModal(false)) : dispatch(showModal(true));
      var bodyModal = {};
      if(header) bodyModal = {...bodyModal, header};
      if(body) bodyModal = {...bodyModal, body};
      if(footer) bodyModal = {...bodyModal, footer};
      !modal && dispatch(modalBody(bodyModal))
  }
};