import _ from 'lodash';
import moment from "moment";

export const agibizPostForm = (obj) =>{
    var obj2 =  {...obj};
    if(obj2.hasOwnProperty('listComentarioFormulario')){
             obj2.listComentarioFormulario = {};
             obj2.listComentarioFormulario.descripcion = Array.isArray(obj.listComentarioFormulario) ? null : obj.listComentarioFormulario;
             obj2.listComentarioFormulario.descripcion = obj2.listComentarioFormulario.descripcion === "" ? null : obj2.listComentarioFormulario.descripcion
    }
    if(obj.listDocumentos){
        obj2.listDocumentos = Object.values(obj2.listDocumentos);
        obj2.listDocumentos.filter(doc => doc !== "" && doc !== null);
    }
    if(obj.listCife){
        obj2.listCife.tipo ="CIFE";
    }
    if(obj.listPfc) {
        obj2.listPfc.tipo = "PFC";
    }
    if(obj.hasOwnProperty("rol") && typeof  obj.rol !== "string"){
        obj2 = {...obj2 , ...obj.rol}
    }
    if(obj.hasOwnProperty('userInstitucion') && obj.userInstitucion !== 0 && obj.hasOwnProperty('userInfo') && obj.userInfo !== null){
        !obj.userInfo.hasOwnProperty("evg_institucion_perteneciente")
            ? console.log()
            : obj.userInfo.evg_institucion_perteneciente.toString() === obj.userInstitucion.toString()
                ? obj2.fechaPostulacion = moment().format("YYYY-MM-DD")
                : console.log();
    }
    return obj2;
};


export const agibizResponseAdapter = (res) => {
    let response = res;
    if('status' in response && 'statusText' in response){
    response.codigo_error = (response.status) ? response.status : response.codigo_error || response.codigo;
    response.descripcion = (response.statusText) ? response.statusText :response.descripcion;
    response.codigo_error = (response.type) ? response.codigo_error + " " + response.type : response.descripcion;
    }
    return response
};


export const updateIncomingData = (prev) => {
    var post = {...prev};
    if(post.hasOwnProperty('codigo_error')){
        if(prev.listDocumentos && prev.listDocumentos !== null && prev.listDocumentos !== []){
            var newVal;
            try{
                newVal = prev.listDocumentos.reduce((obj, content) => {
                    if(content.label) obj[content.label.substring(content.label.indexOf(".")+1)] = content;
                    return obj;
                },{});
            }
            catch (e) {
            }
            post.listDocumentos = newVal;
        }
        if(prev.hasOwnProperty('entrevistador') && !prev.hasOwnProperty("asignador")){
            post.rol = {...post.rol ,"entrevistador" : prev.entrevistador}
        }
        if(prev.hasOwnProperty('intermediario') && !prev.hasOwnProperty("asignador")){
            post.rol = {...post.rol, "intermediario" : prev.intermediario}
        }
    }
    const respuesta = (post.estado === null)
        ? post
        : (post.codigo_error === 1000 || post.codigo_error === 0)
            ? post
            : prev;
    post.codigo_error === 0  && console.log();
    return (post.estado === null)
        ? post
        : (post.codigo_error === 1000 || post.codigo_error === 0)
            ? post
            : prev;
};

export const extServerListener = (response, modal) =>{
    response.codigo_error || response.codigo
        ? modal(`Mensaje` ,`${response.descripcion}`)
        : modal(`Ha ocurrido algo inesperado`, JSON.stringify(response.response || response, null, 2),"")
};

export const agibizRequiredCheckerExt = (values) => {
    if(values.listDocumentos || values.listCife){
        let docs = values.listDocumentos;
        let respuesta = !!((docs.fotocopiaCni && docs.fotocopiaCni.hasOwnProperty('label') &&
            docs.documentoInhabilidades && docs.documentoInhabilidades.hasOwnProperty('label') &&
            docs.certificadoJefatura && docs.certificadoJefatura.hasOwnProperty('label') &&
            docs.certificadoAntecedentes && docs.certificadoAntecedentes.hasOwnProperty('label') &&
            docs.curriculum && docs.curriculum.hasOwnProperty('label') ) &&
            (values.listCife.baseAprobacion &&
                values.listCife.baseInformeFinal &&
                values.listCife.baseAprobacion.hasOwnProperty('label') && values.listCife.baseAprobacion.base !== "" &&
                values.listCife.baseInformeFinal.hasOwnProperty('label') && values.listCife.baseInformeFinal.base !== "") &&
            (values.listCife.codInstitucionFormadora || values.listCife.otraInstitucionFormadora || values.listCife.institucionFormadora))
        return respuesta
    }
    else {
        return console.log()
    }
};


const checkCapacitacion = (val, str) => {
    return ( val.hasOwnProperty(str) &&
        (val[str].baseAprobacion &&
        val[str].baseInformeFinal &&
        val[str].baseAprobacion.hasOwnProperty('label') &&
        val[str].baseInformeFinal.hasOwnProperty('label')) &&
    (val[str].codInstitucionFormadora || val[str].otraInstitucionFormadora || val[str].institucionFormadora))
        ? val
        : null
};

const cifePfcChecker = values => {
    values.hasOwnProperty("listCife") && checkCapacitacion(values, "listCife");
    values.hasOwnProperty("listPfc") && checkCapacitacion(values,"listPfc");
};

export const estado = (values, estado) => {
    values.estado = estado;
    return values;
};

const cleanDocs = values => {
    return values;
};

export const agibizIngresoReset = (val) => val.estado && val.estado === "registrado" && val.process === "ingreso" && estado(val, "BORRADOR") || val;

const ingresoChecker = (values) => {

    if( ((values.listDocumentos || values.listCife) && values.process === "ingreso")
    ){

        let docs = values.listDocumentos;
        return ((docs.fotocopiaCni && docs.fotocopiaCni.hasOwnProperty('label') &&
            docs.documentoInhabilidades && docs.documentoInhabilidades.hasOwnProperty('label') &&
            docs.certificadoJefatura && docs.certificadoJefatura.hasOwnProperty('label') &&
            docs.certificadoAntecedentes && docs.certificadoAntecedentes.hasOwnProperty('label') &&
            docs.curriculum && docs.curriculum.hasOwnProperty('label')
            && docs.declaracionDeDisponibilidadCIFE && docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('label')) &&
            (docs.fotocopiaCni && !docs.fotocopiaCni.hasOwnProperty('pathArchivo') &&
                docs.documentoInhabilidades && !docs.documentoInhabilidades.hasOwnProperty('pathArchivo') &&
                docs.certificadoJefatura && !docs.certificadoJefatura.hasOwnProperty('pathArchivo') &&
                docs.certificadoAntecedentes && !docs.certificadoAntecedentes.hasOwnProperty('pathArchivo') &&
                docs.curriculum && !docs.curriculum.hasOwnProperty('pathArchivo')
                && docs.declaracionDeDisponibilidadCIFE && !docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('pathArchivo') ) &&
            (values.hasOwnProperty('listCife') && checkCapacitacion(values, "listCife") !== null ))
            ? values.estado === "BORRADOR" && values.submitted
                ? estado(values, "REGISTRADO")
                : values.estado !== "BORRADOR" && values.submitted && values.process === "ingreso"
                    ? estado(values, "BORRADOR")
                    : values
            : null
    }
    else if(values.estado === "REGISTRADO" && (values.process === "editaracreditacion" || values.process === "acreditacion")){
        let docs = values.listDocumentos;
        return ((docs.fotocopiaCni && docs.fotocopiaCni.hasOwnProperty('label') &&
            docs.documentoInhabilidades && docs.documentoInhabilidades.hasOwnProperty('label') &&
            docs.certificadoJefatura && docs.certificadoJefatura.hasOwnProperty('label') &&
            docs.certificadoAntecedentes && docs.certificadoAntecedentes.hasOwnProperty('label') &&
            docs.curriculum && docs.curriculum.hasOwnProperty('label')
            && docs.declaracionDeDisponibilidadCIFE && docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('label')) &&
            (docs.fotocopiaCni && !docs.fotocopiaCni.hasOwnProperty('pathArchivo') &&
            docs.documentoInhabilidades && !docs.documentoInhabilidades.hasOwnProperty('pathArchivo') &&
            docs.certificadoJefatura && !docs.certificadoJefatura.hasOwnProperty('pathArchivo') &&
            docs.certificadoAntecedentes && !docs.certificadoAntecedentes.hasOwnProperty('pathArchivo') &&
            docs.curriculum && !docs.curriculum.hasOwnProperty('pathArchivo')
            && docs.declaracionDeDisponibilidadCIFE && !docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('pathArchivo')) &&
            (values.hasOwnProperty('listCife') && checkCapacitacion(values, "listCife") !== null ))
            ? cleanDocs(values)
            : null
    }
    else if(values.estado === "ACREDITADO" && values.revalidacion === null){

      let docs = values.listDocumentos;
      return ((docs.fotocopiaCni && docs.fotocopiaCni.hasOwnProperty('label') &&
          docs.documentoInhabilidades && docs.documentoInhabilidades.hasOwnProperty('label') &&
          docs.certificadoJefatura && docs.certificadoJefatura.hasOwnProperty('label') &&
          docs.certificadoAntecedentes && docs.certificadoAntecedentes.hasOwnProperty('label') &&
          docs.curriculum && docs.curriculum.hasOwnProperty('label')
          && docs.declaracionDeDisponibilidadCIFE && docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('label')
          && docs.declaracionDeDisponibilidadPFC && docs.declaracionDeDisponibilidadPFC.hasOwnProperty('label')) &&
          (docs.fotocopiaCni && !docs.fotocopiaCni.hasOwnProperty('pathArchivo') &&
              docs.documentoInhabilidades && !docs.documentoInhabilidades.hasOwnProperty('pathArchivo') &&
              docs.certificadoJefatura && !docs.certificadoJefatura.hasOwnProperty('pathArchivo') &&
              docs.certificadoAntecedentes && !docs.certificadoAntecedentes.hasOwnProperty('pathArchivo') &&
              docs.curriculum && !docs.curriculum.hasOwnProperty('pathArchivo')
              && docs.declaracionDeDisponibilidadCIFE && !docs.declaracionDeDisponibilidadCIFE.hasOwnProperty('pathArchivo')
              && docs.declaracionDeDisponibilidadPFC && !docs.declaracionDeDisponibilidadPFC.hasOwnProperty('pathArchivo')) &&
          (values.hasOwnProperty('listPfc') && checkCapacitacion(values, "listPfc") !== null ))
          ? values: null
    }
    else{
        return null
    }
};

const revalidacionPfcnull = (values, process, secondProcess) => {
    return values.hasOwnProperty('revalidacion') && values.revalidacion === null
        ? checkCapacitacion(values, "listPfc") === values ? values : null
        : resolucionChecker(values, process, secondProcess);
};

const resolucionChecker = (values, process, secondProcess, thirdProcess) => {
    if(values.hasOwnProperty(process) &&
        values[process]  !== null &&
        values[process].baseDocResolutivo && values[process].baseDocResolutivo.hasOwnProperty("label") &&
        //values[process].baseDocNotificacion && values[process].baseDocNotificacion.hasOwnProperty("label") &&
        values[process].fechaResolucion && values[process].fechaResolucion !== "" &&
        //values[process].fechaNotificacion && values[process].fechaNotificacion !== "" &&
        values.hasOwnProperty('rol') && values.rol !== null && Object.values(values.rol).filter(tru => tru).length > 0
    ){
        values.hasOwnProperty('listDocumentos') ? delete values.listDocumentos : console.log();
        values.hasOwnProperty('listComentarioFormulario') ? delete values.listComentarioFormulario : console.log();
        return values;
    }
    else {
        return null
    }
};

const resolucionChecker2 = (values, process, secondProcess, thirdProcess) => {
    if(values.hasOwnProperty(process) &&
        values[process]  !== null &&
        values[process].baseDocResolutivo && values[process].baseDocResolutivo.hasOwnProperty("label") &&
        values[process].baseDocNotificacion && values[process].baseDocNotificacion.hasOwnProperty("label") &&
        values[process].fechaResolucion && values[process].fechaResolucion !== "" &&
        values[process].fechaNotificacion && values[process].fechaNotificacion !== "" &&
        values.hasOwnProperty('rol') && values.rol !== null && Object.values(values.rol).filter(tru => tru).length > 0
    ){
        values.hasOwnProperty('listDocumentos') ? delete values.listDocumentos : console.log();
        values.hasOwnProperty('listComentarioFormulario') ? delete values.listComentarioFormulario : console.log();
        return values;
    }
    else {
        return null
    }
};


const habilitar = values =>{
    return values;
};

const validacionOficinaPartes = (values) => {
  if(values.oficinapartes && values.oficinapartes.baseDocNotificacion && values.oficinapartes.baseDocNotificacion.hasOwnProperty("label") &&
    values.oficinapartes.fechaNotificacion && values.oficinapartes.fechaNotificacion!==""
  ){
    return values;
  }else{
    return null;
  }
};

export const stateReset = (val, res) => !_.isEmpty(res) &&
    res.hasOwnProperty("codigo" || "codigo_error") &&
    res.codigo === 1005 || res.codigo_error === 1005 &&
    val.hasOwnProperty("estado") &&
    val.hasOwnProperty("process") &&
    val.hasOwnProperty('acreditacion') &&
    val.acreditacion === null &&
    val.estado === "REGISTRADO" &&
    val.process === "ingreso"
        ? "BORRADOR"
        : val.process !== undefined
            ? "REGISTRADO"
            : val.hasOwnProperty('acreditacion') && val.acreditacion !== null
            ? "REGISTRADO"
            : "BORRADOR";

const validarPfc = (res) => res.hasOwnProperty('codInstitucionFormadora') && res.codInstitucionFormadora !== null &&
        res.hasOwnProperty('fechaPresentacion') && res.codInstitucionFormadora !== null && res.fechaPresentacion !== "" &&
        res.hasOwnProperty('fecha') && res.fecha !== "" && res.fecha !== null &&
        res.baseInformeFinal && res.baseInformeFinal.hasOwnProperty('label') &&
        res.baseAprobacion && res.baseAprobacion.hasOwnProperty('label')
            ? res
            : null;

const nuevaReclamacion = values => values.baseDocReclamacion && values.baseDocReclamacion.hasOwnProperty('label') &&
        values.hasOwnProperty('fecha') && values.fecha !== null && values.fecha !== ""
        ? values
        : null;

const notificacionChecker = values => {
    //console.log("LLEGO A NOTIFICACION CHECKER", values);
    return (values.estado === "REGISTRADO" && resolucionChecker2(values, "acreditacion")) ||
    (values.estado === "ACREDITADO" && resolucionChecker2(values, "revalidacion") )||
    (values.estado === "RECHAZADO" && resolucionChecker2(values, "reclamacion")) ? values : null;
    //return null;
};

export const formRequirements = {
    agibiz : {
        ingreso : (values) => ingresoChecker(values),
        acreditacion : (values) => values.acreditacion !== null ? resolucionChecker(values, "acreditacion", "resolucion") : ingresoChecker(values),
        editaracreditacion: (values) => ingresoChecker(values),
        reclamacion : (values) => resolucionChecker(values, "reclamacion", "acreditacion"),
        notificacion : (values) => notificacionChecker(values),
        revalidacion : (values) => revalidacionPfcnull(values, "revalidacion", "lugarTrabajo"),
        revalidacionPfc : (values) => revalidacionPfcnull(values),
        habilitar : (values) => habilitar(values),
        pfc: (values) => validarPfc(values),
        ingresarReclamacion: (values) => nuevaReclamacion(values),
        oficinaPartes : (values) => validacionOficinaPartes(values),
        modificacion:  res => res
    }
};
