import React from 'react';
import {genericFieldProps} from "@isobar-us/redux-form-gen";
import ActionButtonField from "../Components/ActionButtonField";
import {string2Json} from "../../../../../../helpers/util";

export const ActionButtonType = ({field}) => {
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        component: ActionButtonField,
        massive: string2Json(field.conditionalValid)
    };
};