import {createSelector} from 'reselect';
import {store} from "../index";

export const getKeycloak = state => state.keycloak || {}; // Entrega un objeto con todas los datos entregados por kc

const urlLogout = state => {
        try{
                return state.keycloak.createLogoutUrl() || "";
        }catch (e) {
                console.log("ocurrio un error en crear el urlLogout", e);
                console.log("el state emtregado era", state)
                return ""
        }
} // Entrega una funcion que genera la url para cerrar sesión

export const userInfo = state => state.keycloak.userInfo();

export const getUserProfileData = state => state.userProfile || {} ; // Entrega un objeto que tiene todos los datos del usuario

export const getUserProfile = createSelector( // Entrega un objeto con la imagen del usuario, la url para cerrar sesion y el nombre de usuario
    urlLogout,
    getUserProfileData,
    (urlLogout, data) => {
        var valores = {urlImgUsuario: "assets/img/avatar.png", urlLogout: "#", username: "Invitado"};
        if(data.attributes && data.attributes.photo) valores={...valores, urlImgUsuario:'data:image/jpg;base64,' + data.attributes.photo };
        if(urlLogout) valores={...valores, urlLogout: urlLogout};
        if(data.username) valores={...valores, username: data.username};
        if(data.firstName) valores={...valores, username: data.firstName};
        return valores;
    }
);


