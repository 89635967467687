import React, { Component } from 'react';
import WrapperField from "./WrapperField";
import Textarea from 'react-textarea-autosize';
import getFormValues from "redux-form/es/getFormValues";
import { getHide, getSelectData } from "../../../../../../selectors/select";
import connect from "react-redux/es/connect/connect";
import { setSeleccion } from "../../../../../../actions/setSelections";
import { setVisible } from "../../../../../../actions/setVisible";
import { setHidden } from "../../../../../../actions/setHidden";
import { getUserData } from "../../../../../../helpers/Token";
import { getUserProfile, getUserProfileData } from "../../../../../../selectors/keycloak";
import moment from "moment";
import _ from "lodash";
import { SubmissionError } from 'redux-form';

class CommentField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: props.options || null,
            disabled: false,
            visited: false,
            touched: false,
            active: false,
            valid: false,
            invalid: true,
            changed: false,
            prev: null,
            new: null,
            loaded: false
        }
    };

    checkLength = (event) => {
        const { maxLength, minLength } = this.props.conditionals;
        let newEvent = event.target.value;
        let textLength = event.target.value.toString().length;
        (textLength > this.props.conditionals.maxLength)
            ? event.target.value = newEvent.slice(0,this.props.conditionals.maxLength)
            : this.setState({valid:true});
        event.target.value = newEvent.slice(0, this.props.conditionals.maxLength);
        if(minLength && maxLength
            ? textLength >= minLength && textLength <= maxLength
            : minLength
                ? textLength >= minLength
                : maxLength
                    ? textLength <= maxLength
                    : textLength !== 0){
            this.setState({
                valid: true,
                invalid: false,
                changed: true,
                touched: true,
                visited: true
            });
            this.props.meta.valid = true;
            this.props.meta.invalid = false;
            this.props.meta.changed = true;
            this.props.meta.touched = true;
            this.props.meta.visited = true;
        }
        else {
            this.setState({
                valid: false,
                invalid: true,
                changed: true,
                touched: true,
                visited: true
            });
            this.props.meta.valid = false;
            this.props.meta.invalid = true;
            this.props.meta.changed = true;
            this.props.meta.touched = true;
            this.props.meta.visited = true;
        }
        return event;
    };

    handleOnChange = (event) => {
        event = this.checkLength(event);
        this.props.meta.visited = true;
        this.props.meta.touched = true;
        this.setState({ new: event.target.value });
        return this.props.input.onChange(event);
    };


    handleOnFocus = (event) => {
        this.setState({
            visited: true,
            active: true,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.valid && this.props.input.value.toString().length === 0) {
            this.setState({ valid: false, invalid: true });
        }
    }


    componentDidMount() {
        if (this.props.conditionals.maxLength && this.props.conditionals.maxLength === 50) this.props.conditionals.maxLength = 200;
        this.state.prev === null && this.setState({ prev: this.props.input.value });
        if (this.state.new === null) this.props.input.onChange(null);
        if (this.props.input.value) {

            if(!this.state.new && !!this.props.input.value && this.props.input.name==="motivoBloqueo"){
                this.props.input.onChange(this.props.input.value);
                this.setState({new: this.props.input.value});
            }
            
            if(!this.state.new && !!this.props.input.value && this.props.input.name==="listDarDeBaja.motivo"){
                this.props.input.onChange(this.props.input.value);
                this.setState({new: this.props.input.value});
            }

            if (this.props.input.value)
                this.setState({
                    valid: true,
                    invalid: false
                });
            this.props.meta.valid = true;
            this.props.meta.invalid = false;
        }
    };

    handleOnBlur = (event) => {
        this.setState({
            visited: true,
            touched: true
        });
    };



    renderTextarea = ({label, type, placeholder, input, meta, required, disabled}) => {
        return (
            <Textarea
                {...input}
                {...meta}
                value={this.state.new}
                type={type}
                disabled={disabled}
                className="form-control async-validating"
                placeholder={placeholder}
                onBlur={this.handleOnBlur}
                onChange={this.handleOnChange}
                onFocus={this.handleOnFocus}
                minRows={3}
                style={{ padding: "10px",
                    borderRadius: "5px" ,
                zIndex:"0"}}
                disabled={this.state.disabled}
            />)
        };

        structureArray = (arr) => {
            arr.map(arr => {
                this.renderCommentArea(arr)
            })
        };

        init = () => {
            this.props.meta.touched = this.state.touched;
            this.props.meta.active = this.state.active;
            this.props.meta.visited = this.state.visited;
            this.props.meta.required = this.props.required;
            this.props.meta.valid = this.state.valid;
            this.props.meta.invalid = this.state.invalid;
        };

    renderPreviousComment = ({fecha, descripcion, nombre_usuario, conditionals}) => <div>
                <br/>
        <div className="input-group col-md-12 col-xs-12"  style={{
            backgroundColor:"#F3F2F2",
            padding: "10px",
            borderRadius: "7px"
        }}>
                    <div className="form">
                        <div className="post">
                            <div className="user-block" style={{paddingLeft:"0px",marginLeft:"0px"}}>
                                <img src={`assets/img/avatarcomment.png`} className="direct-chat-img"/>
                                <span className="username">{nombre_usuario}</span>
                                <span className="description">{fecha}</span>
                            </div>
                            <p> {descripcion} </p>
                        </div>
                    </div>
                </div>
            </div>;
    /*

*/
    renderNewComment = (usuario, fecha, conditionals) => <div>
        <br/>
    <div className="input-group col-md-12 col-xs-12"  style={{
        backgroundColor:"#F3F2F2",
        padding: "10px",
        borderRadius: "7px"
    }}>
            <div className="form">
                <div className="post">
                    <div className="user-block" style={{paddingLeft:"0px",marginLeft:"0px"}}>
                        <img src={`assets/img/avatarcomment.png`} className="direct-chat-img"/>
                        <span className="username">{usuario}</span>
                        <span className="description">{fecha}</span>
                    </div>
                    {this.renderTextarea(this.props)}
                    <div style={{marginBottom:"-5px"}}>
                        {
                            conditionals && conditionals.hasOwnProperty("maxLength" && "minLength")
                                ? <div>
                                    <span className="description" style={{color:"darkgrey"}}> ** El largo máximo del texto a ingresar es de:  {` ${conditionals.maxLength}  caracteres`} </span><br/>
                                    <span className="description" style={{color:"darkgrey"}}> ** El largo mínimo del texto a ingresar es de:  {` ${conditionals.minLength}  caracteres`} </span>
                                </div>
                                : conditionals && conditionals.hasOwnProperty("maxLength" || "minLength")
                                ? conditionals.maxLength !== null && conditionals.maxLength !== ""
                                    ? <span className="description" style={{color:"darkgrey"}}> ** El largo máximo del texto a ingresar es de:  {` ${conditionals.maxLength}  caracteres`} </span>
                                    : <span className="description" style={{color:"darkgrey"}}> ** El largo mínimo del texto a ingresar es de:  {` ${conditionals.minLength}  caracteres`} </span>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>;

    render() {
        this.init();
        const {label, input:{value}, meta, required, formValues, userdata:{username}, disabled, conditionals} = this.props;
        const { prev } = this.state;
        return(
            <WrapperField label={label} required={required} className="col-md-12 col-xs-12 col-lg-12" meta={meta}>
                {prev && Array.isArray(prev) && prev.length > 0
                    ? prev.map(prex => {
                        prex.nombre_usuario =   prex.hasOwnProperty('nombre_usuario') ? prex.nombre_usuario : "username";
                        prex.fecha = prex.hasOwnProperty('fecha') ? prex.fecha : "fecha indefinida";
                        return  !_.isEmpty(prex) && this.renderPreviousComment(prex)
                      })
                    : disabled && "No hay comentarios."}
                {!disabled && this.renderNewComment(username, moment().format("DD/MM/YYYY"), conditionals)}

            </WrapperField>
        );
    }
}
CommentField.defaultProps = {
    conditionals:{
        maxLength: 200,
    },
    contenidoComentario: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ac pulvinar nisl Vivamus ultrices sed mauris in aliquam. Vivamus lorem purus, vulputate sed nunc a, laoreet",
    userdata:{
        username: "username"
    },
    username: 'username'
};

    const mapStateToProps = (state) => ({
        formValues: getFormValues('form')(state),
        selecciones: getSelectData(state),
        show: getHide(state),
        userProfile: getUserProfile(state),
        userdata: getUserProfileData(state)
    });

    export default connect(mapStateToProps, { setSeleccion, setVisible, setHidden })(CommentField);
