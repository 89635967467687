import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {getAccion, getAccionColumns, getAccionData, getAccionSchema} from "../../selectors/estructura";
import getFormValues from "redux-form/es/getFormValues";
import {mostrarModal} from "../../actions/mostrarModal";
import {actionHandleSubmit, insertAccionData} from "../../actions/insertAccionData";
import {fetchAccionData} from "../../actions/fetchAccionData";
import {updateActionData} from "../../actions/updateActionData";

class ModalTrigger extends Component {

    render() {
        console.log("ENTRO A MODAL TRIGGER CLASS CON ESTOS PROPS..." , this.props);
        const {header, body, footer } = this.props;
        this.props.mostrarModal(header,body,footer);
        return console.log(this.props.mostrarModal)
    }
}

const mapStateToProps = state => ({
    data: getAccionData(state),
    schema: getAccionSchema(state),
    fields: getAccionColumns(state),
    accion: getAccion(state),
    formValues: getFormValues('form')(state),
});

export default connect(mapStateToProps,{mostrarModal, insertAccionData,fetchAccionData, updateActionData, actionHandleSubmit})(ModalTrigger);