import {
    SET_ACCION_DATA
} from "../constants";
import { createAction } from 'redux-actions';
import {setAuxData} from "./data";


export const setAccionData = createAction(SET_ACCION_DATA);

export const setAccionData2 = dataToSet => {
    return (dispatch, getState) => {
        const prevData = getState().accionValor.data;
        try {
            dispatch(setAuxData(dataToSet))
        }catch (e) {
            console.log("ERROR En reduxaction",e)
        }
        //dispatch(setHidden({"hide":fieldToHide}))
    }
};
