import React, {Component} from 'react';
import {connect} from 'react-redux';
import WrapperField from "./WrapperField";
import getFormValues from "redux-form/es/getFormValues";
import {unregisterField} from 'redux-form';
import {change} from "redux-form/es/index"
import {apiGet} from "../../../../../../api";
import Select from 'react-select';
import isPlainObject from "redux-actions/es/utils/isPlainObject";
import {getOcultos, getSelectData} from "../../../../../../selectors/select";
import _ from 'lodash';
import {setSeleccion} from "../../../../../../actions/setSelections";
import {parentFinder, shouldHide, string2Json} from "../../../../../../helpers/util";
import {setHiddenNew, clearHiddenNew} from "../../../../../../actions/setHidden";


class SelectField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            loaded:false
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.input.value === '' && this.state.value !== null) {
            this.setState({value: null});
        }
    }

    formOptions = (arreglo, label, value) => {
        if (arreglo) {
            return arreglo.map(v => {
                return {label: v[label], value: v[value]}
            })
        }
    };

    handleChange = (value) => {
        this.setState(prevState => ({
            ...prevState,
            value: value
        }));
        this.state.hasOwnProperty("children") && this.clearChildren(this.state.children);
        return !this.props.isMulti ? this.props.input.onChange(value !== null ? value.value : value) : this.props.input.onChange(value.map(v => v.value) || value );
    };

    fetchInitialValue = async () => {
        const {elementoOpcion: {label, value, endpoint}} = this.props;
        let array = await  apiGet(`${endpoint}`)().then(v => {
            const data = this.formOptions(v, label, value);
            return data;
        });
        return array;
    };

    clearChildren = (children) =>{
        const {formValues} = this.props;
        formValues[children.input.slice(0, children.input.indexOf('.'))] && this.deleteExternalValue(children)
    };

    deleteExternalValue = ({input}) => {
        const { change } = this.props;
        change('form', input, '');
    };

    setChildren = (sel) => {
        if(!this.state.hasOwnProperty('children')){
            this.setState({children: sel[0]});
        }
    };

    checkChildrens = ({label, formValues, selecciones, elementoOpcion:{elementoFormulario:{questionId}}}) => {
        if( selecciones &&
            !_.isEmpty(selecciones) &&
            selecciones[questionId]){
            let sel = Object.values(selecciones).filter(seleccion => seleccion.filterBy === questionId);
            sel.length > 0 && this.setChildren({...sel});
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        prevState.value && !this.state.value && this.evalHide(string2Json(this.props.massive.visible), this.props.formValues, true);
        this.state.value && this.props.massive.visible && this.evalHide(string2Json(this.props.massive.visible), this.props.formValues, false);
        this.checkChildrens(this.props);
    }

    evalHide = (condition, values, show) => {
        Array.isArray(condition.parent)
            ? condition.parent.map( par => shouldHide({...condition, parent:par}, values) ? show ? this.props.clearHiddenNew(par) : this.props.setHiddenNew(par) : console.log())
            : shouldHide(condition, values) ? show ? this.props.clearHiddenNew(condition.parent) : this.props.setHiddenNew(condition.parent) : console.log("no hay que ocultar a ", this.props.label)

    };


    componentDidMount() {
        if(this.state.value === null && !this.state.loaded && this.props.input.value !== "") {
            const {filterBy, elementoOpcion: {label, value, endpoint, elementoFormulario}, formValues, setSeleccion} = this.props;
            this.fetchInitialValue(endpoint, this.props.input.value)
                .then(array => {
                    return array.filter(m => m.value === parseInt(this.props.input.value) || m.value === this.props.input.value)
                })
                .then(filtered => {
                    setSeleccion({
                        [elementoFormulario.questionId]: {
                            ...filtered,
                            input: elementoFormulario.questionId,
                            filterBy: filterBy
                        }
                    });
                    if (filtered !== []) {
                        this.setState({
                            value: filtered,
                            loaded: true
                        });
                    }
                });
        }
    }

    inputReader = (event) => {
    };

    render() {
        const {input, massive, parent, options, label, required, meta, disabled, isMulti, placeholder, elementoOpcion: {elementoFormulario}} = this.props;
        return (
            <div>
                <WrapperField label={label} required={required} meta={meta} questionId={elementoFormulario.questionId} massive={massive}>
                    <label>{parent}</label>
                    <Select
                        value={this.state.value}
                        options={options}
                        onChange={this.handleChange}
                        placeholder={placeholder || "Seleccione..."}
                        isDisabled={disabled}
                        isMulti={isMulti}
                        isSearchable
                        isClearable
                        inputReader={() => this.inputReader}
                    />
                </WrapperField>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    ocultos: getOcultos(state)
});
export default connect(mapStateToProps, {setSeleccion, unregisterField, setHiddenNew, clearHiddenNew, change})(SelectField);