import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, reduxForm} from "redux-form";
import {FormGenerator, injectGenProps} from "@isobar-us/redux-form-gen";
import CustomFieldTypes from "./CustomFieldTypes";
import CustomOperators from "./CustomOperators";
import Icon from "../../../../helpers/util/Icon";

class TabForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep:0
        };
    }

    handleStep = (step) => this.setState({currentStep: step});

    renderTabs = (fields) => fields.map((section, index)=>
        <li className={ (index===0)&& "active"}>
            <a href="#contenido" onClick={ ()=> this.handleStep(index)} data-toggle="tab">{section.label}</a>
        </li>
    );


    render() {
        const {handleSubmit, fields, submitting, reset, submitSucceeded, alerta, invalid, pristine} = this.props;
        console.log("props antes de form ",this.props);
        return (
            <Form onSubmit={handleSubmit}>
                <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                        {this.renderTabs(fields)}
                    </ul>
                </div>
                <div className="tab-content">
                    <div className="tab-pane active">
                        <FormGenerator fields={fields}
                                       display="inline"
                                       customFieldTypes={CustomFieldTypes}
                                       customOperators={CustomOperators}
                                       visibleDepth={`fields[${this.state.currentStep}]`}/>
                    </div>
                </div>
                <div className="box-footer col-md-12">
                    <button type="button" className="btn btn-danger" disabled={submitting || pristine} onClick={reset}>
                        Limpiar &nbsp;
                        <Icon clase="fas fa-broom"></Icon>
                    </button>
                    <button type='submit' className="btn btn-primary pull-right" disabled={submitting || invalid}>
                        Guardar&nbsp;
                        <Icon clase="fa fa-download"/>
                    </button>&nbsp;
                </div>
            </Form>)
    }
}

TabForm.propTypes = {};

export default injectGenProps(reduxForm({form:'form'})(TabForm));