import Keycloak from 'keycloak-js';
import {kc}from './config/';

export const keycloak = Keycloak(kc);











