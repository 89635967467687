export const parentFinder = ({parent}, formValues) =>{
    try{
        const data = parent
            ? parent.includes(".")
                ? eval(`formValues.${parent}`)
                : formValues[parent]
            : null;
        return data !== undefined ? data : null
    }catch (e) {
        return null;
    }
};

export const parentLabelFinder = ({parent}, fields) => fields.reduce( (ant, {childFields}) => {
        let res = childFields.find(({questionId}) => questionId === parent);
        if(res !== undefined) return res;
    },[]).label;