import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import SideApp from "../components/SideApp";
import {getSistemas} from "../selectors/estructura";
import {setSistemaSeleccionado} from "../actions/setSistemaSeleccionado";

class SideAppContainer extends Component {

    handleOnClick = sistema =>{
        if(!sistema.mantenimiento) this.props.setSistemaSeleccionado(sistema);
    };

    render() {
        console.log(this.props);
        return (
            <SideApp sistemas={this.props.sistemas} handleOnClick={this.handleOnClick}/>
        );
    }
    
    componentDidMount() {
        setTimeout(function(){
            document.getElementById('controlSidebar').click();
        }, 3000);
        
        document.getElementById('controlSidebar').addEventListener('click', function() {
            if (!document.getElementById('sideapp').classList.contains('control-sidebar-open')){
                setTimeout(function(){
                    document.getElementById('sideapp').classList.remove('control-sidebar-open');
                }, 3000);
            }
            
        }, false);
    }
}

SideAppContainer.propTypes = {
    sistemas: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => ({
    sistemas: getSistemas(state),
});


export default connect(mapStateToProps,{setSistemaSeleccionado})(SideAppContainer);