import {
    FETCH_ACCION_DATA,
    FETCH_ACCION_SCHEMA,
    FETCH_SISTEMAS,
    FETCH_USER_PROFILE_DATA,
    FETCH_MODULOS_POR_SISTEMA,
    FETCH_USER_PROFILE_INFO,
    UPDATE_ACCION_DATA,
    INSERT_ACCION_DATA
} from '../constants';

export const types = {
    [FETCH_ACCION_SCHEMA]: true,
    [FETCH_ACCION_DATA]: true,
    [FETCH_SISTEMAS]: true,
    [FETCH_USER_PROFILE_DATA]: true,
    [FETCH_MODULOS_POR_SISTEMA]: true,
    [FETCH_USER_PROFILE_INFO]: true,
    [UPDATE_ACCION_DATA]: true,
    [INSERT_ACCION_DATA]: true
};