import { combineReducers } from 'redux';
import  {estructura} from './estructura';
import {accionSeleccionada} from "./accionSeleccionada";
import {keycloak} from './keycloak';
import {userProfile} from "./userProfile";
import {userInfo} from "./userInfo";
import {accionValor} from "./accionValor";
import { reducer as reduxForm } from 'redux-form';
import {loadingBarReducer} from 'react-redux-loading-bar';
import {modal} from "./modal";
import {modalBody} from "./modalBody";
import { selecciones } from './selecciones';
import { borrarSelecciones } from './selecciones';
import {hide, ocultos, toHide} from './hide';
import { formResponse } from "./formResponses";
import { previousData } from './previousData';
import { previousAccion } from './previousAccion';
import { previousForm } from './previousForm';
import {stats} from "./stats";
import {formReducer} from './form';
import {auxData} from "./auxData";

export default combineReducers({
    estructura,
    accionSeleccionada,
    keycloak,
    userProfile,
    userInfo,
    accionValor,
    form: reduxForm,
    formReducer,
    loadingBar: loadingBarReducer,
    modalvisible: modal,
    modalBody,
    selecciones,
    hide,
    ocultos,
    formResponse,
    previousData,
    previousAccion,
    stats,
    previousForm,
    auxData,
    toHide
});