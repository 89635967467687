import React, {Component} from 'react';
import WrapperField from "./WrapperField";
import textareaType from "@isobar-us/redux-form-gen/lib/defaultFieldTypes/types/textareaType";
import Field from "redux-form/es/Field";
import connect from "react-redux/es/connect/connect";
import getFormValues from "redux-form/es/getFormValues";
import {getHide, getSelectData} from "../../../../../../selectors/select";
import {setSeleccion} from "../../../../../../actions/setSelections";
import {setVisible} from "../../../../../../actions/setVisible";
import {setHidden} from "../../../../../../actions/setHidden";
import Textarea from 'react-textarea-autosize';

class TextAreaField extends Component {

    constructor(props){
        super(props);
        this.state={
            options: props.options || null,
            disabled: false
        }
    }


    handleOnChange = (event) => {
        console.log(event.target.value);
        //this.props.input.onChange(event.target.value);
        return event.target.value;
        //return this.props.input.onChange(event);
    };

    handleOnFocus = (event) => {
        console.log(event);
        return event;
    };

    handleOnBlur = (event) => {
        this.props.input.onChange(event);
    };

    renderTextArea = () => {
        const {label, type, placeholder, input, meta, required, disabled} = this.props;
        return (
            <textarea
                {...input}
                onChange={this.handleOnChange}
                onFocus={this.handleOnFocus}
                className="form-control col-md-6"
                rows="5"/>
        );
    };

    render() {

        const {label, type, placeholder, input, meta, required, disabled} = this.props;
        //console.log("TEXTAREA PROPS: ", this.props)
        return (
            <WrapperField label={label} required={required} meta={meta}>
                <Textarea
                    name={input.name}
                    onChange={this.handleOnChange}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    className="form-control"
                    style={{borderRadius:"10px"}}/>
            </WrapperField>
        );
    }
}

TextAreaField.defaultProps = {
    conditionals:{
        topLength: 70
    }
};

const mapStateToProps = (state) => ({
    formValues: getFormValues('form')(state),
    selecciones: getSelectData(state),
    show: getHide(state)
});

export default connect(mapStateToProps , {setSeleccion, setVisible, setHidden })(TextAreaField);