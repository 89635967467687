export const validarError = (v) =>{
    console.log("VALIDAR ERRORES: ",v);
    if(!v) return Promise.reject(v);
    if(v.error) return Promise.reject(v);
    if(v.errores) return Promise.reject(v);
    return Promise.reject(v) || v;
};

export const recheckResponse = (v) =>{
    console.log("RECHECK ERROR: ",v );
    return v
};
