import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from 'react-loading-overlay';

const Content = ({children, loading}) => {
    const styles = { 'minHeight': '960px' };
    return (
        <LoadingOverlay
            active={!!loading}
            spinner
            text='Cargando...'>
        <div className="content-wrapper" style={styles}>
            {children || null}
        </div>
        </LoadingOverlay>
    );
};

Content.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool
};

Content.defaultProps = {
    loading: false
};

export default Content;



