import React from "react";

export  const statAlert = (tipo,titulo,data,descripcion) =>

<div className={` box box-default no-border small-box ${tipoClassname(Number.isInteger(tipo) ? tipo : parseInt(tipo)) || "bg-info"}`}
     style={{borderRadius: "5px",boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
    <div className="inner" style={{paddingBottom:"0px", marginBottom:"0px"}}>
        <h4><strong>{titulo || ""}</strong></h4>
    </div>
    <div className="inner collapse in" id="body">
        <h3>{data || "data indefinida"}</h3>
        <p>{descripcion || "descripcion indefinida"}</p>
    </div>
    <div className="icon">
        <i className="ion ion-alert-circled"></i>
    </div>
</div>;

const tipoClassname = tipo => {
    switch(tipo){
        case 1 : return "bg-aqua";
        case 2 : return "bg-green";
        case 3 : return "bg-yellow";
        case 4 : return "bg-red";
    }
};