import {genericFieldProps} from '@isobar-us/redux-form-gen';
import DateField from '../Components/DateField';
import {string2Json} from "../../../../../../helpers/util";

export const DateType = ({field}) => {
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    if(!field.conditionalValid){
        field.conditionalValid = {maxDate : ""};
        field.conditionalValid = {minDate : ""};
    }
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        required: field.required,
        component: DateField,
        type: field.inputType,
        disabled: !!field.disabled,
        placeholder: field.placeholder,
        icon: field.icon ? field.icon : false,
        massive: field,
        elementoOpcion: field.elementoOpcion,
        maxDate :field.conditionalValid.maxDate || "",
        minDate: field.conditionalValid.minDate || ""
    };
};

export default DateType;

