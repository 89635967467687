import React from 'react';
import PropTypes from 'prop-types';
import Card from "../index";
import {getDataSistemaSeleccionado} from "../../../../selectors/estructura";
import {connect} from "react-redux";
import {getUserProfileData} from "../../../../selectors/keycloak";
import {Icon} from "../../../../helpers/util";
import Stats from "../Stats";
import {getStats} from "../../../../selectors/stats";
import _ from 'lodash';

const Welcome = (props) => {
    const { sistemaActual, profileData, stats} = props;
    return (
        <Card titulo={`Bienvenido/a: ${profileData.firstName|| "Invitado"}`}>
            <div className='box-body'>
                        <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                                <b>Actualmente estas usando:</b> <p className='pull-right'>{sistemaActual && sistemaActual.nombre || "minjuEscritorio"}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Descripción:</b> <p className='pull-right'>{sistemaActual && sistemaActual.descripcion || "Framework Justicia"}</p>
                            </li>
                        </ul>
                        <ul className="list-group list-group-unbordered">
                            <Icon clase={sistemaActual && sistemaActual.icono && sistemaActual.icono || "fa fa-wrench"}/>
                        </ul>
            </div>
        </Card>
    );
};

Welcome.propTypes = {

};

const mapStateToProps = (state) => ({
    sistemaActual: getDataSistemaSeleccionado(state),
    profileData: getUserProfileData(state),
    stats: getStats(state)
});

export default connect(mapStateToProps)(Welcome);

/*
        <Card titulo={`Bienvenido/a: ${profileData.firstName|| "Invitado"}`}>
            <div className='box-body'>
                {stats !== null && !_.isEmpty(stats)
                    ? <Stats statsData={stats} />
                : <div className='box-body'>
                        <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                                <b>Actualmente estas usando:</b> <p className='pull-right'>{sistemaActual && sistemaActual.nombre || "minjuEscritorio"}</p>
                            </li>
                            <li className="list-group-item">
                                <b>Descripcion:</b> <p className='pull-right'>{sistemaActual && sistemaActual.descripcion || "Framework Justicia"}</p>
                            </li>
                        </ul>
                        <ul className="list-group list-group-unbordered">
                            <Icon clase={sistemaActual && sistemaActual.icono && sistemaActual.icono || "fa fa-wrench"}/>
                        </ul>
                    </div> }
            </div>
        </Card>


        <Card titulo={`Bienvenido/a: ${profileData.firstName|| "Invitado"}`}>
            <div className='box-body'>
                <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                        <b>Actualmente estas usando:</b> <p className='pull-right'>{sistemaActual && sistemaActual.nombre || "minjuEscritorio"}</p>
                    </li>
                    <li className="list-group-item">
                        <b>Descripcion:</b> <p className='pull-right'>{sistemaActual && sistemaActual.descripcion || "Framework Justicia"}</p>
                    </li>
                </ul>
                <ul className="list-group list-group-unbordered">
                    <Icon clase={sistemaActual && sistemaActual.icono && sistemaActual.icono || "fa fa-wrench"}/>
                </ul>
            </div>
        </Card>


 <div className='box-body'>
                    <table className="table table-responsive">
                        <thead>
                            <th className="text-center">INFO</th>
                            <th className="text-center">Riesgo A</th>
                            <th className="text-center">Riesgo B</th>
                            <th className="text-center">Riesgo C</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.renderAlert("titulo","data", "descripcion","icono","classNAme")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                /old



 */