import {handleActions} from 'redux-actions';
import {SET_HIDDEN, SET_VISIBLE} from "../constants";

export const hide = handleActions({
    [SET_VISIBLE] : (state, action) => ({...state, ...action.payload})
}, {});

export const ocultos = handleActions({
    [SET_HIDDEN] : (state, action) => ({...state, ...action.payload})
}, {});

export const toHide = handleActions({
    ["SET_HIDDEN_NEW"] : (state, action) => ([...state, action.payload]),
    ["CLEAR_HIDDEN_NEW"] : (state, action) => {
        let stateMod = [...state];
        stateMod = stateMod.filter(a => a !== action.payload);
        return ([...stateMod])
    }
}, []);