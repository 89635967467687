import {requireChecker} from "./requiredChecker";
import {apiPost} from "../../api";
import {emptyArray} from "./emptyArray";
import {booleanModal} from "../../components/ModalMaster/booleanModal";
import React from "react";
//return ultimatePostGrid(fields, this.userInfoSetter(this.props), action,  mostrarModal, accion.methodForm === "DUALENDPOINTS" ? setAccionData2 : setAccionData, setAccionData, this.arrayError, accion.methodForm , limpiar);

//export const ultimatePostGrid = (fields, fvalues, action,  mostrarModal, setAccionData2, setAccionData, arrayError, cleaner, limpiar, destroy) => {
export const ultimatePostGrid = ({formValues, arrayError, fields, mostrarModal, destroy, setAccionData, limpiar, setAccionData2, accion: {methodForm}, schema:{formulario: {action}}}) => {
    requireChecker(fields, formValues) ? apiPost(action, formValues).then(
        res => {
            if(res[0].codigo_error &&
                res[0].codigo_error == "1000" &&
                res[0].enpoint &&
                res[0].descripcion) {
                return booleanModal(res[0].enpoint, res[0].descripcion, mostrarModal, "POST", formValues);
            }
            !Array.isArray(res)
                ? emptyArray(null, mostrarModal)
                : res.length <= 0
                ? emptyArray( null, mostrarModal)
                : res.length === 1
                    ? arrayError(res[0], mostrarModal, methodForm === "DUALENDPOINTS" ? setAccionData2 : setAccionData  , methodForm)
                    : methodForm === "DUALENDPOINTS"
                        ? setAccionData2({data: res, loaded: false})
                        : setAccionData(res) && limpiar && destroy('form')
        })
        : mostrarModal("Alerta", "Falta completar campos obligatorios", null);
    return true;
};

