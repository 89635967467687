import {FETCH_MODULOS_POR_SISTEMA} from "../constants";
import { createAction} from 'redux-actions';
import {clearAccionSelected} from "./clearAccionSelected";
import {apiGet} from "../api";
import {urlModulos} from '../config';
import {setSelectedSystem} from "./setSelectedSystem";
import {unirUrl} from "../helpers/util";
import {setStats} from "./setStats";
import {clearStats} from "./clearStats";



export const fetchModulosPorSistema= createAction(FETCH_MODULOS_POR_SISTEMA);

export const setSistemaSeleccionado = sistema => {
    return (dispatch, getState) => {
        const store = getState();
        const roles = store.keycloak.realmAccess.roles;
        const {sigla} = sistema;
        const rol = roles.filter(rol => rol.startsWith(sigla));
        const url = unirUrl(`${urlModulos}/${sistema.id}`, "/rol", rol);
        dispatch(clearAccionSelected());
        dispatch(setSelectedSystem(null));
        dispatch(fetchModulosPorSistema(
            apiGet(url)()))
            .then(res => {
                !sistema.hasOwnProperty('endpointStats') && sistema.endpointStats === undefined
                    ? dispatch(clearStats({}))
                    : !getState().userInfo.hasOwnProperty('evg_institucion_perteneciente')
                        ? apiGet(sistema.endpointStats)().then(res => {
                            dispatch(setStats(res))
                        })
                        :dispatch(clearStats({}));
                return dispatch(setSelectedSystem(sistema))
            });

    }
};