import {genericFieldProps} from '@isobar-us/redux-form-gen';
import TextAreaField from "../Components/TextAreaField";
import {string2Json} from "../../../../../../helpers/util";

export const textAreaType = ({field}) => {
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        required: field.required,
        component: TextAreaField,
        type: field.type,
        disabled: !!field.disabled,
        placeholder: field.placeholder
    };
};

export default textAreaType;
