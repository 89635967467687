import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from "./Icon";
import Card from "../../components/Content/Card";
import {getBreadCrumb} from "../../selectors/estructura";
import {connect} from "react-redux";

class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        this.setState({
            hasError: true,
            error:error,
            errorInfo: info
        })
    }

    render() {
        const {sistema, modulo, accion} = this.props.bread;
        if (this.state.hasError) {
            // You can render any custom fallback UI
            this.state.error && console.warn(this.state.error);
            this.state.errorInfo && console.warn(this.state.errorInfo);
            return (
                    <div className="error-page">
                        <h2 className="headline text-red">
                            <i className="fas fa-exclamation-triangle text-red"></i>
                        </h2>
                        <div className="error-content">
                            <div className="external-event bg-aqua">
                                {sistema && sistema.nombre && `${sistema.nombre}/`}
                                {modulo && modulo.nombre && `${modulo.nombre}/`}
                                {accion && accion.nombre && `${accion.nombre}`}
                            </div>
                            <h3>Oops! Algo salio mal.</h3>
                            <p>
                                Vamos a intentar solucionar esto de inmediato.
                                Comuníquese al anexo 3222.
                            </p>
                            <hr/>
                        </div>
                        <details>
                            <pre><code>
                                {this.state.error && this.state.error.toString()}
                                <small>
                                    {this.state.errorInfo && this.state.errorInfo.componentStack.toString()}
                                </small>
                            </code></pre>
                        </details>
                    </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {};

const mapStateToProps = (state) => ({
    bread: getBreadCrumb(state)
});

export default connect(mapStateToProps)(ErrorBoundary);