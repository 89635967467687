import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {connect} from 'react-redux';
import HeaderContainer from "./containers/HeaderContainer";
import SideAppContainer from "./containers/SideAppContainer";
import SideBarContainer from "./containers/SideBarContainer";
import ContentContainer from "./containers/ContentContainer";
import ModalMaster from "./components/ModalMaster";
import Footer from "./components/Footer";
import {fetchEstructura} from "./actions/fetchEstructura";
import {getModal, getModalBody} from "./selectors/modal";
import {mostrarModal} from "./actions/mostrarModal";
import {fetchUserProfileInfo} from "./actions/fetchUserProfileInfo";
import {getKeycloak} from "./selectors/keycloak";
import ErrorBoundary from "./helpers/util/ErrorBoundary";


class App extends Component {

    renderApp = () => {
        return (
            <div className="wrapper" style={{borderRadius: "7px"}}>
                <HeaderContainer />
                <SideBarContainer/>
                <ContentContainer/>
                <Footer/>
                <SideAppContainer/>
                <div className="control-sidebar-bg"></div>
                <ModalMaster m={this.props.exponer} handleModal={this.props.mostrarModal} body={this.props.body}/>
            </div>
            );
    };
    componentDidMount() {

        this.props.fetchUserProfileInfo(this.props.kc.loadUserInfo).then(
            (res)=> {
                return this.props.fetchEstructura(res)
            }
        )

    }

    render() {
      return this.renderApp()
    }
}

const mapStateToProps = (state) => ({
    exponer: getModal(state),
    body: getModalBody(state),
    kc : getKeycloak(state),
    state: state
});

export default connect(mapStateToProps, {fetchUserProfileInfo, fetchEstructura, mostrarModal})(App);