import {
    FETCH_ACCION_SCHEMA,
    FETCH_ACCION_DATA
} from "../constants";
import {fetchAccionData} from './fetchAccionData';
import _ from "underscore";
import {setSelectedAction} from "./setSelectedAction";
import {fetchAccionSchema} from "./fetchAccionSchema";
import {clearData} from "./clearData";
import {clearSchema} from "./clearSchema";
import {clearAccionSelected} from "./clearAccionSelected";
import {setAccionData} from "./setAccionData";
import {setGridAccionData} from "./setGridAccionData";
import {serverListener} from "./insertAccionData";
import {string2Json} from "../helpers/util";
import {
    clearPrevAccionSeleccionada,
    clearPrevData, loadFormValues, loadPrev,
    setPrevAccionSeleccionada,
    setPreviousData,
    setPreviousForm
} from "./previous";
import {extServerListener} from "../components/ModalMaster/serverListener";
import {apiGet} from "../api";
import {setAuxData} from "../actions/data";


const types = {
    [`${FETCH_ACCION_SCHEMA}_FULFILLED`]: true,
    [`${FETCH_ACCION_DATA}_FULFILLED`]: true
};

const previousData = () => null;
const prevSelectedAction = () => null;

const evaluar = tipo => _.has(types, tipo);

export const setAccionSeleccionada = (accion, data, modal, goBack) => {
    if(accion.componenteVista === "grid" && Array.isArray(data)){
        delete accion.endpoint
    }
    return (dispatch, getState) => {
        const estadoPrevio = getState();
            if(goBack){
                dispatch(setPrevAccionSeleccionada(estadoPrevio.accionSeleccionada));
                dispatch(setPreviousData(estadoPrevio.accionValor));
                dispatch(setPreviousForm(estadoPrevio.form));
            }
            dispatch(clearAccionSelected());
            dispatch(clearData());
            dispatch(clearSchema());
            dispatch(loadPrev(false));
            dispatch(loadFormValues(false));

            accion && accion.urlVista && dispatch(fetchAccionSchema(accion.urlVista))
                .then(res => {
                    if(accion.methodForm === "DUALENDPOINTS"){

                      accion.endpoint=typeof accion.endpoint === "string"?string2Json(accion.endpoint):accion.endpoint

                      if(accion.endpoint && accion.endpoint.bloqueo){
                        if(accion.endpoint.pre.includes("pantallaBloqueosRetorno")){
                          accion.endpoint.pre = accion.endpoint.pre +"/"+getState().userInfo.sub;
                        }

                        if(accion.endpoint.post.includes("consultaBloqueosRetorno")){
                          accion.endpoint.post = accion.endpoint.post +"/"+getState().userInfo.sub;

                          apiGet(accion.endpoint.post)().then(r => {
                              return setAuxData(Array.isArray(r) ? {data:r,loaded:false} : null)
                          });
                        }

                      }


                        return dispatch(fetchAccionData(accion.endpoint.pre)).then( resp => {
                            if(modal) extServerListener(resp.value, modal);
                            return resp;
                        })
                    }
                    if(data){
                        if(modal) modal("", JSON.stringify(data,null,4),"");
                        dispatch(setAccionData(data));
                    }
                    if(accion.endpoint){
                        return dispatch(fetchAccionData( accion.componenteVista === "grid" && accion.hasOwnProperty("params") && accion.params !== null
                            ? endpointSetter(accion.endpoint, accion.params, getState())
                            : accion.endpoint)).then( resp => {
                            if(modal) extServerListener(resp.value, modal);
                            return resp;
                        })
                    }
                    return res
                }).then((res) => {
                    if(res && res.action && evaluar(res.action.type)) dispatch(setSelectedAction(accion));
                });
            accion && !accion.urlVista && accion.endpoint &&
            (accion.componenteVista === "stats" || accion.componenteVista === "graficos") &&
            dispatch(fetchAccionData(accion.endpoint))
                .then(resp => resp).then(c => dispatch(setSelectedAction(accion)));

        const endpointSetter = (endpoint, params, store) => `${endpoint}/${checkParams(params, store)}`;
        const findParent = val => eval(`this.props.formValues.${val}`);
        const findStatic = val => val;
        const findValue = val  => eval(`getState().${val}`);
        const checkParams = (params, store) => Object.values(string2Json(params).list).sort((a,b) => a.order - b.order).reduce( (ant, act) => {
                act = ant !== "" ? `${ant}/${eval(`find${Object.keys(act)[0]}(act[Object.keys(act)[0]])`)}`: eval(`find${Object.keys(act)[0]}(act[Object.keys(act)[0]])`);
                return act;
            },"");
    };
};

const errorChecker = val => {
    const codigo = val.value.hasOwnProperty('codigo') || val.value.hasOwnProperty('codigo_error')
        ? val.value.codigo || val.value.codigo_error
        : 0;
    return codigo === 1000 || codigo === 0
            ? val
            : null;
};

export const setAccionDinamica = (urlAccion, dinamicUrl) => {
    return (dispatch) => {
        dispatch(clearAccionSelected());
        dispatch(clearData());
        dispatch(clearSchema());
        urlAccion && dispatch(fetchAccionData(urlAccion))
            .then(res => {
                if(dinamicUrl)return dispatch(fetchAccionData(dinamicUrl));
                return res
            }).then((res) => {
                if(res && res.action && evaluar(res.action.type)) dispatch(setSelectedAction(urlAccion));
            })
    };
};


const dualCaller = (accion, data, modal, goBack, dispatch) => {
    console.log("dual caller");
    apiGet(accion.urlVista)().then(fetchedData => {
        console.log("fetchedData", fetchedData)
        dispatch(fetchAccionData(accion.endpoint).then( resp => {
            if(modal) extServerListener(resp.value, modal);
            return resp;
            })
        );
    });
};
