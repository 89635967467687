import React from 'react';
import PropTypes from 'prop-types';
import Card from "../index";
import {getUserProfileData, getUserProfile} from "../../../../selectors/keycloak";
import {connect} from "react-redux";

const styles = {
    width: '150px',
    height: '150px',
    objectFit: 'cover'
};

const renderEmailVerified = v => {
   return !v? <p className="badge bg-red pull-right">No</p>: <p className="badge bg-green pull-right">si</p>
};

const renderEnabled = v => {
    return !v? <p className="badge bg-red pull-right">Deshabilitado</p>: <p className="badge bg-green pull-right">Habilitado</p>
};
const Profile = ({profileData}) => {
    const LDAP = profileData.attributes.LDAP_ENTRY_DN.toString();
    const arrayLDAP=LDAP.split(",");
    const cn = arrayLDAP[0].substring(3);
    const ou = arrayLDAP[1].substring(3);
    const dn = arrayLDAP[2].substring(3);
    const dc = arrayLDAP[3].substring(3);
    return (
        <Card titulo={cn}>
            <div>
                <div className="box-body box-profile">
                    <div>
                        <img className="profile-user-img img-responsive img-square"
                             src={'data:image/jpg;base64,' + profileData.attributes.photo}
                             alt="User profile picture"
                             style={styles}/>
                    </div>
                        <h3 className="profile-username text-center">{cn}</h3>
                        <p className="text-muted text-center">Unidad {ou}</p>
                        <ul className="list-group list-group-unbordered">
                            <li className="list-group-item">
                                <b>Estado</b> {renderEnabled(profileData.enabled)}
                            </li>
                            <li className="list-group-item">
                                <b>Email Verificado</b> {renderEmailVerified(profileData.emailVerified)}
                            </li>
                            <li className="list-group-item">
                                <b>LDAP</b>
                            </li>
                            <li className="list-group-item">
                                <b>Nombre (CN)</b> <p className="pull-right">{cn}</p><br/>
                            </li>
                            <li className="list-group-item">
                                <b>Unidad (OU)</b> <p className="pull-right">{ou}</p><br/>
                            </li>
                            <li className="list-group-item">
                                <b>Dominio (DN)</b> <p className="pull-right">{dn}</p><br/>
                            </li>
                            <li className="list-group-item">
                                <b>Componente de Dominio (DC)</b> <p className="pull-right">{dc}</p><br/>
                            </li>
                        </ul>

                </div>
            </div>
        </Card>
    );
};

Profile.propTypes = {

};

const mapStateToProps = (state) => ({
    profileData: getUserProfileData(state),
});

export default connect(mapStateToProps)(Profile);