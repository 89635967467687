import {genericFieldProps} from '@isobar-us/redux-form-gen';
import {string2Json} from "../../../../../../helpers/util";
import HiddenField from "../Components/HiddenField";

export const HiddenType = ({field}) => {
    if(!field.conditionalValid) delete field['conditionalValid'];
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        required: field.required,
        component: HiddenField,
        type: field.inputType,
        disabled: !!field.disabled,
        placeholder: field.placeholder,
        icon: field.icon ? field.icon : false,
        massive: field,
        elementoOpcion: field.elementoOpcion
    };
};

export default HiddenType;


