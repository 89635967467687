import {apiGet, apiPost} from "../../api";
import {extServerListener} from "./extServerListener";
import {string2Json} from "./index";
import {setAuxData} from "../../actions/data";

export const methodSwitchGrid = paramsGroup => {
    let {
        values,
        row,
        flagAddData,
        setAccionSeleccionada,
        mostrarModal,
        setAccionData,
        setPreviousData,
        previousData,
        previousAccion,
        setPreviousAccion,
        setAuxData
    }  = paramsGroup;
    switch(values.metodoAccionContenedora || values.methodForm){
        case "GET" : apiGet(values.endpoint)().then(fetchedData => {
            if(extServerListener(fetchedData, mostrarModal) !== null) {
                setPreviousData(previousData);
                setPreviousAccion(previousAccion);
                setAccionSeleccionada(values, undefined, mostrarModal, true);
                flagAddData && setAccionData({parent: row.value})
            }
        });
            break;
        case "DUALENDPOINTS" :  apiGet(values.endpoint.pre)().then(fetchedData => {
            if(extServerListener(fetchedData, mostrarModal) !== null) {
                apiGet(values.endpoint.post)().then(r => {
                    return setAuxData(Array.isArray(r) ? {data:r,loaded:false} : null)
                });
                setPreviousData(previousData);
                setPreviousAccion(previousAccion);
                setAccionSeleccionada(values, undefined, mostrarModal, true);
                setAccionData(fetchedData)
            }
        });
            break;
        case "POST" : apiPost(values.endpointAux, row.value).then(fetchedData => {
            if(extServerListener(fetchedData, mostrarModal) !== null) {
                setAccionSeleccionada(values, undefined, mostrarModal, true);
                flagAddData && setAccionData(fetchedData);
            }
        });
            break;
        default :   apiGet(values.endpoint)().then(fetchedData => {
            if(extServerListener(fetchedData, mostrarModal) !== null) {
                setAccionSeleccionada(values, undefined, mostrarModal, true);
                flagAddData && setAccionData({parent: row.value})
            }
        });
    }
};