import {setAccionSeleccionada} from "../setAccionSeleccionada";
import {setAccionData} from "../setAccionData";
import {setForm} from "../setForm";
import {destroy} from 'redux-form';
import {clearPrevAccionSeleccionada, clearPrevData, clearPrevFormValues, loadPrev, loadFormValues} from "../previous";
import {clearAccionSelected} from "../clearAccionSelected";
import {clearData} from "../clearData";
import {clearSchema} from "../clearSchema";

export const goBack = () => {
    return (dispatch, getState) => {
        const estadoPrevio = getState();
        dispatch(clearAccionSelected());
        dispatch(clearData());
        dispatch(clearSchema());
        dispatch(setAccionSeleccionada(estadoPrevio.previousAccion, undefined, undefined, false));
        dispatch(setAccionData(estadoPrevio.previousData.data));
        dispatch(loadPrev(true));
        dispatch(loadFormValues(true));
        };
};
