import {routerMiddleware, connectRouter} from 'connected-react-router';
import {applyMiddleware, createStore} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import reducers from "../reducers";
import thunk from "redux-thunk";
import {loadingBarMiddleware} from "react-redux-loading-bar";
import errorMiddleware from "./ErrorMiddleware";

export default function configureStore(history) {
    return createStore(connectRouter(history)(reducers), applyMiddleware(
        errorMiddleware,
        routerMiddleware(history),
        promiseMiddleware(),
        thunk,
        loadingBarMiddleware()
    ));
}

