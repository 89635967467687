import {apiGet} from "../../api";
import {domainIpRemoval} from "./DomainIpRemoval";
import {decode as atob} from "base-64";
import {MimeSetter} from "./index";
import {extServerListener} from "../../components/ModalMaster/serverListener";

export const fileOpener = (url, base, format, modal) => {
    base === undefined
        ? apiGet(!url.includes("http") ? url : domainIpRemoval(url))().then(obj => {
            return baseHandling(obj || obj, format, modal)
        })
        : baseHandling(base, format)
};

const baseHandling = (obj, format, modal) => {
    try{
        const base = obj.hasOwnProperty('base') ? obj.base : obj;
        //obj.codigo_error && obj.descripcion && modal && extServerListener(obj, modal);
        if (obj) {
            var byteCharacters = atob(base.substring(base.indexOf(',') + 1));
            var contentType = `${ format ? MimeSetter(format) : "application/pdf" }`;
            var byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                let slice = byteCharacters.slice(offset, offset + 512);
                let byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            let blob = new Blob(byteArrays, {type: contentType});
            let url = URL.createObjectURL(blob);
            if(format && format !== "pdf"){
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = obj.hasOwnProperty("fileName") &&  obj.fileName !== null ? `${obj.fileName}.${format}` : a.download;
                console.log("RESULT", obj.fileName, format, a.download)
                a.click();
                window.URL.revokeObjectURL(url);
            }else {
                console.log("llego al else:", format);
                saveFileOnUserDevice({
                    content: blob, name: obj.hasOwnProperty("fileName") && obj.fileName !== null
                        ? `${obj.fileName}${format ? "."+format: ""}`
                        : "SinTitulo"
                })
            }


        }
    }
    catch (e) {
        console.log("fallo en encontrar obj.base", e);
    }
};
const saveFileOnUserDevice = function(file){ // content: blob, name: string
    if(navigator.msSaveBlob){ // For ie and Edge
        return navigator.msSaveBlob(file.content, file.name);
    }
    else{
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(file.content);
        link.download = file.name;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        link.remove();
        window.URL.revokeObjectURL(link.href);
    }
};
