import isPromise from 'is-promise';
import React from 'react';
import _ from 'underscore';
import {types} from "./Types";
import {mostrarModal} from "../actions/mostrarModal";

export default function errorMiddleware({dispatch, getState}) {
    return next => action => {
          if(!isPromise(action.payload)){
              try {
                  return next(action);
              } catch (e) {
                  errorHandler(e, getState, action, dispatch);
                  return e;
              }
          }
          if(_.has(types, action.type)){
              return next(action).catch(error => {
                  errorHandler(error, getState, action, dispatch)
              })
          }
    };
}


const errorHandler = (err, getState, lastAction, dispatch) => {
    console.error(err);
    console.log('Estado Actual:', getState());
    console.log('Ultima Accion:', lastAction);
    console.log('dispatch:');
    dispatch(mostrarModal(header(),errorBody(err),null));
};

const header = () => {
    return (
        <div className="text-center">
            <h2>Ops, ha ocurrido un problema</h2>
            <small>Vamos a intentar solucionar esto de inmediato.&nbsp;
                <span>Comuníquese al anexo 3222.</span>
            </small>
        </div>
    )
};

const errorBody = err =>  <pre><code className="text-red">{err.toString()}</code></pre>;