import {genericFieldProps} from '@isobar-us/redux-form-gen';
import SeparadorField from './../Components/SeparadorField';
import {string2Json} from "../../../../../../helpers/util";

export const SeparadorType = ({field}) => {
    if(field.conditionalValid && typeof field.conditionalValid === 'string') field.conditionalValid = string2Json(field.conditionalValid);
    if(field.conditionalVisible && typeof field.conditionalVisible === 'string') field.conditionalVisible = string2Json(field.conditionalVisible);
    if(field.conditionalDisabled && typeof field.conditionalDisabled === 'string') field.conditionalDisabled = string2Json(field.conditionalDisabled);
    if(field.conditionalRequired && typeof field.conditionalRequired === 'string') field.conditionalRequired = string2Json(field.conditionalRequired);
    return {
        ...genericFieldProps({field}),
        _genLabelComponent: null,
        label: field.label,
        _genComponent: SeparadorField
    }
};