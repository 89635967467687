import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGenerator, injectGenProps} from "@isobar-us/redux-form-gen";
import CustomFieldTypes from "./CustomFieldTypes";
import {Form, reduxForm} from "redux-form";
import "./styles.css";
import CustomOperators from "./CustomOperators";
import Icon from "../../../../helpers/util/Icon";
import ButtonField from "./CustomFieldTypes/Components/ButtonField/";


class GeneradorForm extends Component {

    renderButtons = (props, submitting, pristine) => (props.botones.length > 0 ) ? props.botones.map( b => <ButtonField {...b} {...this.props}
                                                                                                                        submitting={submitting}
                                                                                                                        pristine={pristine}
                                                                                                                        valid={true}
                                                                                                                        invalid={false}
                                                                                                                        />) : "";
    submitChecker = ({botones}) => botones.length > 0 && (botones.filter(btn => btn.tipo === "submit").length > 0);

    onKeyPress = (event) => {
        if (event.which === 13 && !this.submitChecker(this.props)){
            event.preventDefault();
        }
    };

    render() {
        const {handleSubmit,fields, submitting, submitSucceeded, alerta, invalid, reset, pristine, botones} = this.props;
        return (
        <Form onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                {fields[0].childFields.length === 0
                    ? console.log()
                    : <FormGenerator fields={fields}
                                        customFieldTypes={CustomFieldTypes}/>}
            {this.props.sectionGrid !== null && this.props.sectionGrid}
                <div className="box-footer col-md-12" style={{ backgroundColor:"#F9F9F9",borderRadius:"10px"}}>
                    <div className="col-md-12" style={{margin:"0px", padding:"0px", height:"35px"}} >
                            <div className="col-md-12 pull-right">
                                {this.renderButtons(this.props, submitting, pristine)}
                            </div>
                    </div>
                </div>
            </Form>
        );
    }
}

GeneradorForm.propTypes = {
    data: PropTypes.object,
    titulo: PropTypes.string,
    fields: PropTypes.array,
    handleSubmit: PropTypes.func.isRequired,
};

const form = "form";

export default injectGenProps(reduxForm({ form:`${form}` })(GeneradorForm));