import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Frag} from "./util";
import connect from "react-redux/es/connect/connect";

class SelectorConectado extends Component {
    render() {
        return (
            <Frag>
                {this.props.children(this.props.state)}
            </Frag>
        );
    }
}

SelectorConectado.propTypes = {};
const mapStateToProps = (state) => ({
    state:state
});

export default connect(mapStateToProps)(SelectorConectado);