import React, {Component} from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_en_US from "@amcharts/amcharts4/lang/es_ES";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import myTheme from "@amcharts/amcharts4/themes/material";
import myTheme from "@amcharts/amcharts4/themes/kelly";
//import darkTheme from "@amcharts/amcharts4/themes/amchartsdark";
//import myTheme from "@amcharts/amcharts4/themes/moonrisekingdom";
//import myTheme from "@amcharts/amcharts4/themes/patterns";
//import myTheme from "@amcharts/amcharts4/themes/spiritedaway";
import darkTheme from "@amcharts/amcharts4/themes/dark";

import {connect} from "react-redux";

import Card from "../index";

import {getDataSistemaSeleccionado} from "../../../../selectors/estructura";
import {getUserProfileData} from "../../../../selectors/keycloak";
import {getStats} from "../../../../selectors/stats";

import PropTypes from 'prop-types';




am4core.useTheme(am4themes_animated);
am4core.useTheme(myTheme);
//am4core.useTheme(darkTheme);

class PieChart extends Component {


    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        let chart = am4core.create("chartdiv_PieChart", am4charts.PieChart);
        //chart.paddingRight = 20;
        //chart.background.fill = am4core.color("##222D32");
        chart.data = [{
            "country": "Lithuania",
            "litres": 501.9
        }, {
            "country": "Czech Republic",
            "litres": 301.9
        }, {
            "country": "Ireland",
            "litres": 201.1
        }, {
            "country": "Germany",
            "litres": 165.8
        }, {
            "country": "Australia",
            "litres": 139.9
        }, {
            "country": "Austria",
            "litres": 128.3
        }, {
            "country": "UK",
            "litres": 99
        }, {
            "country": "Belgium",
            "litres": 60
        }, {
            "country": "The Netherlands",
            "litres": 50
        }];

        chart.exporting.menu = new am4core.ExportMenu();

// Add title
        let title = chart.titles.create();
        title.text = "Grafico de torta";
        title.marginBottom = 11;
        title.disabled = false;

// Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "litres";
        pieSeries.dataFields.category = "country";

        chart.responsive.enabled = true;

        this.chart = chart;
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        //const { sistemaActual, profileData, stats} = this.props;

        return (

                <div className='box-body'>
                    <div id="chartdiv_PieChart" style={{ width: "100%", height: "500px" }}></div>
                </div>

        );
    }
}

PieChart.propTypes = {

};

const mapStateToProps = (state) => ({
    sistemaActual: getDataSistemaSeleccionado(state),
    profileData: getUserProfileData(state),
    stats: getStats(state)
});

export default connect(mapStateToProps,{})(PieChart);
