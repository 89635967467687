import {handleActions} from 'redux-actions';
import {
    FETCH_MODULOS_POR_SISTEMA,
    FETCH_SISTEMAS,
    SET_MODULO_SELECCIONADO,
    SET_SISTEMA_SELECCIONADO
} from "../constants";

export const estructura = handleActions({
    [`${FETCH_SISTEMAS}_FULFILLED`]: (state, action) => ({sistemas: action.payload}),
    [`${FETCH_MODULOS_POR_SISTEMA}_FULFILLED`]: (state, action) => ({...state, modulos:action.payload}),
    [SET_SISTEMA_SELECCIONADO] :(state, action) => ({...state, sistemaSeleccionado: action.payload}),
    [SET_MODULO_SELECCIONADO] : (state, action) => ({...state, moduloSeleccionado: action.payload})
}, {});
