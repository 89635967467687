import {token} from '../../config';
import {string2Json} from "../util";

const makeObject = (method, headers, body) => ({method: method, headers: headers, body: body}); // Prepara el objeto a enviar

const prepareFormBody = (details) => {
    var formBody = [];

    for (var property in details){
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }

    formBody = formBody.join("&");
    return formBody
};  //Prepara el formBody para application/x-www-form-urlencoded;charset=UTF-8

const prepareToken = (details) => {
    return (makeObject(
            'POST', //tipo de peticion
            {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }, //headers
            prepareFormBody(details)) //body)
    )};

export const getToken = () => {
    return (fetch(token.endpoint, prepareToken(token.details)) // este token debe ser usado con .then para que espere a que se resuelva
        .then( res => res.json())
        .then( token =>  {
            return `${token.access_token}`
        }));
}

export const getUserData = () => (fetch(token.endpoint, prepareToken(token.details)) // este token debe ser usado con .then para que espere a que se resuelva
    .then( res => res.json())
    .then( token =>  {
        return `${token.access_token}`
    }));
