import { createAction } from "redux-actions";
import {HANDLESUBMIT, INSERT_ACCION_DATA} from "../constants";
import {apiGet, apiPost} from "../api";
import {modalTrigger} from "../components/ModalMaster/test";
import React from "react";
import {fetchAccionData} from "./fetchAccionData";
import {setAccionSeleccionada} from "./setAccionSeleccionada";
import {setFormResponse} from "./setFormResponse";
import {clearAccionSelected} from "./clearAccionSelected";
import {clearData} from "./clearData";
import {clearSchema} from "./clearSchema";
import {fetchAccionSchema} from "./fetchAccionSchema";
import {setAccionData} from "./setAccionData";
import {setSelectedAction} from "./setSelectedAction";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {agibizIngresoReset} from "../externalHandling/agibiz";
import {stopSubmit} from "redux-form";
import {setGridAccionData} from "./setGridAccionData";
import {extServerListener} from "../components/ModalMaster/serverListener";

export const insertAccionData =  createAction(INSERT_ACCION_DATA,
    (url, obj) => apiPost(url, obj));

export const  actionHandleSubmit =  createAction(HANDLESUBMIT,
    (url, obj, modal, nextAction) => apiPost(url, obj).then(a => {
        extServerListener(a, modal);
        return a;
    }));

const  endpointSetter = (accion , values) =>  accion.hasOwnProperty('parent') && values.hasOwnProperty(accion.parent)
                                                ? `${accion.endpoint}/${values[accion.parent]}`
                                                : accion.hasOwnProperty('endpoint')
                                                    ? accion.endpoint
                                                    : null;



export const submitHandling = (url, obj, modal, nextAction) => {
    return (dispatch, getState) => {
        dispatch(showLoading());
        apiPost(url, obj).then(a => {
            dispatch(setFormResponse(a));
            extServerListener(a, modal);
            return a;
        }).then(resp => {
            return endpointSetter(nextAction, obj) !== null
                ? apiGet(endpointSetter(nextAction, obj))()
                    .then(fetchedData => {
                        console.log("RESP", resp);
                        fetcherSetter(resp, dispatch, nextAction, getState, fetchedData);
                    }).catch(cat => console.log(cat))
                : fetcherSetter(resp, dispatch, nextAction, getState, resp)
        });
    };
};

const fetcherSetter = (resp, dispatch, nextAction, getState, fetchedData) => {
    if(checker(resp)){
        dispatch(fetchAccionData(nextAction.redirect))
            .then(fetched => {
                dispatch(setAccionSeleccionada(fetched.value));
                dispatch(hideLoading());
                return (resp.codigo_error === 1000 || resp.codigo_error === 0 || resp.codigo === 1000 || resp.codigo === 0)
                    ? dispatch(setAccionData(fetchedData), stopSubmit('form'))
                    : {};
            })
    }
    else{
        dispatch(hideLoading());
    }
};

const checker = (val) => {
    const c = val.hasOwnProperty('codigo') ? val.codigo : val.hasOwnProperty('codigo_error') ? val.codigo_error : null;
    return c !== 1001 && c !== 1002 && c !== 1005
};
/*
export const serverListener = (response, modal) =>{
    response.codigo_error || response.codigo
        ? modalTrigger("Mensaje", response.descripcion, "", modal)
        : modalTrigger(`Ha ocurrido algo inesperado`, JSON.stringify(response, null, 2), "", modal);
};
*/