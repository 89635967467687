import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from "../index";
import {fetchAccionData} from "../../../../actions/fetchAccionData";
import {getAccion, getAccionData, getAccionSchema, getGridAccionData} from "../../../../selectors/estructura";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {convertBooleanToString, domainIpRemoval, string2Json} from "../../../../helpers/util";
import {setAccionSeleccionada} from "../../../../actions/setAccionSeleccionada";
import {Icon} from "../../../../helpers/util";
import {setAccionData, setAccionData2} from "../../../../actions/setAccionData";
import {mostrarModal, showModal} from "../../../../actions/mostrarModal";
import {apiGet} from "../../../../api";
import {decode as atob} from "base-64";
import {fileOpener} from "../../../../helpers/util";
import FormularioConectado from './../Form'
import SectionType from "../Form/CustomFieldTypes/Type/SectionType";
import {GenField} from "@isobar-us/redux-form-gen";
import SectionGrid from "./SectionGrid";
import ActionButton from './ActionButton';
import _ from 'lodash';
import {setPrevAccionSeleccionada, setPreviousData} from "../../../../actions/previous";
import {setAuxData} from "../../../../actions/data";

class Grid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: convertBooleanToString(this.props.data),
            aux: null,
            auxData:null
        }
    };

    ColumnaAcciones = length => ({
                Header: "Acciones",
                accessor: d => d,
                id:"acciones",
                Cell: (row)=> (<div className="btn-group center">{this.renderAcciones(row)}</div>),
                width: 45 + length * 20
            });

    addColumnsAction = (columns, acciones) => {
        if(columns && acciones && acciones.length) return [this.ColumnaAcciones(acciones.length), ...columns];
        if(columns) return columns;
        return [];
    };

    renderAcciones = (row) => {
        if(this.props.schema && this.props.schema.acciones) {
            return this.props.schema.acciones.map((values) => {
                const globe = {
                    values: values,
                    row: row,
                    params: values.params ? string2Json(values.params).list[0].value : undefined,
                    flagAddData: false,
                    setAccionSeleccionada: this.props.setAccionSeleccionada,
                    mostrarModal: this.props.mostrarModal,
                    setAccionData: this.props.setAccionData,
                    setPreviousData: this.props.setPreviousData,
                    previousData: this.props.accionValor,
                    previousAccion: this.props.accionSeleccionada,
                    setPreviousAccion: this.props.setPrevAccionSeleccionada,
                    showModal: this.props.showModal,
                    setAuxData : this.props.setAuxData,
                    setAccionData2: this.props.setAccionData2,
                    aux: this.state.aux,
                    keycloakUserInfo: this.props.keycloakUserInfo
                };
                return ActionButton(globe)
            })
        }
        return null;
    };

    renderTable = (state, makeTable) => {
        return (
                <div className="table table-hover">
                    {makeTable()}
                </div>
        );
    };


    renderFormGrid = ({ accion: {nombre}, schema:{formulario}, accionSeleccionada:{endpoint}}) => {
        return  (
           <div>
               <FormularioConectado fields={formulario}
                                    sectionGrid={
                                        _.isEqual(this.state.data, this.props.data) && formulario.sections[0].childFields.length >= 0
                                        ? <SectionGrid nombre={nombre} schema={formulario}>
                                                {this.renderTablaConDatos(this.state.data)}
                                          </SectionGrid>
                                        : formulario.sections.length > 1
                                            ?   <SectionGrid nombre={nombre} schema={formulario}>
                                                    {this.renderTablaConDatos(this.state.data)}
                                                </SectionGrid>
                                            : null}/>

           </div>
       )
    };

    renderTablaConDatos = (data) => {
        return  (
            <ReactTable
                {...this.props.schema}
                data={this.state.data || data}
                resolveData={ data  => data.map(row => row)}
                columns={this.addColumnsAction(this.props.schema.cabeceras, this.props.schema.acciones)}
                className="-striped -highlight"
                defaultFilterMethod={({ id, value }, row) => row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true}
                previousText="Atras"
                loadingText="Cargando..."
                defaultPageSize={(this.props.schema.defaultPageSize > this.props.data.length)
                    ? this.props.data.length :this.props.schema.defaultPageSize }
                nextText="Siguiente"
                pageText="Página"
                rowsText="registros"
                pageSizeOptions={this.props.schema.pageSizeOptions.slice(1, this.props.schema.pageSizeOptions.length-1 ).split(",")}
                noDataText="No se encontraron registros"
                ofText="de"
                onFilteredChange={(filtered, column, rowInfo, instance) => {}}
                collapseOnPageChange={true}
                style={{borderRadius: "7px"}}
            >
                {this.renderTable}
            </ReactTable>
            );
    };


        shouldComponentUpdate(nextProps, nextState, nextContext) {
            /*
        console.group("componentshouldUpdate? EXT-IF");
        console.log(!!(Array.isArray(this.props.data) && JSON.stringify(this.props.data) !== JSON.stringify(this.state.data) ? "se actualiza": "no c actualiza"));
        console.log("props", this.props);
        console.log("state", this.state);
        console.log("nextprops",nextProps);
        console.log("nextstate",nextState);
        console.groupEnd();
             */
        try{
            if(this.state.aux !== null){
                this.state.aux && JSON.stringify(nextState.data) !== JSON.stringify(nextProps.auxData.data || nextProps.auxData) && this.setState({
                    data:nextProps.auxData.data || nextProps.auxData,
                    aux: "false"
                });
                if(!this.state.aux && !nextProps.auxData.loaded &&
                    this.state.data === nextState.data){
                    nextProps.auxData.loaded = true;
                    return true;
                }
                if(_.isEmpty(nextState.data) && !nextState.aux && !nextState.auxData && nextProps.data){
                    this.setState({
                        data:nextProps.data
                    });
                    return true;
                }
                if(this.state.aux && !nextProps.auxData.loaded &&
                    this.state.data === nextState.data){
                    //console.log("los siguientes props de auxdata vienen", nextProps.auxData);
                    //console.log("nexprops auxdata loaded se convertira a true")
                    nextProps.auxData.loaded = true;
                    return true;
                }

                //if(this.state.data === nextState.data) return false;
                //if(this.state.data === (nextState.data || nextState.auxData || nextProps.data || nextProps.auxData || nextProps.auxData.data)) return false;

                return !!(this.state.aux &&
                    JSON.stringify(nextState.data) === JSON.stringify(nextProps.auxData.data || nextProps.auxData) &&
                    this.state.data !== nextState.data);
            }
            else {
                if( this.state.aux === null &&
                    this.state.data !== nextProps.data &&
                    Array.isArray(nextProps.data) &&
                    nextState.aux === null
                ) {
                    //console.log("LLEGO POS");
                    nextProps.auxData !== null && this.props.setAccionData2(null);
                    this.setState({
                        data: nextProps.data
                    });
                    return true
                }
                if(this.state.data === nextState.data && this.props.data === nextProps.data){
                    return false
                }
            }

        }catch (e) {
            console.log("ERROR EN COMPONENT SHOULD UODATE", e)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        !_.isEqual(prevState.data, this.props.data) && this.setState({
            data: convertBooleanToString(this.props.data)
        });
    }

    componentWillMount() {
            try {
                if(typeof this.props.accionSeleccionada.endpoint === "object" && !this.props.auxData.loaded && !this.state.aux){
                  console.log(this.props);

                    this.setState({
                        data: this.props.auxData.data || this.props.auxData || this.props.data,
                        aux: true
                    }) && this.props.setAuxData({data: this.props.auxData.data , loaded: false})
                }
            }catch (e) {
                console.log("error en component will mount")
            }
    }



    render()  {
        return (
                <Card titulo={this.props.schema.titulo}>
                    <div>
                        {
                            !this.props.schema.hasOwnProperty('formulario')
                                ? this.renderTablaConDatos(this.state.data)
                                : this.renderFormGrid(this.props)}
                    </div>
                </Card>
        );
    }
}

Grid.propTypes = {};

const mapStateToProps = state => ({
    data: getAccionData(state),
    gridData: getGridAccionData(state),
    schema: getAccionSchema(state),
    accion: getAccion(state),
    accionValor: state.accionValor.data,
    accionSeleccionada: state.accionSeleccionada,
    auxData: state.auxData,
    keycloakUserInfo: state.userInfo
});



export default connect(mapStateToProps,{fetchAccionData, setAuxData,setAccionData, showModal, setAccionData2, setAccionSeleccionada, mostrarModal, setPreviousData, setPrevAccionSeleccionada})(Grid);
