import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGenerator, injectGenProps} from "@isobar-us/redux-form-gen";
import CustomFieldTypes from './CustomFieldTypes';
import {Form, reduxForm} from 'redux-form';
import "./styles.css";
import {Icon} from "../../../../helpers/util";
import CustomOperators from "./CustomOperators";




class WizardForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentStep:0
        };
    }



    handleNextStep = () => this.setState({currentStep: this.state.currentStep + 1});
    handlePrevStep = () => this.setState({currentStep: this.state.currentStep - 1});


    render() {
        const {handleSubmit, fields, submitting, reset, submitSucceeded, alerta, invalid, pristine} = this.props;
        const {currentStep} = this.state;
        return (
            <Form onSubmit={handleSubmit}>
                <FormGenerator fields={fields}
                               display="inline"
                               customFieldTypes={CustomFieldTypes}
                               customOperators={CustomOperators}
                               visibleDepth={`fields[${this.state.currentStep}]`}/>
                <div className="box-footer col-md-12">
                   {currentStep > 0 &&
                   <button className="btn btn-primary" type="button" onClick={()=> this.handlePrevStep()}>
                       <Icon clase="fas fa-chevron-circle-left"/>&nbsp;Atras
                   </button>}&nbsp;
                   {currentStep < fields.length -1 &&
                   <button className="btn btn-primary" type="button" onClick={()=>this.handleNextStep()}>Siguiente&nbsp;
                       <Icon clase="fas fa-chevron-circle-right"/>
                   </button>
                   }&nbsp;
                   <button type='submit' className="btn btn-primary pull-right" disabled={submitting || invalid}>
                       Guardar&nbsp;
                       <Icon clase="fa fa-download"/>
                   </button>&nbsp;&nbsp;
                   {submitSucceeded && alerta("Ingresado Correctamente")}
                </div>
            </Form>
        );
    }
}

WizardForm.propTypes = {
    fields: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default injectGenProps(reduxForm({form:'form'})(WizardForm));