import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import Card from "../index";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import {getDataSistemaSeleccionado} from "../../../../selectors/estructura";
import {getUserProfileData} from "../../../../selectors/keycloak";
import {getStats} from "../../../../selectors/stats";
import ColumnChart from "./ColumnChart";
import GaugeChart from "./GaugeChart";

class Chart extends Component {
    render() {
        return (
            <Card titulo={`Graficos de Estadisticas`}>
                <PieChart />
                <BarChart />
                <ColumnChart />
                <GaugeChart />
            </Card>
        );
    }
}

Chart.propTypes = {

};

const mapStateToProps = (state) => ({
    sistemaActual: getDataSistemaSeleccionado(state),
    profileData: getUserProfileData(state),
    stats: getStats(state)
});

export default connect(mapStateToProps,{})(Chart);