import React from 'react';
import PropTypes from 'prop-types';

const estilo = {
    well: {
        zIndex: 0,
        marginTop:'-3px',
        height: '10px',
        border: '0px',
        boxShadow: "inset 0px 5px 10px -10px rgba(0,0,0,0.1)",
    }
};

const SeparadorField = ({field}) => {
        return (
                <h5 className="text-primary col-xs-12" style={{marginBottom:"15px", marginTop:"5px"}}><strong>{field.label}</strong></h5>
        );
}


SeparadorField.propTypes = {

};

export default SeparadorField;