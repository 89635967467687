import {setPrevAccionSeleccionada} from './set/setPrevAccionSeleccionada';
import {clearPrevAccionSeleccionada} from './clear/clearPrevAccionSeleccionada';
import {setPreviousData} from './set/setPreviousData';
import {clearPrevData} from './clear/clearPrevData';
import {clearPrevFormValues} from './clear/clearPrevFormValues';
import {setPreviousForm} from './set/setPreviousForm';
import {loadFormValues} from './set/loadFormValues';
import {loadPrev} from './set/loadPrev';

export {
    setPrevAccionSeleccionada,
    clearPrevAccionSeleccionada,
    setPreviousData,
    clearPrevData,
    setPreviousForm,
    clearPrevFormValues,
    loadPrev,
    loadFormValues
};