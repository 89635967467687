import React from 'react';
import connect from "react-redux/es/connect/connect";
import {mostrarModal} from "../../actions/mostrarModal";
import Rating from "react-rating";

const Header = props => {

    const stylesThumbnail = {
        padding: "3px",
        backgroundColor: "rgba(0,0,0,0)",
        maxWidth:"100%",
        border: "1px"
    };

    return (
        <header className="main-header">
            <a href="#" className="logo" onClick={()=>props.clearSistemaSeleccionado()}>
                <span className="logo-mini">
                   <img src="http://www.minjusticia.gob.cl/media/2018/04/isologo_minjuddhh2018.png"
                        className="img-thumbnail"
                        style={stylesThumbnail}
                        alt="Ministerio"
                   />
                </span>
                <span className="logo-lg"><b>Minju</b>Escritorio</span>
            </a>
            <nav className="navbar navbar-default">
                <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
                    <span className="sr-only">Toggle Navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </a>

                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">

                        <li>
                            <a id="controlSidebar" href="#" data-toggle="control-sidebar"><i className="fa fa-bars"></i></a>
                        </li>
                    </ul>
                </div>
                {props.children && props.children}
            </nav>
        </header>
    );
};

export default connect(null,{mostrarModal})(Header);
/*
 <li className="btn-group">
                            <div className="notifications-menu" style={{'marginTop':'8px'}}>
                            <button
                                type={"button"}
                                className={"btn btn-success btn-block btn-flat"}
                                onClick={() => props.mostrarModal("Titulo de prueba", <Rating />)}
                            >
                                Calificar sistema
                            </button>
                            </div>
                        </li>

                         <li className="btn-group">
                            <div className="notifications-menu" style={{'marginTop':'8px'}}>
                                <button
                                    type={"button"}
                                    className={"btn btn-success btn-block btn-flat"}
                                    onClick={() => props.mostrarModal("Calificanos", <Rating emptySymbol="far fa-star" fullSymbol="fas fa-star"/>)}
                                >
                                    Calificar sistema
                                </button>
                            </div>
                        </li>
 */