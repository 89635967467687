import React from 'react';

const SectionGrid = ({children, nombre, schema}) =>  schema && schema.sections[0].childFields.length > 0
        ? section(children, nombre, nombre ? nombre.replace(/\s+/g, '') : "null")
        : children;


const section = (children, nombre, colapsar) =><div className="generated-form">
    <div className="section">
        <div className="box box-default no-border" style={{borderRadius:"7px",   boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24"}}>
            <div className="box-header with-border">
                <div className='generated-form__header'>{nombre}</div>
                <div className="box-tools pull-right" style={{paddingTop:"10px"}}>
                    <button type="button" className="btn btn-box-tool"  data-toggle="collapse" data-target={`#${colapsar}`}>
                        <i className="fa fa-minus icon"> </i>
                    </button>
                </div>
            </div>
            <div className="box-body collapse in" id={`${colapsar}`}>
                {children}
            </div>
        </div>
    </div>
</div>;

export default SectionGrid;